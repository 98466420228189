'use strict';

define('vb/private/vx/remoteExtensionAdapter',[
  'vb/private/vx/endpointExtensionAdapter',
  'vbc/private/utils',
], (EndpointExtensionAdapter, Utils) => {
  /**
   * The extension adapter used to load extension from the extension manager
   */
  class RemoteExtensionAdapter extends EndpointExtensionAdapter {
    /**
     * @param {String} registryUrl  the url of the extension manager
     * @param {Object} vbInitConfig
     */
    constructor(registryUrl, vbInitConfig) {
      let url = registryUrl;

      if (url) {
        // Older applications have the registry URL as https://<fa-server>/fscmUI/vxmanifest/v1/applications/
        // and we want https://<fa-server>/fscmUI/
        const manifestIndex = url.indexOf('vxmanifest');
        if (manifestIndex > 0) {
          url = url.substring(0, manifestIndex);
        }

        url = `${Utils.addTrailingSlash(url)}vxmanifest/v2/digests/`;
      }

      super(url, vbInitConfig);
    }

    /**
     * Gets the extension version
     *
     * @return {String}  The extension version
     */
    getExtensionVersion() {
      const version = super.getExtensionVersion();

      if (!version) {
        // For v2 adds the digest version if defined. Default to 'latest'
        const digestVersion = Utils.cleanString(
          this.vbInitConfig && this.vbInitConfig.REGISTRY_DIGEST_VERSION,
        );
        return digestVersion ? encodeURIComponent(digestVersion) : 'latest';
      }
      return version;
    }
  }

  return RemoteExtensionAdapter;
});

