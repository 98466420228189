/* eslint-disable class-methods-use-this */

'use strict';

define('vb/private/services/endpointReferenceFactory',[
  'vb/private/log',
  'vb/private/services/endpointReference',
  'vb/private/services/serviceResolverFactory',
], (
  Log,
  EndpointReference,
  ServiceResolverFactory,
) => {
  const logger = Log.getLogger('/vb/private/services/endpointReferenceFactory');
  /**
   *
   */
  class EndpointReferenceFactory {
    /**
     * Creates new EndpointReference while calling EndpointResolver to process the endpoint ID if applicable.
     *
     * @param {string} endpointId
     * @param {*} serviceContext
     */
    async getResolvedReference(endpointId, serviceContext) {
      const epRef = new EndpointReference(endpointId, serviceContext);
      if (epRef.isProgrammatic) {
        // get endpoint service type
        const serviceType = epRef.serviceType;

        // TODO: support backend based programmatic endpoints "@<backendName>#<modName>/<endpointName>"
        // if (!serviceType) {
        //   // if endpoint reference has backend instead of the service type
        //   // resolve the backend to find its service type
        //   const backendName = epRef.backendName;
        //   if (backendName) {
        //   }
        // }

        if (serviceType) {
          // get endpoint resolver plugin
          const services = ServiceResolverFactory.getServices(serviceContext);

          try {
            // use the serviceContext to see if there is extension level plugin
            const serviceTypePlugin = await services.getServiceTypeEndpointResolver(serviceType, epRef.namespace);

            // first let plugin tokenize the endpoint reference
            // boss#ora/common/bossLabsCollege/order_lines/$getMany
            // ora/common/bossLabsCollege/order_lines/$getMany => (ora/common/bossLabsCollege)/order_lines
            //    module: ora/common/bossLabsCollege eq. oraCommonBossLabsCollege
            //
            // ora/labsRedwoodRef/storeProducts/storeProducts/update =>
            //    moduleName: ora/labsRedwoodRef/storeProducts
            //    endpointName: storeProducts/update

            // If EndpointReference has 'module' property, it means that it contained '#' in its string.
            // RT parses the reference assuming that everything after the first '/' is operation identifier
            // but we really do not know, as the whole endpoint reference section after the '#' is
            // internal to the service type (and its resolver) and does not have meaning to the RT.
            // That is why we let the plugin parse the reference for us.
            // TODO: this should be done at the endpoint reference creation time
            if (serviceTypePlugin && typeof serviceTypePlugin.splitEndpointReference === 'function') {
              const moduleName = epRef.moduleName;
              const endpointName = epRef.endpointName;

              // part of the endoint reference after '#'
              const endpointReference = endpointName ? `${moduleName}/${endpointName}` : moduleName;
              const splitInfo = serviceTypePlugin.splitEndpointReference(endpointReference) || {};

              // The returned split info must have none empty 'moduleName' and 'endpointName'
              // properties to be considered valid. If the split info is not valid we ignore it.
              if (splitInfo && splitInfo.moduleName && splitInfo.endpointName) {
                epRef.adjustEndpoint(splitInfo);
              }
            }
          } catch (err) {
            logger.warn(err);
          }
        }
      }

      return epRef;
    }

    getReference(endpointId, serviceContext) {
      return new EndpointReference(endpointId, serviceContext);
    }

    getServiceOnlyReference(serviceId, serviceContext) {
      return new EndpointReference(serviceId, serviceContext);
    }
  }

  return new EndpointReferenceFactory();
});

