/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/services/serviceResolver',[
], (
) => {
  // VBS-29855: leave it as 'extensionId' property for now
  const extensionIdSymbol = 'extensionId'; // Symbol('vb.service.extensionId');

  const servicesSymbol = Symbol('vb.service.services');
  const altServicesSymbol = Symbol('vb.service.alt-services');

  /**
   * Public context aware utilities for answering questions about services.
   *
   * This is public contract exposed via $vbContext of container context, and
   * getServiceResolver() getter in the Module constructor context parameter.
   *
   * @public
   */
  class ServiceResolver {
    /**
     *
     * @param {object} options
     * @param {string} options.extensionId
     * @param {string} options.factoryID
     * @param {Services} options.services Calling context services
     * @param {Services} [options.altServices] Source context services, if different from options.services
     */
    constructor({
      extensionId, factoryID,
      services, altServices,
    }) {
      // Related to VBS-29855: if we do not provide property with a setter
      // AggregateServiceDataProvider receives an empty vbContext object.
      // It was verified that vbContext AggregateServiceDataProvider receives is writable,
      // indicating someone is cloning it somewhere on the stack.
      this[extensionIdSymbol] = extensionId;

      this[servicesSymbol] = services;
      this[altServicesSymbol] = altServices || services;

      this.factoryID = factoryID;

      Object.freeze(this);
    }

    /**
     * Delegates getRelatedEndpointInfo() call to the EndpointResolver plugin
     * associated with the service type of the given endpoint ID.
     *
     * @param {string} endpointId
     * @param {...*} relatedHints
     * @returns {Promise<Object>} Object structure provided by the plugin
     */
    async getRelatedEndpointInfo(endpointId, ...relatedHints) {
      const serviceType = endpointId.split('#')[0];
      const serviceTypePlugin = await this[servicesSymbol].getServiceTypeEndpointResolver(serviceType);
      if (typeof serviceTypePlugin.getRelatedEndpointInfo === 'function') {
        return serviceTypePlugin.getRelatedEndpointInfo(endpointId, ...relatedHints);
      }
      throw new Error(`EndpointResolver for '${serviceType}' does not provide getRelatedEndpointInfo API.`);
    }

    static getNamespace(serviceContext) {
      return serviceContext[extensionIdSymbol];
    }
  }
  return ServiceResolver;
});

