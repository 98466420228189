'use strict';

define('vb/private/pwa/swLogOptions',[
  'vbc/private/monitorOptions',
  'vb/private/utils',
], (
  MonitorOptions,
  Utils,
) => {
  /**
   * Represents a log monitor options for a Web PWA Service Worker Log messages.
   */
  class SwLogOptions extends MonitorOptions {
    constructor(traceMessage, parentSpan) {
      super(MonitorOptions.SPAN_NAMES.SW_LOG);

      this.addStartMessage(traceMessage.args.join(' '));
      this.addStartFields(() => ({
        parentSpan,

        // Use the actual time, don't let tracer fill in current time
        startTime: traceMessage.time,

        // Set spanContext.level to type, and we get, e.g., [VB INFO swLog] / [VB INFO swLog]
        // Otherwise it is [VB TRACE swLog] / [VB TRACE swLog]
        level: traceMessage.type, // info, warn, error

        // NOTE: This is necessary to ensure that the trace library nests log spans properly
        priority: 1,
      }));

      // If this is a log message not within a trace span, add the sw version tags
      if (!parentSpan) {
        // Need to flatten our trace tags
        this.addTags(() => Utils.flatten({
          vb: {
            sw: traceMessage.sw,
          },
        }));
      }

      this.addEndMessage('done');
      this.addEndFields(() => ({
        // Use the actual time, don't let tracer fill in current time
        endTime: traceMessage.time,

        level: traceMessage.type, // info, warn, error
      }));
    }
  }

  return SwLogOptions;
});

