'use strict';

define('vb/private/services/servicesManager',[
  'vb/private/services/endpointReferenceFactory',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/application',
],
(EndpointReferenceFactory, Router, Application) => {
  /**
   * this is the interface for getting endpoints for the active container (previously, Services was a singleton).
   * Also where Service Providers are registered (ultimately live in the Application's Services property).
   *
   */
  class ServicesManager {
    /**
     * Get an endpoint
     * First, check the active Page; if it has a services object, and that contains the endpoint, return that.
     * Otherwise, check if the Application has any services registered, and contains the endpoint.
     * @param {EndpointReference} endpointReference
     * @param {*} [serviceContext] - not used here (see OperationRefEndpointProvider).
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @return {Promise<Endpoint>}
     * @public
     */
    static getEndpoint(endpointReference, serviceContext, serverVariables) {
      // eslint-disable-next-line no-underscore-dangle
      return ServicesManager._findEndpoint(endpointReference, serverVariables)
        .then((tuple) => tuple && tuple.endpoint);
    }

    /**
     * @typedef {Object} EndpointDefinitionInfo
     * @property path
     * @property [operationDef]
     * @property [serviceDef]
     * @property configInfo
     * @property requestInit
     */
    /**
     * return the service definition (openapi, swagger),
     * and additional information used to fetch the service
     * @param {EndpointReference} endpointReference
     * @param {boolean} [checkAccessible=true] Return found service definition only
     *                                  if it is accessible from the endpoint reference's context
     * @returns {Promise<EndpointDefinitionInfo|*>}
     * @public
     */
    static getDefinitionInfo(endpointReference, checkAccessible = true) {
      return ServicesManager.FindDefinition(endpointReference, undefined, checkAccessible, true)
        .then((foundInfo) => (foundInfo ? {
          service: foundInfo.service,
          requestInit: foundInfo.requestInit,
          serviceDef: foundInfo.serviceJSON,
          endpoint: foundInfo.endpoint,
          configInfo: foundInfo.configInfo, // This is never set!
        } : null));
    }

    /**
     * Used to programmatically register service definitions. These are registered at the Application level.
     * @param serviceProvider
     * @return {Promise}
     * @public
     */
    static addServiceProvider(serviceProvider) {
      return Application.getServices().addServiceProvider(serviceProvider);
    }

    /**
     * find the Service object that contains the declaration for the service ID, by getting the container's
     * array of Services objects, which are all Services objects in the container hierarchy.
     * We then search those Services objects for the Service.
     *
     * Finding the Service does not automatically mean that the referenced endpoint can be loaded/used.
     * Caller needs to check if the referenced endpoint can be accessed after the service definition is fetched.
     *
     * Services:Container :: one:one, each Services contains a map of Service
     *
     * @param {EndpointReference} endpointReference
     * @returns {Promise<Services>} resolved with Services
     */
    static findContainerServices(endpointReference) {
      const pageOrFlow = Router.getCurrentPage();
      const allServices = (pageOrFlow && pageOrFlow.getAllServices()) || Application.getAllServices();

      //
      // Endpoints with fully qualified names can be found by their namespace
      //

      if (endpointReference.namespace) {
        const servicesArray = allServices.filter((s) => s.namespace === endpointReference.namespace);

        const handler = (services, er) => services.containsDeclaration(er).then((result) => (result ? services : null));

        return servicesArray.reduce(
          (promise, services) => promise.then((result) => result || handler(services, endpointReference)),
          Promise.resolve(),
        );
      }

      //
      // Endpoints without the namespace have to be found based on the calling context (i.e. container)
      //

      // These Services objects are starting points for the handler's search/traversal
      const servicesArray = allServices.filter((s) => s.namespace === endpointReference.containerNamespace);

      // Look for the endpointReference on the traversed "services" as well as its delegate services.
      // Using a set to avoid looking for the endpoint reference in the same "services".

      const set = new Set();
      const handler = (services, er) => {
        if (set.has(services)) {
          return false;
        }
        set.add(services);
        return Promise.resolve()
          .then(() => services.isEndpointReferenceCompatible(endpointReference) && services.containsDeclaration(er))
          .then((result) => (result ? services : services.searchDelegates((delegate) => handler(delegate, er))));
      };

      return servicesArray.reduce(
        (promise, services) => promise.then((result) => result || handler(services, endpointReference)),
        Promise.resolve(),
      );
    }

    /**
     * Finds instance of the Services that should be queried for services in the given context.
     *
     * @param {*} servicesContext ServiceResolver's context
     * @returns {Services}
     */
    static findContextServices(servicesContext) {
      if (servicesContext) {
        // it is container
        if (typeof servicesContext.getServices === 'function') {
          return servicesContext.getServices();
        }

        // or at least has an extensionId
        const { extensionId } = servicesContext;
        if (extensionId) {
          const extensions = Application.extensionsArray || [];
          const extension = extensions.find((ext) => ext.extensionId === extensionId);
          if (extension) {
            return extension.services;
          }
        }
      }

      return Application.getServices();
    }

    /**
     * search declared service definitions for the endpoint (serviceId/operationId)
     *
     * @param endpointReference {EndpointReference}
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {Promise} resolved with {endpoint}
     * @private
     */
    static _findEndpoint(endpointReference, serverVariables) {
      return ServicesManager.findContainerServices(endpointReference)
        .then((services) => services && services.getEndpoint(endpointReference, serverVariables))
        .then((endpoint) => endpoint && { endpoint });
    }

    /**
     * Finds definition of EndpointReference, loading it if not already loaded.
     * It returns object with the ServiceDefinition and the Endpoint if it is found.
     *

     * @param {EndpointReference} endpointReference
     * @param {boolean} [checkAccessible=true] Return found service definition only
     *                                  if it is accessible from the endpoint reference's context
     * @returns {Promise<Object|null>} resolved with the Swagger/OpenApi from the ServiceDefinition
     * @protected
     */
    static async FindDefinition(endpointReference, serverVariables, checkAccessible = true, extraInfo = false) {
      if (!endpointReference.serviceId) {
        // this should NEVER happen
        throw new
        Error(`An EndpointReference with no service ID was used, trying to find a service: ${endpointReference}`);
      }

      try {
        const services = await ServicesManager.findContainerServices(endpointReference);
        if (!services) {
          return null;
        }

        const serviceId = endpointReference.getQualifiedServiceId(services.namespace);
        const serviceDefinition = await services.LoadService(serviceId, false,
          endpointReference, serverVariables);
        if (!serviceDefinition) {
          return null;
        }
        // we need to load the service to know if it accessible
        // as it can contain metadata describing the rules for accessing it
        if (checkAccessible && !services.isServiceAccessible(serviceDefinition, endpointReference)) {
          // we can not use this service for the endpoint
          return null;
        }

        const definitionStructure = {
          service: serviceDefinition,
          requestInit: serviceDefinition.requestInit,
        };

        // if needed resolve ServiceDef JSON (this may be costly for programmatic endpoints),
        // and the ednpoint
        if (extraInfo) {
          // openApi is OpenApiObjectCommon, and its definition is simple JS Object
          const [serviceJSON, endpoint] = await Promise
            .all([serviceDefinition.getDefinition(endpointReference),
              serviceDefinition.findEndpoint(endpointReference)]);

          definitionStructure.serviceJSON = serviceJSON;
          definitionStructure.endpoint = endpoint;
        }

        return definitionStructure;
      } catch (err) {
        return null;
      }
    }

    // TODO: make FindDefinition accept OperationRef EndpointReference as an input, and consolidate with this method
    /**
     * Finds definition of a service at given location, loading it if not already loaded.
     * It returns object with the ServiceDefinition if it is found, otherwise it returns null.
     *
     * @param {string} openApiUrl
     * @param {*} serviceContext
     * @returns {Promise<Object|null>}
     * @protected
     */
    static async FindDefinitionByUrl(openApiUrl, serviceContext) {
      // services model for the given context
      const contextServices = ServicesManager.findContextServices(serviceContext);

      // recusrively search for service by its URL
      const set = new Set();
      const handler = (services) => {
        // skip sercices we already visited
        if (set.has(services)) {
          return null;
        }
        set.add(services);
        return Promise.resolve()
          .then(() => services.LoadServiceFromUrl(openApiUrl))
          // continue search through the delegates if service not found
          .then((result) => (result || services.searchDelegates((delegate) => handler(delegate))));
      };

      const serviceDefinition = await handler(contextServices);
      if (serviceDefinition) {
        // return same data structure as the FindDefinition.
        return {
          service: serviceDefinition,
          requestInit: serviceDefinition.requestInit,
        };
      }
      return null;
    }

    /**
     * looks for the containing Services in the container hierarchy, and disposes the specific service
     * @param {string} id declared id of the service
     * @returns {Promise<Services>} resolved with Services, or rejected with text message
     */
    static disposeService(id) {
      return Promise.resolve()
        .then(() => ServicesManager.findContainerServices(EndpointReferenceFactory.getServiceOnlyReference(id)))
        .then((services) => {
          if (services) {
            services.disposeService(id);
            return services;
          }

          throw new Error(`unable to find service to dispose: ${id}`);
        });
    }
  }

  return ServicesManager;
});

