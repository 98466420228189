'use strict';

define('vb/private/stateManagement/eventHandlingMixin',[
  'vb/private/constants',
], (Constants) => {
  /**
   * Mixin that is used by the fragment and its extension containers to determine
   * whether the event auto-wiring is supported.
   */
  const EventHandlingMixin = (superclass) => class extends superclass {
    /**
     * Called to determine if the event auto-wiring is supported by the current container.
     * Currently the auto-wiring of events is only supported by fragments and only for the
     * events with the propagationBehavior set to 'container'.
     * @param eventDef event definition
     * @return {boolean} true if the event auto-wiring is allowed; false otherwise
     */
    // eslint-disable-next-line class-methods-use-this
    allowsEventAutoWiring(eventDef) {
      return eventDef.propagationBehavior === Constants.EventPropagationBehaviors.CONTAINER;
    }
  };

  return EventHandlingMixin;
});

