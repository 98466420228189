'use strict';

define('vb/private/services/definition/openApiServiceDefFactory',[
  'urijs/URI',
  'vb/private/configLoader',
  'vb/private/log',
  'vb/private/services/servicesLoader',
  'vb/private/services/serviceUtils',
  'vb/private/services/definition/serviceDefinition',
  'vb/private/services/definition/serviceDefFactory',
  'vb/private/services/readers/openApiObjectFactory',
  'vb/private/constants',
], (
  URI,
  ConfigLoader,
  Log,
  ServicesLoader,
  ServiceUtils,
  ServiceDefinition,
  ServiceDefFactory,
  OpenApiObjectFactory,
  Constants,
) => {
  const logger = Log.getLogger('/vb/private/services/definition/OpenApiServiceDefFactory');

  const defaultComparator = (serviceUri, url) => serviceUri.equals(url);

  // Special case ADF /describe & /describe.openapi
  // For them ignore queryParameters other then the 'resources'
  //
  // Declarations: /describe.openapi?metadataMode=minimal&resources=positionsLovV2
  // should match: /describe.openapi?resources=positionsLovV2
  //
  // Declarations: /describe.openapi?metadataMode=minimal&resources=positionsLovV2,positions
  // should also match: /describe.openapi?resources=positionsLovV2
  const describeComparator = (serviceUri, url) => {
    if (defaultComparator(serviceUri, url)) {
      return true;
    }

    const uri = new URI(url);
    // compare paths without query params
    if (serviceUri.clone().search('').href() === uri.clone().search('').href()) {
      const serviceUrl = new URL(serviceUri.toString());
      const matchingUrl = new URL(url);
      const matchingResources = matchingUrl.searchParams.get('resources');
      if (matchingResources) {
        const serviceResources = serviceUrl.searchParams.get('resources');
        if (serviceResources) {
          // we should be doing this better
          return serviceResources.includes(matchingResources);
        }
        // if declared service does not name resources it means it should include all avaialble resources
        return true;
      }
    }
    return false;
  };

  const getComparator = (uri) => {
    const path = uri.path();
    // Special case ADF /describe & /describe.openapi
    if (path.endsWith('/describe') || path.endsWith('/describe.openapi')) {
      return describeComparator;
    }
    return defaultComparator;
  };

  /**
   * Abstract class providing implementation layer for ServiceDefinition factories to load
   * the definitions from OpenApi resoirces.
   *
   * @abstract
  */
  class OpenApiServiceDefFactory extends ServiceDefFactory {
    constructor(services, options) {
      super(services);
      const {
        relativePath,
        isUnrestrictedRelative,
        protocolRegistry,
      } = options;

      this._protocolRegistry = protocolRegistry;
      this._isUnrestrictedRelative = !!isUnrestrictedRelative;
      this._relativePath = relativePath || '';

      /**
       * serviceId => ServiceDeclaration
       * ServiceDeclaration: { path: <pathToServiceJSON>, type: 'serviceMap'}
       */
      this._serviceFileMap = {};
    }

    /**
     * @returns {boolean}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    supports(endpointReference) {
      return !endpointReference.isProgrammatic;
    }

    /**
     * @override
     * @protected Only called internally by getDeclaration()
     */
    findDeclaration(endpointReference) {
      let foundDecl;
      if (!endpointReference.serviceName && endpointReference.serviceUrl) {
        const foundName = Object.keys(this._serviceFileMap)
          .find((name) => this.matchesUrl(this._serviceFileMap[name], endpointReference.serviceUrl));
        foundDecl = this._serviceFileMap[foundName];
      } else {
        foundDecl = this._serviceFileMap[endpointReference.serviceName];
      }

      // ensure that declaration has a service name. Some tests hard code service map w/o it.
      if (foundDecl && !foundDecl.name) {
        foundDecl.name = endpointReference.serviceName;
      }
      return foundDecl;
    }

    async getDeclaration(endpointReference) {
      await this.updateServiceDeclarations();

      // if we are searching for decalaration based on the OpenApi locaiton (endpointReference.serviceUrl)
      // we need to resolve all the URLs in our map
      if (!endpointReference.serviceName && endpointReference.serviceUrl) {
        await this.updateServiceUrls();
      }
      return this.findDeclaration(endpointReference);
    }

    /**
     *
     * @param {EndpointReference} endpointReference
     * @param {Object} declaration Service declaration
     * @param {Object} [serverVariables]
     * @returns {Promise<ServiceDefinition>}
     * @override
     */
    loadDefinition(endpointReference, declaration, serverVariables) {
      return Promise.resolve()
        .then(() => {
          if (typeof declaration === 'string') {
            // eslint-disable-next-line no-param-reassign
            declaration = { path: declaration };
          }
          const serviceName = declaration.name || endpointReference.serviceName;

          // declaration will be { path: '...', <headers: {...}> }

          // path might be an expression, evaluate it
          if (!declaration.evaluatedPath) {
            // eslint-disable-next-line no-param-reassign
            declaration.evaluatedPath = this.services.evaluatePathDeclaration(declaration.path);
          }

          const fileName = declaration.evaluatedPath;

          // allow for an alternate 'proxyName' as an internal workaround for when the declaration name
          // does not match the name of the service in the proxy url.

          if (this.services.isPathAllowed(fileName)) {
            return this.loadServiceFromPath(serviceName, fileName, declaration.headers, serverVariables);
          }
          return null;
        });
    }

    /**
     * @override
     */
    getAllServiceIds() {
      return Object.keys(this._serviceFileMap).map((serviceName) => `${this.services.namespace}:${serviceName}`);
    }

    //------------------------------------------------------------------------------

    /**
     * Resolves all service paths into their URLs. This information needs to be avialable
     * for us to find endpoint reference based on the service location, i.e. for operationRefs.
     */
    async updateServiceUrls() {
      try {
        const needsResolution = Object.keys(this._serviceFileMap)
          .some((name) => !this._serviceFileMap[name].resolvedUrl);

        // we want to avoid repeated resolution of vb-catalog:// URLs
        // we do it only once and then store the resolved value
        if (needsResolution) {
          // Array<{ name, decl, url }>
          const servicesDeclList = Object.keys(this._serviceFileMap)
            .map((name) => {
              const decl = this._serviceFileMap[name];
              let url;
              if (!decl.resolvedUrl) {
                // first evaluate any expressions
                if (!decl.evaluatedPath) {
                  decl.evaluatedPath = this.services.evaluatePathDeclaration(decl.path);
                }
                // then resolve it in the context of the services
                if (!decl.url) {
                  decl.url = this.resolvePath(decl.evaluatedPath);
                }
                url = decl.url;
              }
              // Intentially do not set url value for already resolved path, leave url value null.
              // That way CatalogHandler will not do any work for it in resolveUrls() call.
              return { name, decl, url };
            });

          // resolve
          // const resolvedUrls = await this.resolveUrls(servicesDeclList.map((e) => e.decl && e.decl.url));
          const resolvedUrls = await this.resolveDeclarations(servicesDeclList);

          // store all resolved value back on declarations
          resolvedUrls.forEach((resolvedUrl, index) => {
            if (resolvedUrl) {
              const e = servicesDeclList[index];
              e.decl.resolvedUrl = resolvedUrl;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    }

    /**
     * Evaluate any expression in the path, and resolves it relateive to the services.
     * @param {string} path
     * @returns
     */
    resolvePath(path) {
      if (path) {
        let fileName = this.services.evaluatePathDeclaration(path);
        if (this.services.isPathAllowed(fileName)) {
          fileName = this.services.getDefinitionPath(fileName);
        }
        return fileName;
      }
      return null;
    }

    async resolveUrls(urls) {
      const handler = ConfigLoader.protocolRegistry.getHandler(Constants.VbProtocols.CATALOG);
      // in some unit tests this may be missing
      if (handler) {
        const resolvedUrls = await handler.resolveUrls(urls, this.services.namespace);
        return resolvedUrls;
      }
      return [];
    }

    async resolveDeclarations(decls) {
      const resolved = decls.map(async (decl) => {
        if (!decl.url) {
          return undefined;
        }

        try {
          const catalogInfo = await ServicesLoader.getCatalogExtensions(
            this._protocolRegistry,
            decl.url,
            decl.name,
            this.services.namespace,
            // serverVariables,
            // declaredHeaders,
          );
          let url = catalogInfo.url;
          const metadata = (catalogInfo.services && catalogInfo.services.metadata);
          if (metadata) {
            // if the catalog has a "paths" to declare how to fetch the openapi3, use that
            url = ServiceUtils.getServiceUrlFromMetadata(catalogInfo.url, metadata);
          }
          return url;
        } catch (err) {
          return decl.url;
        }
      });

      return Promise.all(resolved);
    }

    // eslint-disable-next-line class-methods-use-this
    matchesUrl(decl, serviceUrl) {
      if (decl && decl.resolvedUrl) {
        const declUri = decl.uri || (decl.uri = new URI(decl.resolvedUrl));

        // Special case ADF /describe & /describe.openapi
        const comp = decl.comp || (decl.comp = getComparator(declUri));

        return comp(declUri, serviceUrl);
      }
      return false;
    }

    /**
     * Clients should not invoke this method directly.
     *
     * @param {string} serviceName
     * @param {string} path
     * @param {object} [declaredHeaders] will be merged with the catalog headers, if any (catalog takes precedence)
     * @param {object} [serverVariables] the serverVariables, if any.
     * @protected
     */
    loadServiceFromPath(serviceName, path, declaredHeaders, serverVariables) {
      // offset by the location of the container
      let catalogInfo;
      let requestInit;
      let fileName;

      return Promise.resolve()
        .then(() => {
          fileName = this.services.getDefinitionPath(path);

          // During URL resolution this method will trigger url "opened" signal, which will update URLMapper.
          return ServicesLoader
            .getCatalogExtensions(
              this._protocolRegistry,
              fileName,
              serviceName,
              this.services.namespace,
              serverVariables,
              declaredHeaders,
            );
        })
        .then((catInfo) => {
          catalogInfo = catInfo;

          // used by Services, and merges with "backends" extensions in the Endpoint
          // services.extensions only contains headers currently, so we can use in Request construction directly
          requestInit = (catalogInfo.services && catalogInfo.services.extensions);

          const metadata = (catalogInfo.services && catalogInfo.services.metadata);

          // TODO: if metadata.extensiosn has serviceType check if we have programatic endpoint handler,
          // and avoid loading/fetching OpenApi object
          if (metadata) {
            // if the catalog has a "paths" to declare how to fetch the openapi3, use that
            return OpenApiServiceDefFactory
              .getOpenApiObjectUsingMetadata(serviceName, catalogInfo.url, metadata, requestInit, this.services);
          }
          return OpenApiServiceDefFactory.getOpenApiObject(serviceName, catalogInfo.url, requestInit, this.services);
        })
        .then((openApi) => this.createServiceDefinition(
          serviceName,
          fileName,
          catalogInfo,
          openApi,
          requestInit,
          serverVariables,
        ))
        .catch((e) => {
          logger.error('service load error: ', e);
          // throw e;
          return null; // allow the rest of the loads to pass
        });
    }

    /**
     * construct a ServiceDefinition
     * @param {string} serviceName name of the service; the property name from the app-flow.json declaration
     * @param {string} fileName service metadata (openapi/swagger) path
     * @param catalogInfo services/backends information from any vb-catalog references
     * @param openApi service metadata object (OpenApiCommonObject)
     * @param {Object} requestInit additional config for a Request object (headers, etc).
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {ServiceDefinition}
     * @private
     */
    createServiceDefinition(serviceName, fileName, catalogInfo, openApi, requestInit, serverVariables) {
      const service = new ServiceDefinition(serviceName, fileName, this._protocolRegistry, catalogInfo, openApi,
        this._relativePath, requestInit, this.services.namespace, this._isUnrestrictedRelative);

      // notify listeners
      try {
        ServicesLoader.notify(service, serverVariables);
      } catch (e) {
        logger.error(e);
      }

      return service;
    }

    /**
     * if we're using a "paths" object from the catalog,
     * we need to merge the 'x-vb' from the "servers" object with the 'x-vb' from both the
     * "info" object for the openapi3 "services" fragment, and its operation (ex. "get") object.
     *
     * for example, use the proxy when fetching the /describe below, use the "accepts" for the fetch,
     * and apply "some/transforms" to the result.
     *
     * this is analogous to what we do today in just swagger/openapi3 with no catalog.json involved; we
     * look at "info", "services", and "paths", when we construct a merged "x-vb" (listed least-to-most precedence).
     *
     *
     * "services": {
     * "demo": {
     *   "openapi": "3.0",
     *   "info": {
     *     "title": "uses new inner-service-openapi3-metadata syntax",
     *     "x-vb": {
     *       "transforms": {
     *         "path": "some/transforms"
     *       }
     *     }
     *   },
     *   "servers": [
     *     {
     *       "url": "vb-catalog://services/demolevel2",
     *       "x-vb": {
     *           "authentication": {
     *               "forceProxy": "cors"
     *           }
     *       }
     *     }
     *   ],
     *   "paths": {
     *     "somepath/describe": {
     *       "get": {
     *         "x-vb": {
     *           "headers": {
     *            "Accepts":  "application/vnd.oracle.adf.openapi3+json"
     *           }
     *         }
     *       }
     *     }
     *   }
     * },
     *
     * @param serviceName
     * @param serverUrl typically, from the resolved "servers" object
     * @param metadata  the 'paths.get" path (and query) will be appended to the url, and the extensions merged.
     * @param requestInit
     * @param {Services} services context the OpenApi document is being loaded in
     * @returns {Promise}
     *
     * @private
     */
    static getOpenApiObjectUsingMetadata(serviceName, serverUrl, metadata, requestInit, services) {
      return Promise.resolve()
        .then(() => {
          const mergedExtensions = ServiceUtils.getExtensionsFromMetadata(serverUrl, metadata, requestInit);

          const { url } = mergedExtensions;
          delete mergedExtensions.url;

          return OpenApiServiceDefFactory.getOpenApiObject(serviceName, url, mergedExtensions, services);
        });
    }

    /**
     * get the service definition, and treat as JSON (no swagger parsing)
     * Will timeout (reject) after Constants.Services.definitionTimeout (30) seconds
     *
     * @param serviceName
     * @param url
     * @param {{ headers: Object, transforms: { path: string }, resolvedUrl: string }} additionalExtensions
     * @returns {Promise} resolved with service definition object
     *
     * @private
     *
     * @todo: Services should have its own protocolRegistry, and a parent-fallback similar to flow/app service defs.
     */
    static getOpenApiObject(serviceName, url, additionalExtensions, services) {
      // 'additionalExtensions' has VB properties that we define for extensions, which includes 'headers';
      // Because it has a 'headers' property, it resembles an 'init param for a fetch() call, so we can use it as
      // an 'init param, and fetch/Request will ignore what it does not care about.
      const initParam = Object.assign({}, additionalExtensions);
      return services.fetchServiceDefinition(url, initParam)
        .then((def) => {
          const context = {};
          context.services = services;
          context.definitionUrl = initParam.resolvedUrl || url;
          return OpenApiServiceDefFactory.getOpenApi(def, context);
        });
    }

    /**
     * creates the appropriate model for swagger (2) or openapi3 (3+)
     * @param def
     * @param {object} [context] abstraction of Application context. optional, used for variables substitution.
     * @returns {Promise<OpenApiObjectCommon>}
     * @private
     */
    static getOpenApi(def, context) {
      return Promise.resolve()
        .then(() => {
          const config = context
            ? Object.assign({}, context, { initParams: ConfigLoader.initParams })
            : { initParams: ConfigLoader.initParams };
          return OpenApiObjectFactory.get(def, config);
        })
        .catch((ex) => {
          logger.error('unable to resolve service references:', ex);
          throw ex;
        });
    }
  }

  return OpenApiServiceDefFactory;
});

