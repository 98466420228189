'use strict';

define('vb/private/stateManagement/redux/basicStoreManager',['vb/private/log', 'redux'],
  (Log, Redux) => {
    const log = Log.getLogger('/vb/stateManagement/redux/basicStoreManager');

    /**
     * A base class to manage a redux store.
     * Known subclass is undoStoreManager which support undo/redo for time travel.
     */
    class BasicStoreManager {
      constructor() {
        /**
         * The redux store
         * @type {Object}
         */
        this.store = null;

        /**
         * Map of reducers
         *  @type {Object}
         */
        this.reducers = null;
      }

      /**
       * Description used by the log
       * @type {String}
       */
      // eslint-disable-next-line class-methods-use-this
      get name() {
        return 'redux store manager without Time Travel';
      }

      /**
       * Initialize the store manager. Create a redux store and apply middleware.
       * @param  {Object} reds the reducers
       * @return {Store} the redux store
       */
      init(reds) {
        log.info('Initializing', this.name);
        if (this.store) {
          throw new Error('StoreManager is already initialized.');
        }

        this.reducers = reds;

        this.store = Redux.createStore(this.combineReducers(),
          // No default state
          {});

        return this.store;
      }

      /**
       * Combines the reducers. Subclass like underStoreManager have their own way to
       * combine reducers
       * @return {Function} A reducer that invokes every reducer inside the this.reducers object
       */
      combineReducers() {
        if (this.reducers) {
          return Redux.combineReducers(this.reducers);
        }
        return (state) => (state || {});
      }

      /**
       * Add a scope to the redux store. It combines the reducers for each variables
       * with the existing array of reducers
       * @param {Scope} scope a scope object
       */
      addScopeToStore(scope) {
        // Collect each variable reducer
        const variableReducers = scope.initVariableReducers();

        // If no variable in the scope yet, nothing to do
        if (Object.keys(variableReducers).length === 0) {
          return;
        }

        log.fine('Adding scope', scope.name);
        const red = this.reducers;

        const scopeReducer = Redux.combineReducers(variableReducers);

        if (red) {
          if (red[scope.name]) {
            throw new Error(`Scope ${scope.name} already exist in the store.`);
          }

          red[scope.name] = scopeReducer;
        } else {
          this.reducers = { [scope.name]: scopeReducer };
        }

        this.store.replaceReducer(this.combineReducers());

        scope.syncWithStore(this.getStore());
      }

      /**
       * Remove a scope from the redux store. It removes the scope reducers from the
       * array of reducers.
       * @param  {Scope} scope a scope object
       */
      removeScopeFromStore(scope) {
        log.fine('Removing scope', scope.name);
        const red = this.reducers;

        if (!red) {
          return;
        }

        // Remove the reducer
        delete red[scope.name];
        // Delete the state keyed by id before calling replaceReducers otherwise
        // redux complains rightfully about state not match reducers.
        delete this.getState()[scope.name];

        this.store.replaceReducer(this.combineReducers());
      }

      /**
       * Return the state from the redux store
       * @return {Object} the state
       */
      getState() {
        return this.store.getState();
      }

      /**
       * Return the redux store
       * @return {Store} the redux store
       */
      getStore() {
        return this.store;
      }

      dispose() {
        this.store = null;
        this.reducers = null;
      }
    }

    return BasicStoreManager;
  });

