'use strict';

define('vbsw/private/plugins/errorResponseHandlerPlugin',['vbsw/api/fetchHandlerPlugin'],
  (FetchHandlerPlugin) => {
    // list of response type that we want to read body for
    const RESPONSE_TYPES = [
      'application/problem+json',
      'application/vnd.oracle.adf.error+json',
      'application/vnd.oracle.resource+json',
    ];
    // limit the size of body we pre-fetch
    // some server cert detials can be a bit larger
    const CONTENT_SIZE_LIMIT = 16000;
    /**
     * The ErrorResponseHandlerPlugin provides a workaround for Chrome browser bug
     * https://bugs.chromium.org/p/chromium/issues/detail?id=1101923.
     *
     * That bug negatively affects us while diagnosing network related connection issues as we are
     * missing crutial data from the response body in both CDT and exported HAR files.
     * This plugin works around that by reading the body of the response for following response content types:
     * - 'application/problem+json'
     * - 'application/vnd.oracle.adf.error+json'
     * - 'application/vnd.oracle.resource+json'
     *
     */
    class ErrorResponseHandlerPlugin extends FetchHandlerPlugin {
      constructor(context, params = {}) {
        super(context);
        // allow the list of mime types to be provided externally
        this.errorResponseTypes = (params.errorResponseTypes && Array.isArray(params.errorResponseTypes))
          ? params.errorResponseTypes
          : RESPONSE_TYPES;

        this.contentSizeLimit = params.contentSizeLimit || CONTENT_SIZE_LIMIT;
      }

      handleResponseHook(response) {
        if (response.ok === false) {
          const responseType = response.headers.get('Content-Type');
          if (responseType
            && this.errorResponseTypes.some((contentType) => responseType.includes(contentType))) {
            return Promise.resolve()
              // we have to clone the response as other clients may want to read it
              .then(() => !response.bodyUsed && this.fetchBody(response))
              .catch(() => false);
          }
        }

        return Promise.resolve(false);
      }

      /**
       * Fetch the body, and always return false so that other response hooks will get called
       * @param {*} response
       * @returns false
       */
      fetchBody(response) {
        // do this only when content is not too big
        const contentLengthStr = response.headers.get('Content-length');
        if (contentLengthStr) {
          try {
            const contentLength = parseInt(contentLengthStr, 10);
            if (contentLength > this.contentSizeLimit) {
              return false;
            }
          } catch (err) {
            return false;
          }
        }

        // do not wait for body
        response.clone().text();
        return false;
      }
    }

    return ErrorResponseHandlerPlugin;
  });

