'use strict';

define('vb/extensions/dynamic/private/models/metadataRulesExtension',[
  'vb/extensions/dynamic/private/models/layoutResourceExtension',
], (
  LayoutResourceExtension,
) => {
  /**
   * MetadataRulesExtension is a subclass of LayoutResourceExtension used to load metadata-rules-x related resources,
   * which have special Translation Bundles semantics (v1 bundle definitions are preferred over v2 bundle definitions).
   */
  class MetadataRulesExtension extends LayoutResourceExtension {
    /**
     * MetadataRulesExtension constructor
     *
     * @param extension the extension from which to load the resource
     * @param {String} path the absolute path to the layout resource, e.g., vb/extA/dynamicLayouts/self/employee
     * @param base the base layout resource
     * @param className the class name
     */
    constructor(extension, path, base, className = 'MetadataRulesExtension') {
      super(extension, path, base, className);
    }

    static get extensionClass() {
      return MetadataRulesExtension;
    }

    /**
     * Overrides the base impl, so we can add "preferV1: true"
     * (v1 bundle definitions are preferred over v2 bundle definitions)
     *
     * @returns {Promise}
     */
    loadTranslationBundles() {
      // If V1 translations are present, ignore V2 translations.
      return super.loadTranslationBundles({ preferV1: true });
    }
  }

  return MetadataRulesExtension;
});

