/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/translations/bundleV2Definition',[
  'knockout',
  'urijs/URI',
  'vb/private/constants',
  'vb/private/log',
  'vb/private/pathHandler',
  'vb/private/utils',
  'vb/private/translations/bundleUtils',
  'vb/private/translations/bundleProxies',
  'vb/private/translations/bundleV2Extension',
], (
  ko,
  URI,
  Constants,
  Log,
  PathHandler,
  Utils,
  BundleUtils,
  { createBundleV2Proxy },
  BundleV2Extension,
) => {
  const logger = Log.getLogger('/vb/private/translations/bundleV2Definition');

  // Remove translations/self/ from the start of bundle path when finding matching -x
  const translationsSelfRegex = /^(translations\/self\/)(.*)$/;
  // This matches src/vb/private/helpers/runtimeEnvironment.js
  const badStringUrlSegmentRegex = /\/(undefined|null)\//gi;

  /**
   * Determine if the path starts with a '/'
   * @param path
   */
  function isRootRelative(path) {
    return path.indexOf('/') === 0;
  }

  /* eslint-disable max-len */
  /**
   * Utility class to handle the import path scheme for V2 translations.
   * See src/vb/private/pathHandler.js
   * BundleV2Definition has simpler requirements for a valid path, but which are hard to represent in the existing
   * PathHandler options.
   *
   * Paths cannot start with a '.'
   * If a path starts with a '/', the location of the resource is relative to the root of the extension or app
   * If a path doesn't start with a '/', the location of the resource is relative to '/translations/self/' at the root
   * of the extension, or '/resources/translations/' at the root of the app.
   * Paths can be absolute, which means they have a hostname.
   * Reference:
   * https://confluence.oraclecorp.com/confluence/pages/viewpage.action?pageId=1477883242&focusedCommentId=2976505802#JetV2TranslationsforBaseApps&ExtensionsforFA-Definition.1
   * https://confluence.oraclecorp.com/confluence/display/ABCS/Referring+to+components+or+modules+in+an+extension+or+an+App+UI
   */
  /* eslint-enable max-len */
  class V2PathHandler {
    /**
     *
     * @param translationsPath
     * @param bundlePath
     * @param baseUrl
     */
    constructor(translationsPath, bundlePath, baseUrl = '') {
      this.translationsPath = translationsPath;
      this.bundlePath = bundlePath;
      this.baseUrl = baseUrl;
      this.normalizedBundlePath = undefined;
    }

    /**
     * Allow neither "./somepath" nor "../somepath".
     * @returns {boolean}
     */
    isAllowed() {
      return !PathHandler.startsWithDot(this.bundlePath);
    }

    /**
     * Normalizes the path.
     * If path begins with '/', it is relative to the extension root or app root. It is expected that these paths start
     * with this.translationsPath.  The initial '/' is removed.
     * If path doesn't begin with '/', it is relative to this.translationsPath, which is prepended to the path.
     *
     * @returns {string}
     */
    getNormalizedBundlePath() {
      if (this.normalizedBundlePath === undefined) {
        if (isRootRelative(this.bundlePath)) {
          this.normalizedBundlePath = this.bundlePath.substring(1);
        } else {
          this.normalizedBundlePath = `${this.translationsPath}${this.bundlePath}`;
        }
      }
      return this.normalizedBundlePath;
    }

    /**
     * Resolve the path.
     * See src/vb/private/pathHandler.js
     * BundleV2Definition has simpler requirements for a valid path, but which are hard to represent in the existing
     * PathHandler options.
     * Path cannot begin with a '.'
     * If path begins with '/', it is relative to the extension root or app root. It is expected that these paths start
     * with '/translations/self/' (or '/resources/translations/' for an app)
     * If path doesn't begin with '/', it is relative to '/translations/self' in its extension or
     * '/resources/translations/' in the app.
     * The container path will be appended if there is one, and the path isn't absolute.
     *
     * @returns {string}
     */
    getResolvedPath() {
      if (!this.resolvedPath) {
        let path;
        // Absolute URL, use it
        if (Utils.isAbsoluteUrl(this.bundlePath)) {
          path = this.bundlePath;
          this.normalizedBundlePath = null; // (not undefined)
        } else {
          // Find the path relative to the extension or app root
          const normalizedBundlePath = this.getNormalizedBundlePath();
          if (this.baseUrl) {
            path = `${this.baseUrl}/${normalizedBundlePath}`;
          } else {
            path = normalizedBundlePath;
          }
        }

        // Normalize the path and use it
        this.resolvedPath = URI(path).normalizePath().toString();
      }
      return this.resolvedPath;
    }
  }

  /**
   * BundleV2Definition
   *
   * differences between this, and the original BundleDefinition
   *
   * - relative-to-container paths (starting with "./") are not supported; all paths are relative to the application,
   *   its extension, or absolute
   *
   * - path templates are not supported. this was never really used, but officially dropping support in new bundles.
   *
   * @todo: as of 09/29/2020, JET is not generating this format yet: JET-39285
   * simple example:
   *   define(() => ({
   *     "greeting": function (p){return "Hello, "+p["person"];},
   *     "heading": function (p){return "This is "+p["0"];},
   *   });
   *
   */
  class BundleV2Definition {
    /**
     * represents one translation bundle
     * @param {Object} application
     * @param {Object} options BundlesModel's options
     * @param [options.initParams] {Object}
     * @param [options.proxyBundles] {boolean} Make proxies for bundles and don't wait for the bundles to be loaded
     * @param {string} extensionId id of the extension that defines this BundleV2Definition
     * @param {string} name bundle name
     * @param {Promise<ExtensionTranslationsConfig>} extBundleDeclarations
     */
    constructor(application, options, extensionId, name, extBundleDeclarations) {
      this.application = application;
      this.options = options;
      this.extensionId = extensionId;
      this.name = name;
      this.extBundleDeclarations = extBundleDeclarations;

      // Avoid ts(2339) error
      this.map = {}; // these will come directly from the JET-generated string bundle and extensions
      this.loadPromise = null;

      this.proxyBundles = !!options.proxyBundles;
      if (this.proxyBundles) {
        logger.info('proxying V2 bundle', this.name);
        this._bundleProxy = ko.observable(createBundleV2Proxy(name));
        Object.defineProperty(this, 'map', {
          get: () => this._bundleProxy && this._bundleProxy().value,
          enumerable: true,
          configurable: true,
        });
      }
    }

    static get extensionClass() {
      return BundleV2Extension;
    }

    static getPathHandler(extension, path) {
      // allow neither "./somepath" nor "../somepath".
      if (!extension || (extension.id === Constants.ExtensionFolders.BASE)) {
        return new V2PathHandler('resources/translations/', path);
      }

      return new V2PathHandler('translations/self/', path, extension.baseUrl);
    }

    /**
     * Add a property to the Bundle String Map for this bundle's string map.
     * This defines the property such that it will resolve to the proxy observable, or the actual
     * map when it is loaded.
     */
    addStringMapToBundleMap(bundleMap) {
      Object.defineProperty(bundleMap, this.name, {
        get: () => this.map,
        enumerable: true,
        configurable: true,
      });
    }

    /**
     * Load the V2 Translations Bundle and its extensions.
     * This function can be wrapped in unittests to, for example, set a timer to mimic slow loads.
     * @see tests/testUtils.js delayTranslationBundleLoad
     * @param {Object} runtimeEnvironment
     * @return {Promise<this>} when bundle has been loaded
     */
    _load(runtimeEnvironment) {
      return this.extBundleDeclarations
        .then(({ extension, translations }) => {
          this.declaration = translations[this.name];
          this.path = BundleUtils.evaluateTranslationPath(this.declaration, this.options.initParams);
          if (!this.path) {
            throw new Error(`No path declared for translation bundle ${this.name}`);
          }
          if (!BundleUtils.isV2(this.path)) {
            throw new Error(`Not a v2 translation bundle ${this.name}`);
          }
          this.extensionId = extension ? extension.id : Constants.ExtensionFolders.BASE;
          this.pathHandler = BundleV2Definition.getPathHandler(extension, this.path);

          // Whether or not the path is allowed
          // allow neither "./somepath" nor "../somepath".
          if (!this.pathHandler.isAllowed()) {
            throw new Error(`Path not alliowed for v2 translation bundle ${this.name}`);
          }

          // Resolve the path
          const resolvedPath = this.pathHandler.getResolvedPath();
          const normalizedBundlePath = this.pathHandler.getNormalizedBundlePath();

          const v2Strings = runtimeEnvironment.getV2Strings(resolvedPath, this.declaration);
          let translationExtensions = null;

          // No normalized path (Absolute).   Can't derive an extension from its path
          if (normalizedBundlePath) {
            // Derive path to find matching translation extensions (-x) in extension packages.
            // In base, normalizedBundlePath may be
            //   resources/translations/bundle-i18n
            // It should remain as-is, and ExtensionRegistry will append 'translations/base/', to get
            //   'translations/base/resources/translations/bundle-i18n'
            //
            // In an extension (e.g. 'extId'), normalizedBundlePath may be
            //   translations/self/bundle-i18n
            // It should be transformed to
            //   bundle-i18n
            // and ExtensionRegistry will append 'translations/<extId>/' based on this Bundle Definition, to get
            //  'translations/<extId>/bundle-i18n'
            let comparePath = normalizedBundlePath.replace(badStringUrlSegmentRegex, '/');
            if (this.extensionId !== Constants.ExtensionFolders.BASE) {
              comparePath = comparePath.replace(translationsSelfRegex, '$2');
            }
            translationExtensions = this.application.extensionRegistry.loadTranslationExtensions(comparePath, this);
          }

          let bundleFunctions = null;
          return Promise.allSettled([v2Strings, translationExtensions])
            .then(([v2StringsResult, translationExtensionsResult]) => {
              if (v2StringsResult.status === 'rejected') {
                // vs-code and typescript don't like the two argument constructor for Error!
                throw new Error(`Failed to load v2 bundle ${this.path} in ${this.extensionId}.`,
                  // @ts-ignore Expected 0-1 arguments, but got 2. ts(2554)
                  { cause: v2StringsResult.reason });
              }

              const v2Objects = v2StringsResult.value;
              // Copy the value of functions, but still use the actual reference in the 'map' property.
              // This is because DT will replace v2Objects.functions with a proxy to allow updates to string bundles
              // in preview without requiring reloading of the bundle, or the defining container.
              bundleFunctions = v2Objects.functions;

              // Redefine map, to point to the real string functions
              Object.defineProperty(this, 'map', {
                get: () => v2Objects.functions || {},
              });

              // If we loaded the bundle but not the extensions report that the extension load failed but return this
              if (translationExtensionsResult.status === 'rejected') {
                logger.error(`Failed to load v2 bundle extensions for ${this.path} in ${this.extensionId}`,
                  translationExtensionsResult.reason);
              } else {
                const extensions = translationExtensionsResult.value;
                // If we have extensions, copy their overridden string functions
                if (bundleFunctions && extensions && extensions.length) {
                  extensions.forEach((ext) => {
                    const extensionFunctions = ext.getStringFunctions();
                    Object.keys(extensionFunctions)
                      .forEach((key) => {
                        // eslint-disable-next-line no-param-reassign
                        bundleFunctions[key] = extensionFunctions[key];
                      });
                  });
                }
              }

              // VBS-26912 Make sure we update the bundleProxy after updating all the strings from extensions
              if (this.proxyBundles) {
                logger.info('un-proxying bundle', this.name);
                // Load the Bundle Proxy with the actual string functions and
                // Update the Bundle Proxy Observable with the updated result.
                this._bundleProxy(this._bundleProxy().load(bundleFunctions));
              }

              return this;
            });
        })
        .catch((error) => {
          logger.error(error);

          return null;
        })
        .finally(() => {
          delete this._bundleProxy;
        });
    }

    /**
     * Load the V2 Translations Bundle and its extensions.
     * @param {Object} runtimeEnvironment
     * @return {Promise<this>} when bundle has been loaded
     */
    load(runtimeEnvironment) {
      this.loadPromise = this.loadPromise || Promise.resolve()
        .then(() => {
          // Track the Bundle Load, because clients may not block on load().
          const bundleLoadResolver = BundleUtils.trackBundleLoad(this.name, this.loadPromise);

          return this._load(runtimeEnvironment)
            .finally(() => {
              bundleLoadResolver();
            });
        });

      return this.loadPromise;
    }

    /**
     * Find the string function for the given key in this bundle, and call it with any params.
     *
     * ex: "{{ $translations.format('mybundle', 'titlekey', {appname: 'text'}) }}"
     *
     * @param {string} key
     * @param {Object.<string, string>} params an object with named substitutions
     * @returns {string}
     */
    format(key, params) {
      const fn = this.map[key];
      if (!fn) {
        logger.error(`Translation bundle "${this.name}" does not define key "${key}"`);
        return key;
      }

      return fn(params);
    }

    /**
     * Create the V2 Bundle Definition, if one hasn't already been created
     * if extId === 'self', use extension.  Otherwise use extension from extBundleDeclaration.
     *
     * @param {Object} application
     * @param {Object} options BundlesModel's options
     * @param [options.initParams] {Object}
     * @param [options.proxyBundles] {boolean} Make proxies for bundles and don't wait for the bundles to be loaded
     * @param {string} extId id of the Extension that the bundle is defined in
     * @param {string} name name used for the bundle in mainExtension
     * @param {Promise<ExtensionTranslationsConfig>} extBundleDeclarations
     * @returns {BundleV2Definition}
     */
    static getBundleV2Definition(application, options, extId, name, extBundleDeclarations) {
      let extensionMap = BundleV2Definition.extensions[extId];
      if (!extensionMap) {
        extensionMap = {};
        BundleV2Definition.extensions[extId] = extensionMap;
      }

      let bundleDefinition = extensionMap[name];
      if (!bundleDefinition) {
        bundleDefinition = new BundleV2Definition(application, options, extId, name, extBundleDeclarations);
        extensionMap[name] = bundleDefinition;
      }
      return bundleDefinition;
    }

    /**
     * Test helper
     * Reset cached information
     */
    static reset() {
      BundleV2Definition.extensions = {};
    }
  }

  BundleV2Definition.extensions = {};

  return BundleV2Definition;
});

