'use strict';

/* eslint no-empty: 0 */

define('vb/private/rtEnvConfig',['vb/private/constants'], (Constants) => {
  class RtEnvConfig {
    constructor() {
      const config = RtEnvConfig.getCommunicationConfig(globalThis.vbInitConfig, globalThis.location.href);

      this.module = config.module;
      this.environmentClass = config.environmentClass;

      // Configure the require so that "vbRuntimeEnvironmentClass" maps to the environment class
      requirejs.config({
        map: {
          '*': {
            vbRuntimeEnvironmentClass: config.environmentClass,
          },
        },
      });
    }

    /**
     * This function retrieve the configuration for the communication channel between DT and RT.
     *
     * @returns {{environmentClass: String, module: String}} the config object for the communication channel
     * environmentClass the class that should be mapped to 'vbRuntimeEnvironmentClass' in the requireConfig
     * mapping. It allows DT to change the implementation of the runtimeEnvironment. The value can be undefined,
     * in which case the runtime dummy implementation is used. (See the buildRequireConfig function)
     * module is of the library provided by DT to communicate with RT when an application runs inside DT,
     * like preview or live mode. It can be undefined, in that case no library is loaded.
     */
    static getCommunicationConfig(vbInitConfig, url) {
      const initConfig = Object.assign({}, vbInitConfig);
      const config = {
        // By default uses the Runtime implementation
        environmentClass: initConfig.RUNTIME_ENV_CLASS || 'vb/private/helpers/runtimeEnvironment',
        module: '',
      };

      try {
        const { searchParams } = new URL(url);
        const vbComChannelUrl = searchParams.get('vbdt:editModeComChannelUrl');
        const vbRuntimeEnvClass = searchParams.get('vbdt:editModeComChannelClass');
        if (vbComChannelUrl && vbRuntimeEnvClass) {
          let editorUrl;

          // Give precedence to the value on the sessionStorage if it's a valid URL
          try {
            const { sessionStorage } = globalThis;
            editorUrl = new URL(sessionStorage && sessionStorage.getItem(Constants.SessionStorage.EDITOR_URL));
          } catch (err) {}

          if (!editorUrl) {
            try {
              editorUrl = new URL(initConfig.EDITOR_URL);
            } catch (err) {
              console.warn('Invalid Editor URL:', initConfig.EDITOR_URL);
              return config;
            }
          }

          // For the channel URL to be correct, it has to starts with the EDITOR_URL
          // even if the port is not the same.
          if (!vbComChannelUrl.startsWith(editorUrl.href)) {
            // Remove the potential port from the editor URL
            const noPortEditorUrl = `${editorUrl.protocol}//${editorUrl.hostname}${editorUrl.pathname}`;
            if (!vbComChannelUrl.startsWith(noPortEditorUrl)) {
              return config;
            }
          }

          // Export the URL of the communication module
          config.module = vbComChannelUrl;
          config.environmentClass = vbRuntimeEnvClass;
        }
      } catch (err) {
        // This is to catch when IE throws on new URL
        // DT does not run on IE either so we're fine.
      }

      return config;
    }
  }

  return RtEnvConfig;
});

