/* eslint-disable prefer-destructuring */

'use strict';

define('vbc/private/monitorOptions',[], () => {
  /**
   * All the spans used by VB
   * DO NOT change any of these names without previously consulting the Tracing team on slack channel #vb-tracing
   *
   * @type {Object}
   */
  const SPAN_NAMES = {
    LOAD_APPLICATION: 'ApplicationLoad',
    LOAD_FLOW: 'FlowLoad',
    LOAD_PAGE: 'pageLoad',
    LOAD_APPUI: 'AppPackageLoad',
    LOAD_PACKAGE_FLOW: 'PackageFlowLoad',
    LOAD_FRAGMENT: 'FragmentLoad',
    LOAD_PACKAGE_FRAGMENT: 'PackageFragmentLoad',
    LOAD_LAYOUT: 'LayoutLoad',
    LOAD_APPLICATION_EXTENSION: 'ApplicationExtensionLoad',
    LOAD_FLOW_EXTENSION: 'FlowExtensionLoad',
    LOAD_PAGE_EXTENSION: 'PageExtensionLoad',
    LOAD_FRAGMENT_EXTENSION: 'FragmentExtensionLoad',
    LOAD_LAYOUT_EXTENSION: 'LayoutExtensionLoad',
    LOAD_APPUI_EXTENSION: 'AppPackageExtensionLoad',
    LOAD_PACKAGEFLOW_EXTENSION: 'PackageFlowLoad',
    LOAD_EXTENSION_BUNDLE: 'extensionBundleLoading',
    LOAD_EXTENSION_MANIFEST: 'extensionManifestLoad',
    ACTIVATE_PAGE: 'pageActivate',
    ACTIVATE_FRAGMENT: 'FragmentActivate',
    ACTIVATE_PACKAGE_FRAGMENT: 'PackageFragmentActivate',
    ACTION: 'action',
    ACTION_CHAIN: 'actionChain',
    EVENT_COMPONENT: 'componentEvent',
    EVENT_RUNTIME: 'runtimeEvent',
    EVENT_VARIABLE: 'variableEvent',
    FETCH: 'fetch',
    SDP_FETCH_BY_OFFSET_ITERATION: 'sdp.fetchByOffsetIteration',
    SDP_FETCH_BY_OFFSET: 'sdp.fetchByOffset',
    SDP_FETCH_BY_KEYS_ITERATION: 'sdp.fetchByKeysIteration',
    SDP_FETCH_BY_SINGLE_KEY: 'sdp.fetchBySingleKey',
    SDP_FETCH_BY_KEYS: 'sdp.fetchByKeys',
    SDP_FETCH_FIRST: 'sdp.fetchFirst',
    CACHE_JET_MONITOR: 'cacheJet',

    SW_INSTALL: 'swInstall',
    SW_ACTIVE: 'swActivate',
    SW_PUSH: 'swPush',
    SW_NOTIFICATIONCLICK: 'swNotificationClick',
    SW_LOG: 'swLog',
  };
  Object.freeze(SPAN_NAMES);

  /**
   * An array of span name used to do a quick validation
   *
   * @type {Array<String>}
   */
  const SPAN_ARRAY = Object.values(SPAN_NAMES);
  Object.freeze(SPAN_ARRAY);

  const STARTING = 'starting';
  const ENDING = 'ending';

  class MonitorOptions {
    /**
     * @param {String} operationName the name of the operation to monitor
     * @param {String} message user readable monitor message
     */
    constructor(operationName, message) {
      this.operationName = operationName;
      this.message = message;
      // Check the span name is valid
      if (SPAN_ARRAY.includes(operationName)) {
        this.operationName = operationName;
        this.message = message;
      } else {
        throw new Error(`Span ${operationName} is not valid.`);
      }
    }

    /**
     * @type {Object}
     */
    static get SPAN_NAMES() {
      return SPAN_NAMES;
    }

    /**
     * @type {String}
     */
    static get pageLoadSpanName() {
      return 'pageLoad';
    }

    /**
     * @type {String}
     */
    static get pageActivateSpanName() {
      return 'pageActivate';
    }

    /**
     * @param message monitor message
     * @returns {MonitorOptions}
     */
    addMessage(message) {
      this.message = message;
      return this;
    }

    /**
     * @param message start monitor message
     * @returns {MonitorOptions} monitor options for chaining
     */
    addStartMessage(message) {
      this.startMessage = message;
      return this;
    }

    /**
     * @param message end monitor message
     * @returns {MonitorOptions} monitor options for chaining
     */
    addEndMessage(message) {
      this.endMessage = message;
      return this;
    }

    /**
     * @param tags a function that returns log monitor tags object
     * @returns {MonitorOptions} monitor options for chaining
     */
    addTags(tags) {
      this.tags = tags;
      return this;
    }

    /**
     * @param startFields a function that returns a fields object
     * @returns {MonitorOptions} monitor options for chaining
     */
    addStartFields(startFields) {
      this.startFields = startFields;
      return this;
    }

    /**
     * @param endFields a function that returns a fields object
     * @returns {MonitorOptions} monitor options for chaining
     */
    addEndFields(endFields) {
      this.endFields = endFields;
      return this;
    }

    /**
     * @returns {*|string} a start message for this monitor. If no start message was specified, start message is
     * constructed from the message.
     */
    getStartMessage() {
      let startMessage = this.startMessage;
      if (!startMessage && this.message) {
        startMessage = `${STARTING} ${this.message}`;
      }
      return startMessage;
    }

    /**
     * @returns {*|string} an end message for this monitor. If no end message was specified, end message is
     * constructed from the message.
     */
    getEndMessage() {
      let endMessage = this.endMessage;
      if (!endMessage && this.message) {
        endMessage = `${ENDING} ${this.message}`;
      }
      return endMessage;
    }

    /**
     * @returns {Object | undefined} log monitor tags object
     */
    getTags() {
      let tags;
      if (typeof this.tags === 'function') {
        tags = this.tags();
      }
      return tags;
    }

    /**
     * @returns {Object | undefined} log monitor fields object
     */
    getStartFields() {
      let fields;
      if (typeof this.startFields === 'function') {
        fields = this.startFields();
      }
      return fields || {};
    }

    /**
     * @returns {Object | undefined} log monitor fields object
     */
    getEndFields() {
      let fields;
      if (typeof this.endFields === 'function') {
        fields = this.endFields();
      }
      return fields || {};
    }
  }
  return MonitorOptions;
});

