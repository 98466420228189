'use strict';

define('vb/private/vx/pwaFetchHandler',[
  'vbsw/private/constants',
  'vbsw/private/fetchHandler',
], (
  SwConstants,
  FetchHandler,
) => {
  /**
   * A FetchHandler that is configured to use optCacheStrategies.getHttpCacheHeaderStrategy,
   * which was cloned from OPT's CacheStrategies.getHttpCacheHeaderStrategy, but without the
   * Cache-Control: no-cache, no-store
   * handling.
   */
  class PwaFetchHandler extends FetchHandler {
    /**
     * @param {string} registryUrl the url of the extension manager
     */
    constructor(registryUrl) {
      super('/', {
        isWebPwa: true,
        registryUrl,
        useCacheResponseWhenOfflineHeaderEnabled: true,
      });
    }

    /**
     * Install plugins and initialize Persistence Manager
     */
    initialize() {
      // Install Plugins
      return this.installPlugins([
        'vbsw/private/plugins/authPostprocessorHandlerPlugin',
      ])
        // Initialize Persistence Manager, so we can get the digest when offline
        .then(() => this.initializePersistenceManagerWithStore())
        // Fetch It
        .then((pm) => {
          this.pm = pm;
        });
    }

    /**
     * Fetch the digest.
     * @param {string} digestUrl the url of the digest request
     */
    fetchDigest(digestUrl) {
      this.digestUrl = digestUrl;

      const options = {
        headers: {
          [SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE]: true, // use offline toolkit to handle caching
        },
      };
      // @ts-ignore
      const request = new Request(digestUrl, options);
      return this.handleRequest(request);
    }

    /**
     * Override browserFetchHandleRequest() so we can make sure we only call our handleRequest for our
     * actual digestUrl.
     * This avoids the 'wrong' handleRequest being called from PersistenceManager.browserFetch.
     *
     * @param {Request} request
     * @returns Promise
     */
    browserFetchHandleRequest(request) {
      if (request.url === this.digestUrl) {
        return super.browserFetchHandleRequest(request)
          .finally(() => {
            // Restore the Persistence Manager's browserFetch
            this.restorePersistenceManager(this.pm);
          });
      }

      return this.browserFetch(request);
    }
  }

  return PwaFetchHandler;
});

