'use strict';

define('vb/private/helpers/fragmentHelper',[
  'vb/private/log',
  'vb/private/stateManagement/container',
  'vb/private/constants',
], (Log, Container, Constants) => {
  /** @type Object */
  const symbol = Symbol('fragment-helper');
  const logger = Log.getLogger('/vb/stateManagement/fragmentHelper', [
    // Register a custom logger
    {
      name: 'coralInfo',
      severity: 'info',
      style: 'coral',
    },
  ]);
  /**
   * Fragment Helper that provides methods to manage the state of a fragment, associated to a specific outer
   * container, that is identified by a stable and unique fragmentId.
   *
   */
  class FragmentHelper {
    /**
     * Outer container that holds the fragment(s)
     * @param {Object} context that is hidden using a closure. See modelUtils.js
     * @param {Container} context.container an instance of a container, like a page, fragment or layout, all of
     * which can hold one or more fragments.
     */
    constructor(context = { container: undefined }) {
      if (!context.container) {
        this.log.error('Unable to create a FragmentHelper instance because a valid container was not provided!');
        return;
      }
      this.log = logger;
      Object.defineProperty(this, symbol, {
        value: {
          container: context.container,
        },
      });
    }

    /**
     * marks the fragment state for deletion
     * @param {String} fragmentId  stable id of the fragment
     */
    deleteState(fragmentId) {
      if (!fragmentId) {
        this.log.warn('Unable to delete fragment state because a fragment id was not provided!');
      } else {
        const oc = this[symbol].container;
        oc.addFragmentForDispose && oc.addFragmentForDispose(fragmentId);
      }
    }
  }

  return FragmentHelper;
});

