'use strict';

define('vbsw/private/browserServiceWorkerWrapper',['vbsw/private/utils',
  'vbsw/private/serviceWorkerWrapper'], (Utils, ServiceWorkerWrapper) => {
  /**
   * Wrapper class for a browser service worker instance.
   */
  class BrowserServiceWorkerWrapper extends ServiceWorkerWrapper {
    constructor(scope, serviceWorker, config, registration) {
      super(scope, registration);
      this.serviceWorker = serviceWorker;
      if (config.isMobilePwa) {
        this.cacheOnRequest(config);
      }
    }

    /**
     * An opportunity for FetchHandler to perform cache operations on every request
     * @returns {Promise<void>} a Promise to perform resource caching operations
     */
    cacheOnRequest() {
      const msg = {
        method: 'cacheOnRequest',
        args: [],
      };
      return this.postMessage(msg).catch(() => {
        // ignore the error
      });
    }

    /**
     * Load and install the given plugins. The plugins should be an array of urls.
     *
     * @param plugins an array of urls for the plugins to install
     * @param reload if true, force reload all the plugins, otherwise, only reload plugins whose metadata has changed
     * @returns {Promise}
     */
    installPlugins(plugins, reload = true) {
      // post a message to the service worker to install the plugins
      const msg = {
        method: 'installPlugins',
        args: [plugins, reload],
      };

      return this.postMessage(msg).catch(() => {
        // ignore error
      });
    }

    /**
     * Uninstall currently installed plugins. This is used for testing purpose.
     * @returns {Promise}
     */
    uninstallPlugins() {
      // post a message to the service worker to install the plugins
      const msg = {
        method: 'uninstallPlugins',
        args: [],
      };

      return this.postMessage(msg).catch(() => {
        // ignore error
      });
    }

    /**
     ** @returns {Promise}
     * @override
     */
    activateUrlMapping() {
      const msg = {
        method: 'activateUrlMapping',
        args: [],
      };
      return this.postMessage(msg);
    }

    /**
     * Register the given listener with the service worker as a message event listener.
     *
     * @param listener the listener to register
     */
    addMessageEventListener(listener) {
      // listen for messages from the service worker
      navigator.serviceWorker.addEventListener('message', listener);

      // also install the listener on window
      super.addMessageEventListener(listener);
    }

    /**
     * Unregister the given message event listener from the service worker.
     *
     * @param listener the listener to unregister
     */
    removeMessageEventListener(listener) {
      navigator.serviceWorker.removeEventListener('message', listener);

      // also remove the listener from window
      super.removeMessageEventListener(listener);
    }

    /**
     * Uninstall offline handler.
     *
     * @returns {Promise}
     */
    uninstallOfflineHandler() {
      const msg = {
        method: 'uninstallOfflineHandler',
        args: [],
      };

      return this.postMessage(msg);
    }

    /**
     * Dynamically load the PersistenceUtils.
     *
     * @returns {*}
     */
    getPersistenceUtils() {
      if (!this.persistenceUtilsPromise) {
        this.persistenceUtilsPromise = Utils.getResource('persist/persistenceUtils');
      }
      return this.persistenceUtilsPromise;
    }

    /**
     * Serialize the given Request instance into a JSON object.
     *
     * @param request request to serialize
     * @returns {Object}
     */
    serializeRequest(request) {
      return this.getPersistenceUtils()
        .then((PersistenceUtils) => PersistenceUtils.requestToJSON(request));
    }

    /**
     * Deserialize the given request JSON object into a Request instance.
     *
     * @param requestJson request json to deserialize
     * @returns {Promise<Request>}
     */
    deserializeRequest(requestJson) {
      return this.getPersistenceUtils()
        .then((PersistenceUtils) => PersistenceUtils.requestFromJSON(requestJson));
    }

    /**
     * Deserialize the given response JSON object into a Response instance.
     *
     * @param responseJson response json to deserialize
     * @returns {*}
     */
    deserializeResponse(responseJson) {
      return this.getPersistenceUtils()
        .then((PersistenceUtils) => PersistenceUtils.responseFromJSON(responseJson));
    }

    /**
     * Deserialize the give result by deserializing all request/response JSON objects into
     * Request/Response instances.
     *
     * @param result result to deserialize
     * @returns {Promise}
     */
    deserializeSyncOperationResult(result) {
      return Promise.resolve().then(() => {
        if (result) {
          if (Array.isArray(result)) {
            const deserializedResult = [];
            const promises = result.map((entry) => this.deserializeSyncOperationResult(entry)
              .then((deserializedEntry) => {
                deserializedResult.push(deserializedEntry);
              }));
            return Promise.all(promises).then(() => deserializedResult);
          }

          if (Utils.isObject(result)) {
            const deserializedResult = Object.assign({}, result);
            const promises = [];

            if (result.request) {
              promises.push(this.deserializeRequest(result.request)
                .then((request) => {
                  deserializedResult.request = request;
                }));
            }

            if (result.response) {
              promises.push(this.deserializeResponse(result.response)
                .then((response) => {
                  deserializedResult.response = response;
                }));
            }

            return Promise.all(promises).then(() => deserializedResult);
          }
        }

        return result;
      });
    }

    /**
     * Get the FetchHandler to perform a sync of cached offline data.
     *
     * @param options any options to be passed to the persistence sync manager
     * @returns {Promise}
     */
    syncOfflineData(options) {
      const msg = {
        method: 'syncOfflineData',
        args: [options],
        options: {
          addClientToArgs: true, // make originating client available to the method
        },
      };

      return this.postMessage(msg)
        .catch((err) => this.deserializeSyncOperationResult(err)
          .then((deserializedErr) => {
            throw deserializedErr;
          }));
    }

    /**
     * Get the FetchHandler to return the offline sync log.
     *
     * @returns {Promise}
     */
    getOfflineSyncLog() {
      const msg = {
        method: 'getOfflineSyncLog',
      };

      return this.postMessage(msg)
        .then((syncLog) => this.deserializeSyncOperationResult(syncLog));
    }

    /**
     * Get the FetchHandler to insert a new request into the offline sync log.
     *
     * @param request request to insert
     * @param options
     * @returns {Promise}
     */
    insertOfflineSyncRequest(request, options) {
      return this.serializeRequest(request).then((requestJson) => {
        const msg = {
          method: 'insertOfflineSyncRequest',
          args: [requestJson, options],
        };

        return this.postMessage(msg)
          .then((result) => this.deserializeRequest(result));
      });
    }

    /**
     * Get the FetchHandler to delete a request from the offline sync log.
     *
     * @param requestId the id for the request to delete
     * @returns {Promise}
     */
    removeOfflineSyncRequest(requestId) {
      const msg = {
        method: 'removeOfflineSyncRequest',
        args: [requestId],
      };

      return this.postMessage(msg)
        .then((requestJson) => this.deserializeRequest(requestJson));
    }

    /**
     * Get the FetchHandler to update a request in the offline sync log.
     *
     * @param requestId the id for the request to update
     * @param request updated request
     * @returns {Promise}
     */
    updateOfflineSyncRequest(requestId, request) {
      return this.serializeRequest(request).then((requestJson) => {
        const msg = {
          method: 'updateOfflineSyncRequest',
          args: [requestId, requestJson],
        };

        return this.postMessage(msg)
          .then((result) => this.deserializeRequest(result));
      });
    }

    /**
     * Set options on the offline handler.
     *
     * @param options options to be set on the offline handler
     * @returns {Promise}
     */
    setOfflineHandlerOptions(options) {
      const msg = {
        method: 'setOfflineHandlerOptions',
        args: [options],
      };

      return this.postMessage(msg);
    }

    /**
     * If the flag is true, inform the service worker to force the PersistenceManager offline and vice versa.
     *
     * @param flag if true, force the PersistenceManager offline and vice versa
     * @returns {Promise}
     */
    forceOffline(flag) {
      const msg = {
        method: 'forceOffline',
        args: [flag],
      };

      return this.postMessage(msg);
    }

    /**
     * Ask the service worker for its online status.
     *
     * @returns {Promise.<Boolean>}
     */
    isOnline() {
      const msg = {
        method: 'isOnline',
        args: [],
      };

      return this.postMessage(msg);
    }

    /**
     * Return the service worker configuration.
     *
     * @returns {Promise}
     */
    getConfig() {
      const msg = {
        method: 'getConfig',
        args: [],
      };

      return this.postMessage(msg);
    }

    /**
     * Register the requests to be skipped by the service worker.
     *
     * @param requests an array of objects containing the url and method, e.g., [{ url: 'xxxx', method: 'GET' }]
     * @returns {Promise}
     */
    addRequestsToSkip(requests) {
      const msg = {
        method: 'addRequestsToSkip',
        args: [requests],
      };

      return this.postMessage(msg);
    }

    /**
     * Deregister the requests to be skipped by the service worker.
     *
     * @param requests an array of objects containing the url and method, e.g., [{ url: 'xxxx', method: 'GET' }]
     * @returns {Promise}
     */
    removeRequestsToSkip(requests) {
      const msg = {
        method: 'removeRequestsToSkip',
        args: [requests],
      };

      return this.postMessage(msg);
    }

    /**
     * Post the given message to the service worker for invoking a method on the fetchHandler. The message
     * has the following format:
     *
     * {
     *   method: 'methodName',  // name of the method to invoke
     *   args: [...],  // arguments to the method
     *   options: {
     *     addClientToArgs: true,  // make the calling client available to the callee for return communication
     *   }
     * }
     *
     * @param message the message to post
     * @returns {Promise}
     */
    postMessage(message) {
      // use navigator.serviceWorker.controller which is more reliable than the service worker instance
      // we get during registration
      // note; fallback to using this.serviceWorker, because controller could be null
      // during unit testing ('grunt test-release', using compiled sources, which uses real service workers).

      // TODO: discuss this change with Peter
      // const sw = navigator.serviceWorker.controller || this.serviceWorker;
      const sw = this.serviceWorker || navigator.serviceWorker.controller;
      return Utils.postMessage(sw, message);
    }
  }

  return BrowserServiceWorkerWrapper;
});

