'use strict';

define('vb/private/pwa/swTraceOptions',[
  'vbc/private/monitorOptions',
  'vb/private/utils',
], (
  MonitorOptions,
  Utils,
) => {
  /**
   * Represents a log monitor options for a Web PWA Service Worker Events.
   */
  class SwTraceOptions extends MonitorOptions {
    /**
     * Create a monitor options from the tracerSpan options, and copy all traceContext properties into a
     * property with the name of the operation
     */
    constructor(traceSpan, parentSpan) {
      super(`sw${traceSpan.name}`, `Service worker ${traceSpan.name} event`);

      // Need to flatten our trace tags
      this.addTags(() => Utils.flatten({
        vb: {
          sw: traceSpan.sw,
        },
      }));

      this.addStartFields(() => ({
        parentSpan,

        // Use the actual startTime, don't let tracer fill in current time
        startTime: traceSpan.startTime,

        // NOTE: This is necessary to ensure that the trace library nests log spans properly
        priority: 1,
      }));

      // Use the actual endTime, don't let tracer fill in current time
      this.addEndFields(() => ({
        endTime: traceSpan.endTime,
      }));

      // If an error was set by tracerMessage()
      this.error = traceSpan.error;
    }

    getError() {
      return this.error;
    }
  }

  return SwTraceOptions;
});

