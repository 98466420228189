'use strict';

define('vb/private/stateManagement/layoutMixin',[], () => {
  /**
   * Mixin that is used by layout container and extension to load translation bundles similarly
   */
  const LayoutMixin = (superclass) => class extends superclass {
    /**
     * Overrides the base impl, so we can add "allowSelfRelative: false"
     *
     * @param [options] {Object} Special directives for the bundle definitions
     * @param [options.replacementValues] {Object} map of key/value pairs for expressions in path
     * @param [options.allowSelfRelative] {boolean} (V1) let the container JSON use "./" to mean 'relative to the
     * same folder
     * @param [options.isUnrestrictedRelative] {boolean} (V1) you can reach outside of your current folder
     * (only app-flow)
     * @param [options.preferV1] {boolean} If V1 translations are present, ignore V2 translations.
     * @param [options.proxyBundles] {boolean} Make proxies for bundles and don't wait for the bundles to be loaded
     *
     * @returns {Promise<Object>} a promise that resolves with the bundles model
     */
    loadTranslationBundles(options) {
      // (V1) let the container JSON use "./" to mean 'relative to the same folder
      const disallowSelfRelative = { allowSelfRelative: false };
      return super.loadTranslationBundles(options ? Object.assign(options, disallowSelfRelative)
        : disallowSelfRelative);
    }

    /**
     * The name of the resource to be used to load layout or extension layout.
     * @type {string}
     */
    get fullName() {
      return this.name;
    }
  };

  return LayoutMixin;
});

