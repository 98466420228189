'use strict';

define('vb/private/stateManagement/routerUtils',[], () => {
  /**
   * Traverse the router hierarchy to retrieve the current page.
   * The content of the application is made of nested flows and the leaf flow is
   * displaying a page. This routine traverse the hierarchy of flows using the
   * router hierarchy and return the page on the leaf flow.
   *
   * When the full argument is thruthy, the function retrieve the current page of
   * the switcher.
   *
   * @param  {Router} rt router
   * @param  {Boolean} full when true, traverse the full container hierarchy to the switcher current page
   * @return {Page}   the current page
   */
  function getPageInternal(rt, full) {
    if (!rt) {
      return null;
    }

    const currentState = rt.currentState();
    if (!currentState) {
      return null;
    }

    const id = currentState.id;
    // The container is set in the router configure callback
    const container = currentState.value;
    if (!container || !container.router) {
      return null;
    }
    const childRouter = container.router.getChildRouter(encodeURIComponent(id));
    let page = getPageInternal(childRouter, full);
    if (!page) {
      page = container.getContainer(id);
      if (full && page && page.switcherBridge) {
        // Drill into the switcher element
        const switcherElement = page.switcherBridge.getCurrentSwitcherElement();
        return switcherElement && switcherElement.getCurrentPage();
      }
    }

    return page;
  }

  /**
   * This class describes router utilities.
   *
   */
  class RouterUtils {
    /**
     * Traverse the router hierarchy to retrieve the current page.
     * The content of the application is made of nested flows and the leaf flow is
     * displaying a page. This routine traverse the hierarchy of flows using the
     * router hierarchy and return the page on the leaf flow.
     *
     * When the full argument is thruthy, the function retrieve the current page of
     * the switcher.
     *
     * @param  {Router} rt router
     * @param  {Boolean} full when true, traverse the full container hierarchy to the switcher current page
     * @return {Page}   the current page
     */
    static getCurrentPage(router, full) {
      return getPageInternal(router, full);
    }

    /**
     * Build the query parameters representing the current page when using the
     * query router strategy.
     *
     * @param {VbRouter} rt the base router
     * @param {Array<Object>} params array of non default parameter
     * @param {Array<Object>} finalParams complete array of parameters for the URL
     * @return {Array<Object>} finalParams complete array of parameters for the URL
     */
    static buildSearch(rt, params = [], finalParams = []) {
      const currentState = rt.currentState();
      if (currentState) {
        const name = rt.name;
        const id = currentState.id;

        params.push({ name, id });

        // Default state is not shown on the URL
        if (id !== rt.defaultStateId) {
          finalParams = finalParams.concat(params);
          params = [];
        }

        // The container is set in the router configure callback
        const container = currentState.value;
        const childRouter = container.router.getChildRouter(id);
        if (childRouter) {
          return RouterUtils.buildSearch(childRouter, params, finalParams);
        }
      }

      return finalParams;
    }
  }

  return RouterUtils;
});

