'use strict';

define('vb/private/stateManagement/applicationClass',[
  'knockout', 'ojs/ojcore-base', 'ojs/ojcontext',
  'vb/private/stateManagement/router/vbRouter',
  'vb/versions',
  'vb/private/stateManagement/flow',
  'vb/private/constants', 'vb/private/stateManagement/redux/storeManager',
  'vb/private/stateManagement/redux/reduxRouter',
  'vb/private/stateManagement/page',
  'vb/private/stateManagement/router', 'vb/private/utils',
  'vb/private/log',
  'vb/private/stateManagement/context/applicationContext',
  'vb/private/stateManagement/applicationExtension',
  'vb/private/stateManagement/shellPageInfo',
  'vb/private/stateManagement/appPackage',
  'vb/private/services/services',
  'vb/private/translations/bundlesModel',
  'vb/private/configLoader',
  'vb/private/configuration',
  'vb/private/history',
  'vb/private/services/protocolRegistry',
  'vb/private/pwa/jetCache',
  'vbc/private/pwa/pwaUtils',
  'vbc/private/performance/performance',
  'vbsw/private/serviceWorkerManager',
  'vb/private/services/fallbackServices',
  'ojs/ojmodule-element', // Needed for oj-module in index.html
  'vb/components/oj-vb-content/loader', // Needed for oj-vb-content in index.html
], (ko, oj, ojContext, VbRouter, Versions, Flow, Constants, StoreManager, ReduxRouter, Page, Router, Utils,
  Log, ApplicationContext, ApplicationExtension, ShellPageInfo,
  AppPackage, Services, BundlesModel, ConfigLoader, Configuration,
  History, ProtocolRegistry, JetCache, PwaUtils, Performance,
  ServiceWorkerManager, FallbackServices) => {
  const logger = Log.getLogger('/vb/stateManagement/application', [
    // Register  custom logger
    {
      name: 'custom',
      severity: 'info',
      style: 'calm',
    },
  ]);

  /**
   * @const {!string}  The path to the ApplicationUtils module.
   * Used to load the module dynamically in loadFunctionModule()
   */
  const APP_UTILS_PATH = 'vb/private/stateManagement/applicationUtils';

  class Application extends Flow {
    constructor() {
      super('app', null, null, 'Application');

      this.log = logger;

      // Store the full path of the previous page. Used for the navigate event.
      this.previousPagePath = null;

      this.previousPageParams = null; // Assigned in Container.navigateToSamePage() and Page.run()

      // A flag used to know if the app has started. This is use to change the
      // sync to a go when refreshing with a bookmark for a page.
      this.started = false;

      this.builtinUtils = null; // namespace for all public util packages used via expressions

      // Initialize performance measuring based on PERFORMANCE_CONFIG
      Performance.init(globalThis.vb, globalThis.vbInitConfig && globalThis.vbInitConfig.PERFORMANCE_CONFIG);

      if (PwaUtils.shouldAddBeforeInstallPromptListener(globalThis.vbInitConfig)) {
        globalThis.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt.bind(this), false);
      }
      if (PwaUtils.isMobilePwaConfig(globalThis.vbInitConfig)) {
        // PWA: dynamic JET caching based on require calls is only supported for mobile app PWA's for now
        JetCache.cacheJetWhenPageLoads();
      }

      // Use an observable to hold the ojModule configuration because it changes on each
      // navigation and ojModule depends on the mutation of the configuration to update its content.
      // By default the content is a blank ojModule. This will be modified by the nested page
      // when it is created.
      this.moduleConfig = ko.observable(Constants.blankModuleConfig);

      this.runtimeEnvironment = null; // Assigned in loadRuntimeEnvironment()
      this.reduxRouter = null; // Assigned in initReduxRouter()
      this.securityProvider = null; // Assigned in initApplicationUser()
      this.appUiInfos = null; // Assigned in initAppUis()
      this.isHostApplication = null; // Assigned in initAppUis()
      this.swMessageHandler = null; // Assigned in installFetchPluginMessageHandler

      this.beforeInstallPromptEvent = null; // Assigned in onBeforeInstallPrompt()
      this.newContentAvailableEvent = null; // Assigned in onNewContentAvailable()

      this.loadAppFunctionsPromise = null;
      this._loadApplicationPromise = null; // Assigned in load()
      this._messageHandlerPromise = null; // Assigned in load()

      // Both of these are assigned during navigation and get reset after the navigation is either
      // cancelled or completed
      this.destinationPath = null; // the destination path of navigation
      this.beforeExitDirtyDataStatus = null; // the dirty data status during navigation
    }

    /**
     * The type of object to instantiate to extend this object.
     * @type {ApplicationExtension}
     */
    static get extensionClass() {
      return ApplicationExtension;
    }

    /**
     * The type of object to instantiate to create an App UI
     *
     * @type {AppPackage}
     */
    static get AppUiClass() {
      return AppPackage;
    }

    /**
     * A listener for
     * {@link https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent|BeforeInstallPromptEvent}
     * that delivers vbBeforeAppInstallPrompt to the current page and application. If there is no current page,
     * event will be delivered after the next vbEnter for (any) page.
     * @param e BeforeInstallPromptEvent
     * @see {@link https://developers.google.com/web/fundamentals/app-install-banners/}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent}
     * @see {@link Page#invokeAppInstallPromptEvent}
     */
    onBeforeInstallPrompt(e) {
      this.log.info('PWA: BeforeInstallPromptEvent delivered at', e.timeStamp);
      Performance.timestamp(this.id, e.type);
      // Starting in Chrome 76 (July 2019), the mini-infobar can be disabled by calling preventDefault()
      // on the beforeinstallprompt event:
      // https://developers.google.com/web/updates/2019/05/mini-infobar-update
      e.preventDefault();
      // If page is loaded, deliver event right away (with bubbling).
      // Otherwise, deliver it after the next vbEnter for (any) page. Since the event is invoked with bubbling,
      // we cannot guarantee that the actual event handler in the page will be called after vbEnter.
      // This is because event bubbling will deliver vbBeforeAppInstallPrompt to page's parent's
      // before vbEnter for the parent was invoked.
      const page = Router.getCurrentPage();
      if (page && page.className === 'Page') {
        page.invokeAppInstallPromptEvent(e);
      } else {
        this.beforeInstallPromptEvent = e;
      }
    }

    onNewContentAvailable(newContentAvailableEvent) {
      this.log.info(`PWA: new content available: ${newContentAvailableEvent.message}`);
      Performance.timestamp(this.id, `newContentAvailable: ${newContentAvailableEvent.message}`);
      // If page is loaded, deliver event right away (with bubbling).
      // Otherwise, deliver it after the next vbEnter for (any) page.
      const page = Router.getCurrentPage();
      if (page && page.className === 'Page') {
        page.invokeNewContentAvailable(newContentAvailableEvent);
      } else {
        this.newContentAvailableEvent = newContentAvailableEvent;
      }
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getApplicationDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getApplicationFunctions';
    }

    initDefault(definition) {
      const def = definition;
      // Default strategy is 'query'
      def.routerStrategy = def.routerStrategy || Constants.RouterStrategy.QUERY;

      def.settings = def.settings || {};
      super.initDefault(def);
    }

    /**
     * Return true if the router strategy is "query"
     * @return {Boolean}
     */
    isQueryStrategy() {
      return this.definition.routerStrategy === Constants.RouterStrategy.QUERY;
    }

    /**
     * Always return false, only a host application can have App UIs
     * @param  {String} appUiId the id of the App UI
     * @return {Boolean}
     */
    doesAppUiExist(appUiId) {
      return this.appUiInfos.exist(appUiId);
    }

    /**
     * For the application, the router is the root instance.
     * @return {VbRouter} the router for this flow
     */
    createRouter() {
      let router;

      if (this.isQueryStrategy() || Configuration.appName) {
        router = VbRouter.rootInstance;
      } else {
        // When using the path strategy and the appName is not defined, we need to introduce a marker in the URL so
        // create a child router with this one state of this value
        VbRouter.rootInstance.configure({
          [Configuration.urlMarker]: {
            isDefault: true,
          },
        });
        router = VbRouter.rootInstance.createChildRouter(Configuration.urlMarker, Configuration.urlMarker);
      }

      // Initialize the router baseUrl and the router URL adapter
      Router.init(router, this.definition.routerStrategy);

      return router;
    }

    /**
     * Load and construct the runtime environment class.
     *
     * @return {RuntimeEnvironment} the runtime environment
     */
    loadRuntimeEnvironment() {
      // allow runtimeEnvironment to be mocked for unit tests
      if (this.runtimeEnvironment) {
        return Promise.resolve(this.runtimeEnvironment);
      }

      return Utils.getRuntimeEnvironment().then((rtEnvironment) => {
        this.runtimeEnvironment = rtEnvironment;
        return this.runtimeEnvironment;
      });
    }

    /**
     * This method initializes the store manager with the router reducer.
     */
    initReduxRouter() {
      this.reduxRouter = new ReduxRouter(Router);
      const store = StoreManager.init({ [ReduxRouter.KEY]: this.reduxRouter.reducer });
      this.reduxRouter.syncRouterWithStore(store);
    }

    /**
     * Retrieve the root page the host application should use. By default the root page
     * is defined in the defaultPage property in the descriptor but App UI have the ability
     * to change the root page using the hostRootPage property. That property is available
     * in the AppUiInfo structure given by the extension manager digest
     *
     * @param {String} appUiId the id of the App UI
     * @return {String} the id of the root page
     * @private
     */
    getHostRootPageId(appUiId) {
      let hostRootPage;

      // Either appUiId is defined and the hostRootPage could be defined by the App UI or
      // appUiId is not defined (v1 case) and the host default page is in the definition
      if (appUiId) {
        const info = this.appUiInfos.getInfo(appUiId);
        hostRootPage = info && info.hostRootPage;
      }

      return hostRootPage || this.definition.defaultPage.id;
    }

    /**
     * Applications are different than super class (flow) because
     * if there are App UI, we skip the shell page in the URL
     *
     * @return {Promise}
     */
    processDefaultPage() {
      if (this.isHostApplication) {
        let appUiId;
        // Get the App UI urlId from the URL. It is the segment with the name of the App UI:
        // https://host/redwood/foo/someflow
        // which is the segment immediately following the appName "redwood"
        // eslint-disable-next-line no-param-reassign
        const appUiUrlId = Router.getUrlSegmentAfterAppName();
        if (appUiUrlId) {
          appUiId = this.appUiInfos.getAppUiIdFromUrlId(appUiUrlId);
        }

        this.defaultPage = new ShellPageInfo(this, appUiId);
      }

      return super.processDefaultPage();
    }

    /**
     * Modify the default page to use the correct host root page
     * This is used when navigating to a different App UI
     *
     * @param {String} appUiId
     * @return {Promise} a promise that resolve when the work is done
     */
    prepareHostRootPage(appUiId) {
      return Promise.resolve().then(() => {
        let promise;
        if (this.isHostApplication) {
          this.defaultPage = new ShellPageInfo(this, appUiId);
          return super.processDefaultPage();
        }

        return promise;
      });
    }

    /**
     * Override container routerStateEnterCallback in order to convert
     * App UI urlId to appUiId
     *
     * @param {String}  stateId  The state identifier
     * @return {Promise}
     */
    routerStateEnterCallback(stateId) {
      return Promise.resolve().then(() => {
        if (this.isHostApplication) {
          const appUiId = this.appUiInfos.getAppUiIdFromUrlId(stateId);
          if (appUiId) {
            // eslint-disable-next-line no-param-reassign
            stateId = appUiId;
          }
        }

        return super.routerStateEnterCallback(stateId);
      });
    }

    /**
     * Override the Container behavior to deal with the host root page changing during navigation
     *
     * @param   {String}  containerId  The container identifier
     * @param   {String}  origin
     * @param   {Object}  details Additional info when origin is 'popState'
     * @return  {Promise}
     */
    routerStateCanExitCallback(containerId, origin, details) {
      return Promise.resolve().then(() => {
        if (this.isHostApplication) {
          const appUiId = this.appUiInfos.getAppUiIdFromUrlId(containerId);

          // By the time the canExit callback is invoked by the router, the defaultPage of the host application
          // has potentially already been changed to a different shell page.
          // In order to retrieve the reference to the old shell page we need to recreate
          // an instance of it temporarily.
          const defaultPage = new ShellPageInfo(this, appUiId);
          if (appUiId) {
            // eslint-disable-next-line no-param-reassign
            containerId = appUiId;
          }

          // The canExit callback is suppose to be called on each container of the hierarchy, so calls
          // the inner container first then call canExit on the shell page
          const shellPage = this.pages[defaultPage.id];
          return shellPage.getContainer(containerId).canExit(origin, details)
            .then((result) => (result ? shellPage.canExit(origin, details) : result));
        }

        return super.routerStateCanExitCallback(containerId, origin, details);
      });
    }

    /**
     * Override the Container behavior to deal with the host root page changing during navigation
     *
     * @param   {String}  containerId  The container identifier
     * @return  {Promise}
     */
    routerStateExitCallback(containerId) {
      return Promise.resolve().then(() => {
        let cont;
        if (this.isHostApplication) {
          const appUiId = this.appUiInfos.getAppUiIdFromUrlId(containerId);

          // By the time the exit callback is invoked by the router, the defaultPage of the host application
          // has potentially already been changed to a different shell page.
          // In order to retrieve the reference to the old shell page we need to recreate
          // an instance of it temporarily.
          const defaultPage = new ShellPageInfo(this, appUiId);
          if (appUiId) {
            // eslint-disable-next-line no-param-reassign
            containerId = appUiId;
          }
          cont = this.pages[defaultPage.id].getContainer(containerId);
          return cont.exit();
        }

        return super.routerStateExitCallback(containerId);
      });
    }

    /**
     * Implementation of loadContainer for host application, adjust the defaultPage
     * with the root page for this App UI.
     *
     * @param  {String} flowId the id of flow (can be an App UI flow)
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise} a promise that resolve to a AppPackage instance
     */
    loadContainer(flowId, navContext) {
      if (navContext && navContext.isCancelled()) {
        return Promise.resolve();
      }

      return Promise.resolve().then(() => {
        if (this.isHostApplication) {
          // When loadContainer is called from canEnter the id could be the URL id
          const appUiId = this.appUiInfos.getAppUiIdFromUrlId(flowId);
          if (appUiId) {
            // eslint-disable-next-line no-param-reassign
            flowId = appUiId;
          } else if (this.router.defaultStateId !== flowId) {
            // If the App UI does not exist and the flowId is not the default
            // flow of the application then log an error and go to the app root
            this.log.error('App UI', flowId, 'does not exist in host application', Configuration.appName);
            let url = window.location.href;
            url = `${url.substring(0, url.indexOf(Configuration.appName) + Configuration.appName.length)}/`;
            Utils.changeBrowserState(null, url, 'replaceState').then(() => {
              History.resetUri();
              Router.sync();
            });
            return null;
          }

          this.defaultPage = new ShellPageInfo(this, appUiId);
        }

        return super.loadContainer(flowId, navContext);
      });
    }

    /**
     * Retrieve the cached instance of the nested container.
     * @param  {String} id the id of the page to retrieve
     * @return {Object} the page instance
     */
    getContainer(id) {
      let containerId = id;
      if (this.isHostApplication) {
        // When getContainer is called, the id is the one used in the URL segment
        // Since App UI can have a URL id different than their id, we convert it.
        // Note that getAppUiIdFromUrlId return the App UI id when the URL id is not defined.
        containerId = this.appUiInfos.getAppUiIdFromUrlId(id);
      }
      return super.getContainer(containerId);
    }

    /**
     * Override the createFlow in the flow class in order to create App UI when
     * needed.
     * @param  {String} id the id of the flow or App UI to create
     * @param  {Page} page the parent page creating the flow
     * @return {Flow} the flow/App UI instance
     */
    createFlow(id, page) {
      // Precedence is given to App UI
      // Either this is a URL id and it maps to an App UI id or
      // we use the App UI id.
      if (this.doesAppUiExist(id)) {
        const extension = this.appUiInfos.getExtension(id);
        return new (this.constructor.AppUiClass)(extension, extension.getAppUiInfo(id), page);
      }

      return super.createFlow(id, page);
    }

    load() {
      if (!this._messageHandlerPromise) {
        // subscribe to plugin vbResourceChanged events
        // need to do this here, after ServiceWorkerManager.getInstance().installServiceWorker is called by bootstrap
        this._messageHandlerPromise = this.installFetchPluginMessageHandler();
      }

      if (!this._loadApplicationPromise) {
        this.logVersion();

        // Inject application instance in the router as a read-only property.
        Object.defineProperty(Router, 'application', {
          value: this,
          enumerable: true,
          configurable: true,
        });

        // Inject application instance in the BundlesModel class as a read-only property, so that all bundle
        // instances have the application instance available.
        // BundlesModel/BundleV2Definition need reference to application instance, but BundlesModel is instantiated
        // elsewhere as well.
        Object.defineProperty(BundlesModel, 'application', {
          value: this,
          enumerable: true,
          configurable: true,
        });

        // Initialized the the store manager with the router reducer
        this.initReduxRouter();

        // Subscribe to changes of router state
        this.onNavigate(this.navigated, this);

        // load the runtime environment and then call super
        this._loadApplicationPromise = this.loadRuntimeEnvironment()
          .then(() => this.preloadModules())
          .then(() => this.initAppUis())
          .then(() => super.load())
          .then(() => this.enter())
          .then(() => this.processDefaultPage())
          .then(() => {
            // When the application exclude the default page from URL, replace 'page' request parameter with the page id
            // so that the URL look something like ?shell=flowId instead of ?page=flowId
            // This need to be done before the first Router.sync
            if (this.defaultPage.excludeFromUrl && this.isQueryStrategy() && this.defaultPage.id) {
              // eslint-disable-next-line no-param-reassign
              VbRouter.defaults.rootInstanceName = this.defaultPage.id;
            }

            // Initialize the module with a viewModel that syncs the router as soon as the
            // view is connected. The ko.applyBindings in bootstrap.js will start this process.
            this.moduleConfig({
              view: [],
              viewModel: {
                connected: () => {
                  // connected is only called once when the application is refreshed.
                  // Calling applicationBootstrapComplete to indicate this is the point where we consider
                  // most of the application libraries are loaded. This information is needed for JET testing
                  // framework using whenReady. It does not impact the runtime, it only tells the JET webdriver
                  // API, the application is ready or not. By not making this call, the test might try to execute
                  // before the page is ready.
                  // See https://docs.oracle.com/en/middleware/developer-tools/jet/9/reference-api/oj.BusyContext.html
                  ojContext.getPageContext().getBusyContext().applicationBootstrapComplete();
                  Router.sync();
                },
              },
            });
          })
          .then(() => this.preloadChains());
      }
      return Promise.all([this._loadApplicationPromise, this._messageHandlerPromise])
        .catch((error) => {
          // In case of error, clear up the bootstrap flag so JET testing framework can continue
          ojContext.getPageContext().getBusyContext().applicationBootstrapComplete();
          throw error;
        });
    }

    getLeafPageInstance(pagePath, navContext) {
      if (pagePath === '') {
        return this.loadDefaultContainers(navContext);
      }

      return super.getLeafPageInstance(pagePath, navContext);
    }

    /**
     * Retrieve the leaf page instance when navigating between 2 App UIs.
     * It uses the App UI id and the navigation action parameters stored in navContext.options. and return
     * a promise that resolves with the page instance nested the deepest.
     * The logic for App UI is different from other flow because the App UI can have a defaultFlow
     * instead of the defaultPage.
     *
     * Called by navigateOperation
     *
     * @param  {String}  appUiId the id of the App UI to navigate to
     * @param  {Object}   navContext including options with navigate action parameters
     * @return {Promise}  a Promise resolving to the leaf page instance
     */
    getLeafPageInstanceFromAppUiId(appUiId, navContext) {
      return this.prepareHostRootPage(navContext.options.application)
        // Load the App Ui using the urlId
        .then(() => this.loadContainer(this.appUiInfos.getUrlIdFromAppId(appUiId), navContext))
        .then((appUi) => appUi.loadDefaultPageOrFlow(navContext));
    }

    /**
     * Override the container loadExtensions.
     * Even when the application does not have an extension, what's in the interface
     * section need to be expose to all flow/page extensions in the application
     * @return {Promise} a promise that resolve when extension is created
     */
    loadExtensions() {
      // If the application extension does not exist, creates one so that public variables are
      // accessible from extensions and App UIs
      return super.loadExtensions()
        .then(() => this.extensionRegistry.getExtensions())
        .then((extensions) => {
          const basePath = this.extensionRegistry.getBasePath('', this.application);
          const exts = [];

          extensions.forEach((extension) => {
            const extensionId = extension.id;

            // Only create the extension if it does exist yet
            if (!this.extensions[extensionId]) {
              exts.push(this.application.createExtension(extension, basePath));
            }
          });

          this.storeExtensions(exts);
        });
    }

    /**
     * Return true if the resource is defined in the extension
     *
     * @param  {String} extensionId
     * @param  {String} path
     * @return {Boolean}
     */
    fileExistsInExtension(extensionId, path) {
      const ext = this.extensions[extensionId];
      return ext.extension.fileExists(path);
    }

    /**
     * Preload modules specified by runtimeEnvironment.getModulesToPreload.
     *
     * @returns {Promise}
     */
    preloadModules() {
      return this.runtimeEnvironment.getModulesToPreload()
        .then((modulePaths) => {
          let result;

          if (Array.isArray(modulePaths) && modulePaths.length > 0) {
            result = Utils.getResources(modulePaths);
          }

          return result;
        });
    }

    /**
     * Override the loadImports in container.js
     * @return {Promise}
     */
    loadImports() {
      const { stopValidationPreLoading } = this.definition.settings;

      if (stopValidationPreLoading !== true) {
        // We don't need the code in JetCache anymore because the preload is now done
        // later in application loading
        //
        // Comments from code removed from JetCache.js that may be useful for history:
        //
        // https://jira.oraclecorp.com/jira/browse/BUFP-31429
        // In JET7, oj-message removed its dependency on oj-validation. The result is that VB pages that were using
        // oj-message didn't have to explicitly load oj-validation module, and now they do, so ojs/ojvalidation-datetime
        // needs to include in the cache because it might not show up as an explicit dependency in existing apps
        // https://jira.oraclecorp.com/jira/browse/BUFP-35521
        // JET8 incompatible changes. Short term (19.4.3) we're going to import the "side-effects" they removed
        //
        const moduleToPreload = [
          // Needed for oj-message since JET7 doesn't depend on this module anymore.
          // See https://jira.oraclecorp.com/jira/browse/JET-27893
          'ojs/ojvalidation-datetime',
          // JET8 incompatible changes. Short term (19.4.3) we're going to import the "side-effects" they removed
          // See https://jira.oraclecorp.com/jira/browse/BUFP-35521
          'ojs/ojvalidation-base',
          'ojs/ojvalidation-number',
        ];

        return Promise.all([super.loadImports(), Utils.getResources(moduleToPreload)]);
      }

      return super.loadImports();
    }

    // eslint-disable-next-line class-methods-use-this
    isDefault() {
      return true;
    }

    /**
     * @return {Boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    isNavigable() {
      // Application ia always navigable
      return true;
    }

    /**
     * Override Container.checkAccess.
     * Before being able to check the access we need to make sure the userConfig is loaded.
     * @return {Promise}
     */
    checkAccess() {
      // Load the security provider and user info before trying to check the access
      return this.initApplicationUser()
        .then(() => super.checkAccess())
        .catch((error) => {
          this.log.error('Error while loading user information', error);
          throw error;
        });
    }

    /**
     * Initialize the application user using the userConfig from the application descriptor.
     *
     * @returns {Promise}
     */
    initApplicationUser() {
      return Promise.resolve().then(() => {
        const userConfig = ConfigLoader.userConfig;
        if (!userConfig) {
          return undefined;
        }

        if (!this.securityProvider) {
          // the url expression (if any) has already been evaluated
          const config = userConfig.configuration;
          if (!config) {
            throw new Error('Missing configuration in userConfig');
          }

          return ConfigLoader.loadSecurityProvider().then((securityProvider) => {
            // instantiate the security provider
            this.securityProvider = securityProvider;

            config.authentication = config.authentication || {};

            // BUFP-39984: this is an optional list of auth types that the preprocessor plugin should just 'pass along',
            // instead of interpreting. this is to support "third party (FA)" interpretation of VB auth types.
            // This can be passed as an explicit parameter to the plugin, but it's also passed here via the userConfig.
            // That way, security provider can specify an (override) value, but its not required ,a dn the plugin
            // will get the setting, because it looks in both places (see authPreprocessorHandlerPlugin).
            config.passthroughs = ConfigLoader.initParams[Constants.InitParams.PLUGIN_PASSTHROUGHS];

            // initialize the security provider
            return this.securityProvider.initialize(config).then(() => {
              const { userInfo } = this.securityProvider;
              if (userInfo.isAuthenticated) {
                this.log.info('User', userInfo.username, 'is authenticated.');
              } else {
                this.log.info('User is anonymous.');
              }
            });
          });
        }

        return undefined;
      });
    }

    /**
     * Initialize the appUiInfos property with a map of AppUiInfo
     * and set isHostApplication to true if the application is a host application.
     * @return {Promise} a promise that resolve when the work is done
     */
    initAppUis() {
      return this.extensionRegistry.getAppUiInfos()
        .then((appUiInfos) => {
          this.appUiInfos = appUiInfos;
          return this.runtimeEnvironment.disableAppUis()
            .then((disabled) => {
              this.isHostApplication = Utils.isHostApplication() && !disabled;
            });
        });
    }

    /**
     * The place to initialize builtins variables.
     */
    initializeBuiltins() {
      super.initializeBuiltins();

      if (this.securityProvider) {
        // create the user variable with securityProvider.userInfo as its value
        this.scope.createVariable(Constants.APPLICATION_USER_VARIABLE, Constants.VariableNamespace.BUILTIN,
          this.securityProvider.constructor.getUserInfoType(),
          this.securityProvider.userInfo, undefined, { writable: false });
      }

      // expose the active profile in the expression language (as a constant) - $application.profile
      this.createConstant(Constants.PROFILE_CONSTANT, {
        type: 'string',
        defaultValue: ConfigLoader.activeProfile,
      }, Constants.VariableNamespace.BUILTIN);

      // expose deployment type in the expression language (as a constant) - $application.deployment
      // $application.deployment.pwa === 'enabled' for mobile VB PWA's applications that have been staged
      const pwaEnabled = PwaUtils.isPwaConfig(globalThis.vbInitConfig);
      const appType = 'web';
      const pwa = pwaEnabled ? 'enabled' : 'disabled';
      this.createConstant(Constants.DEPLOYMENT_CONSTANT, {
        type: 'object',
        defaultValue: {
          appType,
          pwa,
        },
      }, Constants.VariableNamespace.BUILTIN);

      if (Utils.isHostApplication()) {
        // Create the currentAppUi variable
        this.scope.createVariable(Constants.GLOBAL_CURRENT_APPUI_VARIABLE, Constants.VariableNamespace.BUILTIN,
          this.appUiInfos.constructor.getAppUiInfoType(), {}, undefined, { writable: false });
      }
    }

    // eslint-disable-next-line class-methods-use-this
    defineCurrentPageBuiltinVariable() {
      // The correct value is set when the page is navigated.
      return {
        type: 'any',
        defaultValue: { id: '', path: '', title: '' },
      };
    }

    /**
     * Return the promise to load the functions module using the name of a functions loader.
     * @return {Promise.<Function|Object>}  the promise of a constructor a singleton object
     */
    loadFunctionModule() {
      // app functions loader loads the app module and also app util files
      if (this.loadAppFunctionsPromise) {
        return this.loadAppFunctionsPromise;
      }
      const functionsLoaderPromise = super.loadFunctionModule();
      const promises = [];
      promises.push(functionsLoaderPromise);

      const loadUtilsPromise = Utils.getResource(APP_UTILS_PATH)
        .then((appUtils) => {
          this.builtinUtils = appUtils;
          return appUtils;
        });
      promises.push(loadUtilsPromise);
      this.loadAppFunctionsPromise = Promise.all(promises);

      return this.loadAppFunctionsPromise;
    }

    /**
     * Define the info builtin variable object
     * Adds the 2 properties appUis and appUiInfo to the properies of the flow
     * @return {Object}
     */
    defineInfoBuiltinVariable() {
      const defaultValue = super.defineInfoBuiltinVariable();

      if (Utils.isHostApplication()) {
        // Only the application object has the info.appUis and info.appUiInfos properties
        return Object.assign(defaultValue, {
          appUis: this.appUiInfos.getAppUiIds(),
          appUiInfos: this.appUiInfos.info,
        });
      }

      return defaultValue;
    }

    /**
     * returns the ApplicationContext constructor used to create the '$' expression context
     * Override Flow.ContextType
     * @type {ApplicationContext}
     */
    static get ContextType() {
      return ApplicationContext;
    }

    onNavigate(listener, context) {
      this.reduxRouter.navigated.add(listener, context);
    }

    /**
     * Handler for the reduxRouter onNavigate event. Update the ojModule config for each nested
     * flow and fire the afterNavigate event.
     */
    navigated(hasChanged) {
      if (hasChanged) {
        const page = Router.getCurrentPage(true);

        // A null page part of the normal process when loading a new URL. When the URL specify a leaf
        // page like /shell/flow/page, the loading is lazy, so the shell page loads first and realize
        // there is a child flow so it creates a child router and calls VbRouter.sync() to process the
        // next router level that has just been created. At that point navigated is called but page is
        // undefined because we haven’t reach a leaf page. The process continue, until eventually we
        // get to page “page” without a child, at that point we can continue the process, set the title
        // and dispatch the navigated event.
        if (!(page instanceof Page)) {
          return;
        }

        // Calculate the navigation path from the page root router to that page
        const navPath = page.getNavPath();
        // Update the navigation path stored in the root router of this page.
        // If this page is a switcher page, the root router is the one in the switcher
        // not the one in Router.js which is application based.
        page.rootRouter.setCurrentNavPath(navPath);

        // Assign the $application.currentPage variable
        this.updateApplicationCurrentPageVariable(page);

        page.invokeAfterNavigateEvent();
      }
    }

    /**
     * Assign the $application.currentPage variable
     *
     * @param {Page}  page
     */
    updateApplicationCurrentPageVariable(page) {
      // Build the title and update the browser
      const title = page.buildTitle();
      if (title && title !== window.document.title) {
        window.document.title = title;
      }

      // Uses the variable setValueInternal because it's a readonly variable and the regular
      // assignment will fail.
      const currentPageVar = this.scope.getVariable(Constants.CURRENT_PAGE_VARIABLE,
        Constants.VariableNamespace.BUILTIN);

      const path = page.getNavPathFromTopRouter();

      // Flow navigation updates builtin variables that triggers recompute of observable
      // expressions using them, leads to errors if previous exited flow scope variables are read.
      try {
        currentPageVar.setValueInternal({
          id: page.id,
          path,
          title,
        });
      } catch (e) {
        // Dropping the exception as the recompute happens on observable expressions on an exited flow
        const builtinVarExpr = `${Constants.ALL_SCOPES[1]}.${Constants.CURRENT_PAGE_VARIABLE}`;
        // eslint-disable-next-line max-len
        logger.info('Updating builtin variables', builtinVarExpr, 'during flow navigation triggered error. \n', e.message,
        // eslint-disable-next-line max-len
          '\n This can happen when a bound expression containing the builtin variable re-evaluates while the state of the other variable(s) have been disposed due to navigation.');
      }
    }

    /**
     * Return the first flow up in the parent hierarchy.
     * Application is at the root, so always returns null.
     *
     * @return {Flow} the first flow in the parent hierarchy
     */
    // eslint-disable-next-line class-methods-use-this
    getParentFlow() {
      return null;
    }

    /**
     * Return the path of the current page. The path is relative to the application flow.
     * When the current page is not set, by example when this function is called before
     * the current page is known, the value returned is null.
     *
     * @return {String} null or a path relative to the application
     */
    getCurrentPagePath() {
      // Check for scope because there are cases where getCurrentPath is called before
      // the scope is created.
      return this.scope && this.expressionContext[Constants.CURRENT_PAGE_VARIABLE].path;
    }

    // eslint-disable-next-line class-methods-use-this
    updateFlowCurrentPageVariable() {
      // no-op
    }

    /**
     * Build the title that will be used for this page.
     * Walk up the flow hierarchy and gather the title of all pages.
     *
     * @param {String} title the base of the title
     * @return {String} the title
     */
    // eslint-disable-next-line class-methods-use-this
    buildTitle(title) {
      return title;
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      return {
        [Constants.GLOBAL_PREFIX]: this,
        [Constants.APPLICATION_PREFIX]: this,
      };
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * applicationClass it could either be 'global' if it's defined at the host app level or
     * 'application'.
     *
     * @type {String}
     */
    get eventPrefix() {
      return this.isHostApplication ? Constants.GLOBAL_PREFIX : Constants.APPLICATION_PREFIX;
    }

    /**
     * overridden from container.js; return our Service object, and all extension ones.
     * This is called by Container, to always include all services defined at the app level,
     * when looking for endpoints.
     *
     * This list will contain, in order:
     * - our (base) Services (namespace: 'base')
     * - one Services for each extension (namespace: ext ID)
     *
     * @returns {Array<Services>}
     */
    getAllServices() {
      return Utils.toFlatUniqueArray(
        this.getServices(),
        this.extensionsArray.map((e) => e.getAllServices()),
      );
    }

    /**
     * Get instance of Services associated with the application extension with the given extension ID.
     *
     * @param {string} extensionId
     * @returns {Services}
     */
    getExtensionServices(extensionId = Constants.ExtensionNamespaces.BASE) {
      if (extensionId === Constants.ExtensionNamespaces.BASE) {
        return this.getServices();
      }
      const ext = this.extensions[extensionId];
      if (ext) {
        return ext.getServices();
      }
      logger.info('getting services for extension: ', extensionId, 'that is not loaded failed');
      return undefined;
    }

    /**
     * initialize the services object, but don't load, and tell it that it is declared in the Application
     * so we can restrict use of '..' in flows
     *
     * at the application level, service IDs can be sourced from two places:
     * - the "services" declaration that can appear in any flow
     * - the "services" objects in the catalog
     *
     * Override Flow.createServices()
     *
     * @returns {Promise}
     *
     * @private
     */
    createServices() {
      // I am using a function here to create an abstraction between Utils and the
      // ConfigLoader (the former only cares about the servicesGlobalVariables).
      // eslint-disable-next-line no-param-reassign
      Utils.servicesGlobalVariableSupplier = () => ConfigLoader.servicesGlobalVariables;

      // Setting the catalogRegistry.extensionRegistry.
      // This is needed now because protocol registry collects ids and versions of extensions
      // while collecting all the names
      this.catalogRegistry.extensionRegistry = this.extensionRegistry;

      return this.protocolRegistry.getNames(Constants.CatalogNamespaces.BASE, 'services')
        .then((protocols) => {
          /**
           * Get the names of all services objects form the catalog, and add "vb-catalog://..." references
           * to the internal app-flow.json service map, to enable references to the service ID to load via the catalog
           * without needed an explicit definition.
           *
           * In other words, we add an internal (invisible) reference to app-flow, as if the user declared it as:
           * "services": {
           *   "foo": "vb-catalog://services/foo"
           * }
           */
          let serviceFileMap = this.definition.services || {};

          const protocolNamespaces = protocols.find((name) => name.protocol === Constants.VbProtocols.CATALOG);

          // 'namespaces' is an array for each namespace; the first one should always be 'base'
          if (protocolNamespaces && protocolNamespaces.namespaces.length) {
            const catalogNames = protocolNamespaces.namespaces[0];
            // in theory, the protocolRegistry can have services from multiple protocol handlers.
            // we only want the ones that can be referenced by the "vb-catalog" protocol

            // and add catalog "services", if any
            if (catalogNames && catalogNames.services
              && Array.isArray(catalogNames.services) && catalogNames.services.length) {
              serviceFileMap = Object.assign({}, serviceFileMap);
              catalogNames.services.forEach((name) => {
                if (!serviceFileMap[name]) {
                  serviceFileMap[name] = {
                    path: `${protocolNamespaces.protocol}://services/${name}`,
                  };
                }
              });
            }
          }
          const options = {
            relativePath: this.getResourceFolder(),
            serviceFileMap,
            isUnrestrictedRelative: true, // this is why we override createServices()
            expressionContext: this.getAvailableContexts(),
            protocolRegistry: this.protocolRegistry,
            extensionRegistry: this.extensionRegistry,
          };

          this.services = new Services(options);

          // we need to be able to use a default path for references to undeclared "services";
          // build a 'fallback' Services (using an array for future expansion)
          this.services.addDelegate(new FallbackServices(options));

          // we make all extension create their services mode here;
          // load is only called for an ApplicationExtension when the app-flow.x.json exists,
          // but we want to create a services model from the extension contents, even if there is no app-flow-x.json
          return Promise.all(this.traverseExtensions('createServices'))
            .then((extServicesCreated) => {
              // this is adjusting the services of the extensions to allow for properly resolving the service name,
              // i.e., "search on the extension, then on the required extensions, then on base".
              this.extensionsArray.forEach((applicationExtension) => {
                // gets the services of the "application extension"
                const applicationExtensionServices = applicationExtension.services;

                // gets the "extension" for the "application extension"
                const extension = applicationExtension.extension;

                // for each required extension, get the services of the associated "application extension",
                // then add it as a delegate to applicationExtensionServices
                extension.getRequiredExtensions().forEach((requiredExtension) => {
                  const delegate = this.extensions[requiredExtension.id].services;
                  applicationExtensionServices.addDelegate(delegate);
                });

                // add the application's services as the ultimate delegate.
                applicationExtensionServices.addDelegate(this.services);
              });
              return extServicesCreated;
            });
        });
    }

    /**
     * Overrides the base impl, so we can add "isUnrestrictedRelative: true"
     * to tell us the app is declaring this, so we can restrict use of '..' in flows/pages
     * @returns {Promise}
     */
    loadTranslationBundles() {
      // (V1) you can reach outside of your current folder (only app-flow)
      return super.loadTranslationBundles({ isUnrestrictedRelative: true });
    }

    /**
     * accessor for initParams
     * @type {Object}
     */
    // eslint-disable-next-line class-methods-use-this
    get initParams() {
      return ConfigLoader.initParams;
    }

    /**
     * accessor for the one in the ConfigLoader
     * @type {ProtocolRegistry}
     */
    // eslint-disable-next-line class-methods-use-this
    get protocolRegistry() {
      return ConfigLoader.protocolRegistry;
    }

    /**
     * accessor for the one in the ConfigLoader
     * @type {CatalogRegistry}
     */
    // eslint-disable-next-line class-methods-use-this
    get catalogRegistry() {
      return ConfigLoader.catalogRegistry;
    }

    /**
     * @param p
     * @private only for tests!!!!
     */
    // eslint-disable-next-line class-methods-use-this
    set protocolRegistry(p) {
      // eslint-disable-next-line no-param-reassign
      ConfigLoader.protocolRegistry = p;
    }

    /**
     * @type {ExtensionRegistry}
     */
    // eslint-disable-next-line class-methods-use-this
    get extensionRegistry() {
      return ConfigLoader.extensionRegistry;
    }

    /**
     * creates a listener for messages from the fetch plugins;
     * currently only listens for the 'vbResourceChanged' message.
     * When we get this;
     *  - get a unique version from the message   @todo: need to find out what the server will really send
     *  - if we have already started firing a vbResourceChanged even for that version, skip it;
     *    otherwise, fire a new one; these are not sequential
     *  - if handleResourceChangeResponse returns true, meaning there was a listener registered,
     *    we will stop firing the event, until the app is REFRESHED. @todo: do we want to keep firing & notifying?
     *
     * @returns {Promise}
     */
    installFetchPluginMessageHandler() {
      return Promise.resolve()
        // .then(() => Utils.getResource('vb/private/services/servicesManager')) // cyclic dependency
        .then(() => {
          let handlerEnabled = true;
          let handlerPending = []; // [{ headerValue: {string}, promise: {Promise} }]

          this.swMessageHandler = {
            // this name is defined by the resourceChangedPlugin
            vbResourceChanged: (url, error, headerValue) => {
              if (this.swMessageHandler.isEnabled()) {
                // look for an existing one; we want to debounce the events.
                // if one comes in with the same header value, skip it.
                // otherwise, process it.
                const existing = handlerPending.find((elem) => elem.headerValue === headerValue);
                if (!existing) {
                  const promise = this.handleResourceChangeResponse(url, error, headerValue);
                  handlerPending.push({
                    headerValue,
                    promise,
                  });
                  promise.then((wasHandled) => {
                    // remove any pending ones from the array
                    handlerPending = handlerPending.filter((elem) => elem.headerValues !== headerValue);
                    if (wasHandled) {
                      this.swMessageHandler.disable();
                    }
                  });
                }
              }
            },

            // (reverse) map a url, if possible
            vbGetUrlMapping: (url) => ConfigLoader.urlMapper && ConfigLoader.urlMapper.getUrlMapping(url),

            isEnabled: () => handlerEnabled,
            enable: () => {
              handlerEnabled = true;
            },
            disable: () => {
              handlerEnabled = false;
            },
          };

          ServiceWorkerManager.getInstance().installMessageHandler(this.swMessageHandler);
        });
    }

    /**
     * fire a vbResourceChanged event, starting with either the current page, or the application (if no page).
     * check the promise resolution, to see if any listeners were actually called;
     *
     * return true if a listener was called, otherwise, return false;
     *
     * @param url
     * @param errorText
     * @param headerValue
     * @returns Promise{boolean} resolves to true if handled, false otherwise
     */
    handleResourceChangeResponse(url, errorText, headerValue) {
      return Promise.resolve()
        .then(() => {
          let error = errorText;
          try {
            error = JSON.parse(error);
          } catch (e) {
            // ignore JSON parsing errors for 'error'
          }
          const eventPayload = {
            url,
            error,
            headerValue,
          };
          const container = Router.getCurrentPage() || this;
          return container.invokeEventWithBubbling(Constants.RESOURCE_CHANGED_EVENT, eventPayload);
        })
        .then((results) => {
          if (results) {
            const resultsFromListeners = results.filter((result) => result !== Constants.NO_EVENT_LISTENER_RESPONSE);
            // no one handled it, just log it, and don't remove the handler
            if (!resultsFromListeners.length) {
              logger.info('unhandled event', Constants.RESOURCE_CHANGED_EVENT);
            } else {
              // it was handled remove this; they either don't care, or they refreshed, re-installing the handler
              // only fire the event ONCE if it has been handled!
              logger.info('handler disabled', Constants.RESOURCE_CHANGED_EVENT);
              return true; // we handled it
            }
          }
          return false; // we did NOT handle it
        })
        .catch((error) => {
          logger.error(error);
          return false; // treat as if we we did not handle it
        });
    }

    /**
     * Override the flow implementation.
     * In the case of applicationClass, the moduleConfig is defined right here
     *
     * @return {function}  The moduleConfig that is used for the viewModel of index.html (see
     * usage in bootStrap.js)
     */
    getParentModuleConfig() {
      return this.moduleConfig;
    }

    dispose() {
      super.dispose();

      // Mutates ojModule in order to disconnect and release inner ko bindings.
      this.moduleConfig(Constants.blankModuleConfig);

      VbRouter.rootInstance.dispose();

      if (this.reduxRouter) {
        this.reduxRouter.dispose();
      }
      if (this.services) {
        this.services.dispose();
      }

      StoreManager.dispose();

      this._loadApplicationPromise = null;
      this._messageHandlerPromise = null;

      Performance.clear(true);
    }

    /**
     * Logs VB version in the console. If there is a mismatch between JET version that VB was built with
     * and JET version that is used by the application, a warning will be logged.
     */
    logVersion() {
      this.log.custom(`Starting Visual Builder Runtime v${Versions.visualDevelopmentPlatform.version}`,
        `(${Versions.visualDevelopmentPlatform.sprint}) commit: ${Versions.visualDevelopmentPlatform.commit}`);
      // JET version that VB was built with:
      const vbJet = Versions.jet.version;
      // the actual JET version:
      const actualJet = oj.version;
      const versionMatchRegExp = /[^.]*.[^.]*/;
      // Checking for mismatch in vbJet and actualJet version by comparing only the major and minor version numbers:
      // eslint-disable-next-line max-len
      if (actualJet.match(versionMatchRegExp)[0] !== vbJet.match(versionMatchRegExp)[0]) {
        // eslint-disable-next-line max-len
        this.log.error(`JET version mismatch: Visual Builder was built with ${vbJet}, but is running with ${actualJet}`);
      }
    }
  }

  return Application;
});

