/* eslint-disable max-classes-per-file */
/* eslint-disable prefer-destructuring, prefer-object-spread */

'use strict';

// This file was originally located in src/vb/private/. For full history: git log src/vb/private/log.js
define('vbc/private/log',[
  'vbc/private/constants',
  'vbc/private/logConfig',
  'vbc/private/utils',
  'vbc/private/performance/performance',
  'vbc/private/trace/tracer',
  'vbc/private/trace/spanContext',
  'vbc/private/monitorOptions',
], (Constants, LogConfig, Utils, Performance, Tracer, SpanContext, MonitorOptions) => {
  // The place where all the loggers are cached
  const cachedLoggers = {};

  /**
   * The global configuration object for all loggers that allows to turn off fancy mode(colors) and emoji's.
   * This is typically specified as a window.vbInitConfig.LOG parameter in index.html:
   * <pre>
   *   {
   *   mode: 'simple',
   *   emoji: 'off',
   *   }
   * </pre>
   * @type {{}}
   */
  let logConfig = {};
  // Object to store log level and log mode in session storage of browser to survive refresh on page
  const vbLog = {};
  // Array with pre-defined log levels
  const logLevels = ['error', 'warn', 'fine', 'finer', 'info'];
  // Array with pre-defined log modes
  const logModes = ['simple', 'fancy', 'contrast', 'test'];

  // A no-op used in various place
  const noop = () => {
  };

  const resolveConsoleMappings = () => {
    // check to make sure we can actually log
    const compatLog = (console && console.log) ? console.log : noop;
    const compatInfo = (console && console.info) ? console.info : compatLog;
    const compatErr = (console && console.error) ? console.error : compatLog;
    const compatWarn = (console && console.warn) ? console.warn : compatLog;
    const compatDebug = (console && console.debug) ? console.debug : compatLog;

    /**
     * An object use to map a log severity to a console method name
     * @type {Object}
     */
    const consoleMapper = {
      [Constants.Severity.ERROR]: compatErr,
      [Constants.Severity.WARNING]: compatWarn,
      [Constants.Severity.INFO]: compatInfo,
      [Constants.Severity.FINE]: compatDebug,
      [Constants.Severity.FINER]: compatDebug,
    };

    return consoleMapper;
  };

  let consoleMapper = resolveConsoleMappings();

  /**
   * Builds the log function
   *
   * @param {Function} paramsBuilder Typically bound Logger::_buildParams
   * @param {any} severity First params builder arg: message severity
   * @param {any} style Second params builder arg: message style
   *
   * @return {Function} Logging function
   */
  const basicLogFunctionCreator = ((paramsBuilder, severity, style) => {
    const consoleMethod = consoleMapper[severity];
    return consoleMethod.bind(console, ...paramsBuilder(severity, style));
  });

  // console.group support check
  const isGroup = !!(console.group && console.groupCollapsed && console.groupEnd);

  /**
   * Given a path and the information in the logConfig, retrieve which severity
   * method will show on the console.
   * @param  {String} path
   * @return {Object} an object where each property is a severity of type boolean
   */
  const getShowLogMap = (path) => {
    const loggerInfo = {};
    let loggerPath = path;

    // go through each of the path elements
    while (loggerPath.length > 0) {
      const foundLogger = LogConfig.Loggers[loggerPath];
      if (foundLogger) {
        // fill in the log levels that we can for the current path
        Constants.SeverityOrder.forEach((severity) => {
          if (loggerInfo[severity] === undefined && foundLogger[severity] !== undefined) {
            loggerInfo[severity] = foundLogger[severity];
          }
        });
      }

      // if we have all our info, abort
      if (Constants.SeverityOrder.every((severity) => loggerInfo[severity] !== undefined)) {
        break;
      }

      // get the next logger in the path
      const lastSlash = loggerPath.lastIndexOf('/');
      if (lastSlash === -1) {
        loggerPath = '';
      } else if (lastSlash === 0) {
        loggerPath = '/';
      } else {
        loggerPath = loggerPath.substring(0, lastSlash);
      }
    }

    return loggerInfo;
  };

  /**
   * Return true is the log should be in fancy mode
   * @return {Boolean}
   */
  const useFancyMode = (config) => (config.mode !== 'simple' && config.mode !== 'contrast');

  /**
   * Return true if the log should be in high contrast mode
   * @return {Boolean}
   */
  // eslint-disable-next-line max-len
  const useHighContrastMode = (config) => (config.mode !== undefined && config.mode !== 'simple' && config.mode !== 'fancy');

  /**
   * Return true is the log should be in fancy mode
   * By default emoji are off. To turn on emoji, set vbInitConfig.LOG.emoji === 'on'
   * @return {Boolean}
   */
  const useEmoji = (config) => (config.emoji === 'on');

  /**
   * Only use template for formatting messages if we are not running in test mode.
   *
   * @param config log configuration
   * @returns {Boolean}
   */
  const useTemplate = (config) => (config.mode !== 'test');

  /**
   * if the LogConfig.Styles[severity] has a truthy 'disabled' property
   * @param severity
   * @returns {*}
   * @private
   */
  const isSeverityDisabled = (severity) => !!(LogConfig.Styles[severity] && LogConfig.Styles[severity].disabled);

  const baseTemplate = '[VB (%s), %s]:';
  const noEmojiTemplate = `%c${baseTemplate}`;

  /**
   * Should be a static inside Logger as in
   * static logFunctionCreator. But, we dont support this yet.
   */
  let logFunctionCreator = basicLogFunctionCreator;

  /**
   * A toString method that filters sensitive data. See VBS-1591.
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects
   * Ugly objct exclusion. A unit test checks the load added by these additional
   * checks. Instanceof and type of checks are expected to be fast.
   * We are picking things that should be allowed from
   * the list above. Collections and complex objects are NOT ALLOWED
   * Look at "Check normal and restricted run performance" in the spec for
   * a performance test.
   */
  const argToString = (o) => ((typeof o === 'object'
    && !(o instanceof Error)
    && !(o instanceof RegExp)
    && !(o instanceof String)
    && !(o instanceof Number)
    // eslint-disable-next-line no-undef
    // && !(o instanceof BigInt) // available across browsers since September 2020.
    && !(o instanceof Date))
    ? '<object>' : o);

  /**
   * A logger that has a name and log levels.
   *
   * @param {String} path The logger path (i.e. /vb)
   * @param {Object[]} customLoggers the definition of custom loggers
   * @param {String} customLoggers.name - the name of the function on the logger
   * @param {String} customLoggers.severity - the severity to use on the custom logger
   * @param {String} customLoggers.style - the style to use on the custom logger
   * @params {Object} options custom logging configuration
   * @params {boolean} options.useEmoji true, if logging messages can use emojis for fancy style
   * @param {boolean} options.useFancyMode true, if fancy node should be used to format log messages
   * @param {Object} options.wrapErrorIfNeeded a function that allows to perform special handling for log errors
   * @see {@link LogConfig#Emojis}
   * @see {@link LogConfig#FancyStyle}
   * @constructor
   * @private
   */
  class Logger {
    constructor(path, customLoggers, options = {}) {
      this._display = path;

      this._showLog = getShowLogMap(path);

      this.customLoggers = customLoggers;

      this.options = options;

      this.options.wrapErrorIfNeeded = this.options.wrapErrorIfNeeded || noop;

      // Dynamically create the methods error, warn, info, fine and finer
      // on the Logger class
      Constants.SeverityOrder.forEach((severity) => {
        // Update the showLog map with info about the severity from the LogConfig
        const severityEnabled = !!(!isSeverityDisabled(severity) && this._showLog[severity] === true);
        this._showLog[severity] = severityEnabled;

        // Create a property for each severity (isInfo, isFine, isFiner, etc) so that logging can be
        // made optional using the condition logger.isFine
        let propName = severity.charAt(0).toUpperCase() + severity.slice(1);
        propName = `is${propName}`;
        this[propName] = severityEnabled;

        // Create the logging method
        this[severity] = this.getLoggerMethod(severity);
      });

      this.options.wrapErrorIfNeeded(this);

      // Create additional logging methods using severity and style passed to the constructor
      if (customLoggers) {
        customLoggers.forEach((def) => {
          this[def.name] = this.getLoggerMethod(def.severity, def.style);
        });
      }
    }

    /**
     * Returns a function that creates the log function.
     *
     * @param  {String} severity Current log function severity
     *
     * @return {Function} A function that takes a context, severity and style
     */
    static getLogFunctionCreator(severity) {
      // Always go back to basic log function creator for info and lesser severity
      return (severity === Constants.Severity.ERROR || severity === Constants.Severity.WARNING)
        ? logFunctionCreator : basicLogFunctionCreator;
    }

    /**
     * Turns on restricted logging for the logger class. If production mode is turned on, then,
     * Complex objects that are sent for printing to the console will NOT be printed to
     * the console. This avoids printing privileged data to the console.
     *
     * @param {boolean} prodMode Pass true for production mode. False by default.
     */
    static setRestrictedLoggingMode(prodMode) {
      // Implementation options for VBS-1591:
      // 1. Expose Logger and extend it in vb/private/log.js. Use production mode here.
      //    The downside is, direct users of vbc/private/log.js
      //    wont get "production mode"
      // 2. Import ojs/ojconfig here. ojconfig has a dep on 'ojs/ojcore-base' and a lot of other
      //    modules. Probably this is not for vbc.
      // 3. Given that vb/private/log.js already has way to reconfigure this module by passing config.
      //    piggyback on the same functionality and inject production mode into this module.
      // The chosen implementation is (3) here.
      if (prodMode) {
        // The current "filtering" is a bit simplistic. The assumption is,
        // objects should not be printed out as such. Other simple data
        // types can't be checked. They are just printed out as such.
        // Unfortunately, we will lose exact line numbers, but given
        // that this is done only for prod where the sources are minimized
        // anyway, this should be OK.
        // See VBS-1591
        logFunctionCreator = ((...baseCreatorArgs) => {
          const basicLogFunction = basicLogFunctionCreator(...baseCreatorArgs);
          // Wrap the baseLogFunctionCreator to return a function
          // that sanitizes the arguments
          return ((...argsToLog) => {
            const mappedArgs = argsToLog.map((arg) => argToString(arg));
            basicLogFunction(...mappedArgs);
          });
        });
      } else {
        logFunctionCreator = basicLogFunctionCreator;
      }
    }

    /**
     * Get the method that should be called when logging with a specific severity and style
     * The method is bound to the console object to display the correct log location on the
     * debugger.
     * @param  {String} severity
     * @param  {String} [style]
     * @return {Function}  the bound logging function
     */
    getLoggerMethod(severity, style) {
      // Add only warning and error here
      if (this._showLog[severity] === true) {
        // Please note that this is only called for logger function creation
        // Logger in the next line can be replaced by "this.constructor", only needed if we try to
        // extend Logger
        return Logger.getLogFunctionCreator(severity)(this._buildParams.bind(this), severity, style);
      }

      return noop;
    }

    /**
     * If logger is enabled for this severity, and <code>console.table</code> is enabled, specified object will
     * be displayed in a table within a <code>console.group</code>.
     * Otherwise, if logging level is enabled, but there is no table support, the object will be logged directly
     * in the console.
     * For a logging level that is disabled for this logger, there will be no console output.
     * @param {Object} options
     * @param {String} options.severity logging level. For example, 'fine', 'info', 'error'. If not enabled for this
     * logger, there will be no console output
     * @param {Object} options.obj the object to display in <code>console.table</code>
     * @param {String} options.groupTitle an optional title for the <code>console.group</code>, if group option is
     * available
     * @param options.group a boolean specifying if table should be showed within a (collapsed) group
     * @param options.logObject if true, object will be logged directly in console regardless of
     * <code>console.table</code> support
     */
    table({
      severity, obj, groupTitle = '', group = true, logObject = false,
    }) {
      if (this.isEnabled(severity) && Utils.isObject(obj)) {
        let endGroup = false;
        if (group && isGroup) {
          console.groupCollapsed(groupTitle);
          endGroup = true;
        }

        if (!logObject) {
          console.table(obj);
        } else {
          this.getLoggerMethod(severity)(obj);
        }
        if (endGroup) {
          console.groupEnd();
        }
      }
    }

    /**
     * @param severity logging level. For example, 'fine', 'info', 'error'.
     * @returns {boolean} true, if the logging level is enabled for this logger
     */
    isEnabled(severity) {
      return this[severity] !== noop;
    }

    /**
     * Creates a new monitor that can be used to time an operation.
     *
     * @param monitorOptions (Object) log monitor options for this monitor
     * @param fn (Function) a function that takes a timing function as a param to call when timing ends
     * @returns The return value of fn OR the end timing function if fn was not supplied
     */
    // eslint-disable-next-line class-methods-use-this
    monitor(monitorOptions, fn) {
      const startTime = new Date().getTime();
      let markName;
      let spanContext;
      let span;
      const endFunc = (detail) => {
        const duration = new Date().getTime() - startTime;
        if (markName) {
          Performance.markEnd(markName);
        }
        if (spanContext) {
          if (detail instanceof MonitorOptions) {
            spanContext.addEndSpan(() => (Object.assign({
              msg: detail.getEndMessage(),
            }, detail.getEndFields())));
          } else {
            spanContext.addError(detail);
          }

          Tracer.finishSpan(span, spanContext);
        }
        return `(completed in ${duration} ms)`;
      };

      if (monitorOptions instanceof MonitorOptions) {
        markName = [monitorOptions.operationName, monitorOptions.message];
        Performance.markStart(markName);
        spanContext = new SpanContext().addOperationName(monitorOptions.operationName);
        spanContext.addStartSpan(() => (Object.assign({
          msg: monitorOptions.getStartMessage(),
          tags: monitorOptions.getTags(),
        }, monitorOptions.getStartFields())));
        spanContext.addEndSpan(() => (Object.assign({
          msg: monitorOptions.getEndMessage(),
        }, monitorOptions.getEndFields())));

        if (fn) {
          // Use the span() function (preferred)
          spanContext.addSpanFunction((spn) => {
            span = spn;
            return fn(endFunc);
          });
          return Tracer.span(spanContext);
        }

        // Use the startSpan() function
        span = Tracer.startSpan(spanContext);
      }

      return endFunc;
    }

    /**
     * Builds params to format log message.
     * @param {String} severity
     * @param {String} style
     * @returns {Array}
     * @private
     */
    _buildParams(severity, style) {
      const params = [];

      // directly format the message header when running in test mode
      if (!this.options.useTemplate) {
        params.push(`[VB (${LogConfig.Styles[severity].display}), ${this._display}]:`);
        return params;
      }

      if (!this.options.useFancyMode && !this.options.useHighContrastMode) {
        params.push(baseTemplate);
      } else if (style) {
        let fancyStyle = `fancy-${style}`;
        let emoji = '';
        if (this.options.useEmoji) {
          emoji = LogConfig.Emojis[fancyStyle];
          emoji = emoji ? `${emoji} ` : '';
        }
        params.push(`%c${emoji}${baseTemplate}`);
        if (this.options.useHighContrastMode) {
          fancyStyle = 'fancy-contrast';
        }
        params.push(LogConfig.FancyStyles[fancyStyle]);
      } else if (this.options.useHighContrastMode) {
        params.push(noEmojiTemplate);
        params.push(LogConfig.FancyStyles['fancy-contrast']);
      } else {
        params.push(noEmojiTemplate);
        params.push(LogConfig.Styles[severity].style);
      }

      params.push(LogConfig.Styles[severity].display);
      params.push(this._display);

      return params;
    }
  }

  /**
   * Loggers are path based, to get a particular logger, use:
   *
   * Log.getLogger('/some/path')
   *
   * where the path is arbitrary but should reflect the organization of your resources.
   *
   * The second argument is used to define custom loggers. A custom logger has name, a severity
   * and a style. A new method with that name is created on the logger that will log a message
   * on the console using that severity and style.
   *
   * Logs can be configured by editing /loggers-config.js, please see the comments in there
   * for more details.
   */
  class Log {
    /**
     * Returns a logger for the given path (see Log.Loggers). The path will determine the attributes
     * of the logger, including what levels are shown by default.
     *
     * @param {String} path The logger path (i.e. /vb)
     * @param {Object[]} customLoggers the definition of custom loggers
     * @param {String} customLoggers.name - the name of the function on the logger
     * @param {String} customLoggers.severity - the severity to use on the custom logger
     * @param {String} customLoggers.style - the style to use on the custom logger
     * @param {Object} wrapErrorIfNeeded a function that allows to perform special handling for log errors
     */
    static getLogger(path, customLoggers, wrapErrorIfNeeded = noop) {
      let logger = cachedLoggers[path];

      if (!logger) {
        // create new logger with the logger levels
        const options = {};
        options.useTemplate = useTemplate(logConfig);
        options.useEmoji = useEmoji(logConfig);
        options.useFancyMode = useFancyMode(logConfig);
        options.useHighContrastMode = useHighContrastMode(logConfig);
        options.wrapErrorIfNeeded = wrapErrorIfNeeded;
        logger = new Logger(path, customLoggers, options);
        cachedLoggers[path] = logger;
      }

      return logger;
    }

    /**
     * Allows sub class to access cached loggers.
     * @return an object containing existing loggers keyed by path
     */
    static getCachedLoggers() {
      return cachedLoggers;
    }

    /**
     * When the logger definition has changed, for example after the app-flow.json is loaded,
     * adjust all the exist cached loggers with the possible new definition.
     * @param {Object} config (optional) log config options for setting fancy mode and emoji
     */
    static rebuildLoggers(config) {
      consoleMapper = Log.resolveConsoleMappings();
      Object.keys(cachedLoggers).forEach((path) => {
        const cached = cachedLoggers[path];
        const options = cached.options;
        if (config) {
          options.useEmoji = useEmoji(config);
          options.useFancyMode = useFancyMode(config);
          options.useHighContrastMode = useHighContrastMode(config);
        }
        const newLogger = new Logger(path, cached.customLoggers, options);
        Object.assign(cached, newLogger);
      });
    }

    /**
     * allow setting the minimum level of log message sent to the console,
     * to quiet the logs, and (typically) improve performance
     *
     * In CDT console use
     *    require(['vbc/private/log'], (Logger) => {
     *      Logger.setMinimumLevel('finer');
     *    });
     *
     * See docs/logging/README.md for more information about inspecting logging information in the browser console.
     *
     * @param {string} lvl one of 'error' | 'warn' | 'info' | 'fine' | 'finer'
     * @returns {boolean} success
     */
    static setMinimumLevel(lvl) {
      // backward compatibility
      const level = lvl === 'warning' ? 'warn' : lvl;
      const precedence = Constants.SeverityOrder.indexOf(level);
      if (precedence !== -1) {
        LogConfig.updateLoggerStyles(precedence);
        // @todo: revisit this; could we call this earlier?
        Log.rebuildLoggers();
        return true;
      }
      return false;
    }

    /**
     * A simple compare for log configuration objects. It does not consider default options, so:
     * <pre>
     *   {
     *   mode: 'simple',
     *   emoji: 'on',
     *   }
     * </pre>
     * and
     * <pre>
     *   {
     *   mode: 'simple',
     *   }
     * </pre>
     * are considered different configurations. Nulls are not supported. Improvements can be made in the future.
     * @param c1 original log config
     * @param c2 new log config
     * @returns {boolean} true, if both objects are the same
     * @private
     */
    static compareConfigs(c1 = {}, c2 = {}) {
      return c1.mode === c2.mode && c1.emoji === c2.emoji;
    }

    /**
     * Sets the global log configuration and rebuilds all existing loggers, if configuration has changed.
     * @param {Object} config the log configuration options, for example:
     * <pre>
     *   {
     *   mode: 'simple',
     *   emoji: 'off',
     *   }
     * </pre>
     */
    static setConfig(config = {}) {
      if (config !== null) {
        if (!Log.compareConfigs(logConfig, config)) {
          logConfig = config || {};
          Log.rebuildLoggers(logConfig);
        }

        // Configure logger based on production mode and tracer status
        // Tracer.isEnabled is available too, but,
        // 1. It is not explicitly initialized to false, so it could be internal
        // 2. It is set asynchronously after tracer module load
        // So, use the synchronous isTelemetryLoaded() callback instead
        // To test this, comment out && Tracer.isTelemetryLoaded() part
        // jet turns on production mode by default
        const useRestrictedLogging = config.productionMode && Tracer.isTelemetryLoaded();
        Logger.setRestrictedLoggingMode(useRestrictedLogging);
      }
    }
  }

  // Make sure this is set as soon as possible. Please see
  // https://jira.oraclecorp.com/jira/browse/VBS-38815
  Log.resolveConsoleMappings = resolveConsoleMappings;
  Log.NOOP = noop; // expose for unit tests
  Log.consoleMapper = consoleMapper; // Only for unit tests
  Log.Logger = Logger; // Only for tests
  Log.argToString = argToString; // Only for tests. Dont change built-in toString.

  const logger = Log.getLogger('/vbc/private/log', undefined);

  // Sets the log level and mode when configured in console by user
  if (globalThis.vbInitConfig) {
    if (globalThis.vbInitConfig.LOG === undefined) {
      globalThis.vbInitConfig.LOG = {};
    }
    // Get the default log level of the application to set vbInitConfig.LOG.level
    vbLog.level = LogConfig.getDefaultLogLevel();
    Object.defineProperty(globalThis.vbInitConfig.LOG, 'level', {
      get: () => vbLog.level,
      set: (value) => {
        vbLog.level = value;
        if (Log.setMinimumLevel(vbLog.level)) {
          sessionStorage.setItem(Constants.SessionStorage.LOG, JSON.stringify(vbLog));
        } else {
          logger.warn(`Unsupported log level: '${value}' - choose level from [${logLevels}].`);
        }
      },
    });
    // Get the default log mode of the application to set vbInitConfig.LOG.mode
    vbLog.mode = globalThis.vbInitConfig.LOG.mode || 'fancy';
    Object.defineProperty(globalThis.vbInitConfig.LOG, 'mode', {
      get: () => vbLog.mode,
      set: (value) => {
        if (logModes.includes(value)) {
          vbLog.mode = value;
          Log.rebuildLoggers(logConfig);
          sessionStorage.setItem(Constants.SessionStorage.LOG, JSON.stringify(vbLog));
        } else {
          logger.warn(`Unsupported log mode: '${value}' - choose mode from [${logModes}].`);
        }
      },
    });
  }

  // Get log level configured in session storage of browser
  const vbSessionLogString = globalThis.sessionStorage
  && globalThis.sessionStorage.getItem(Constants.SessionStorage.LOG);

  if (vbSessionLogString) {
    try {
      const vbSessionLog = JSON.parse(vbSessionLogString);
      if (Utils.isObject(vbSessionLog)) {
        // Update Logger Styles if vbSessionLog.level property configured in session storage of browser
        if (vbSessionLog.level) {
          globalThis.vbInitConfig.LOG.level = vbSessionLog.level;
        }
        // Update Logger Styles if vbSessionLog.mode property configured in session storage of browser
        if (vbSessionLog.mode) {
          globalThis.vbInitConfig.LOG.mode = vbSessionLog.mode;
        }
      }
    } catch (err) {
      logger.error('Error retrieving VB log configuration from browser session storage.', err);
    }
  }

  return Log;
});

