'use strict';

define('vb/private/serviceWorker/serviceWorkerManagerFactory',['vb/private/utils', 'vbc/private/pwa/pwaUtils'], (Utils, PwaUtils) => {
  function getServiceWorkerManagerInfo(vbInitConfig = {}) {
    let swManagerClassName = 'vbsw/private/serviceWorkerManagerClass';
    // Even if this is a PWA, Service Worker is not enabled in DT preview
    if (!vbInitConfig.IS_DT_MODE && PwaUtils.isWebPwaConfig(vbInitConfig)) {
      swManagerClassName = 'vb/private/pwa/pwaServiceWorkerManagerClass';
    }

    return Utils.getResources(['vbsw/private/serviceWorkerManager', swManagerClassName])
      .then(([ServiceWorkerManager, SWManagerClass]) => ({
        ServiceWorkerManager,
        SWManagerClass,
      }));
  }

  /**
   * A factory that creates a ServiceWorkerManager based on vbInitConfig.
   * When vbInitConfig.PWA_CONFIG is set, an instance of PwaServiceWorkerManagerClass is created,
   + unless either vbInitConfig.IS_DT_MODE or vbInitConfig.PWA_CONFIG.disabled is set to true.
   * In all other cases, an instance of ServiceWorkerManagerClass is created.
   * An instance of service worker manager class is then set on ServiceWorkerManager.
   *
   * Consolidate the getResource calls into getServiceWorkerManagerInfo.
   * MJR NOTE: Strongly advised against changing apis so as not to break possible DT usages.
   * So I've created getServiceWorkerManagerInfo() above to consolidate the getResource calls, and now the
   * two apis below are independent of each other, but follow the same logic.
   */
  class ServiceWorkerManagerFactory {
    static createServiceWorkerManager(vbInitConfig) {
      return getServiceWorkerManagerInfo(vbInitConfig)
        .then(({ ServiceWorkerManager, SWManagerClass }) => {
          const swmInstance = new SWManagerClass();
          ServiceWorkerManager.setInstance(swmInstance);
          return ServiceWorkerManager;
        });
    }

    /** only used in serviceWorkerManagerFactorySpec */
    static getServiceWorkerManagerClass(vbInitConfig = {}) {
      return getServiceWorkerManagerInfo(vbInitConfig)
        .then(({ SWManagerClass }) => SWManagerClass);
    }
  }
  return ServiceWorkerManagerFactory;
});

