'use strict';

define('vbsw/private/plugins/authPreprocessorHandlerPlugin',[
  'vbsw/api/fetchHandlerPlugin',
  'vbsw/private/constants',
  'vbc/private/constants',
  'vbc/private/log',
  'vbsw/private/utils',
], (FetchHandlerPlugin, Constants, CommonConstants, Log, SWUtils) => {
  // constants for legacy syntax
  const VB_PROXY_URLS = 'proxyUrls';
  const VB_PROXY_URL = 'proxyUrl';
  const VB_TOKEN_RELAY_URLS = 'tokenRelayUrls';

  const VB_HTTPS_PREFIX = /^https/;

  // constants for new syntax
  const VB_PROPAGATE = 'propagate';
  const VB_AS_AUTHENTICATED_USER = 'as_authenticated_user';

  const logger = Log.getLogger('/vbsw/private/plugins/authPreprocessorHandlerPlugin');

  // supported forceProxy values
  const ForceProxy = {
    ALWAYS: 'always',
    CORS: 'cors',
  };

  // authenticated types that support token relay
  // cloud is the legacy user assertion type where the scope is calculated for you as required by FA.
  // oauth2_client_credentials, oauth2_user_assertion and oauth2_resource_owner represent standard
  //   OAuth 2.0 types.
  // oauth_alm_cross_stripe is a specific type that has the interface of an OAuth type that is
  //   specific to running ALM for internal SPECTRA development where the bearer token is fetched
  //   via the environment API to allow request into a different non-federated stripe. This will
  //   hopefully be replaced with an implementation of the standard OAuth 2.0 type oauth2_code_grant
  //   in the future and will be removed.
  const VB_TOKEN_RELAY_TYPES = ['cloud', 'oauth2_client_credentials', 'oauth2_user_assertion', 'oauth2_resource_owner', 'oauth_alm_cross_stripe'];

  // list of auth types that will set forceProxy = true;
  const MUST_FORCE_PROXY = [Constants.AuthenticationType.BASIC, Constants.AuthenticationType.HTTP_SIGNATURE_OCI];

  // authenticated types that will always use the proxy if forceProxy is always - used for tests only!!
  const VB_ALWAYS_PROXY_TYPES = VB_TOKEN_RELAY_TYPES.concat(MUST_FORCE_PROXY);

  // default headers automatically added by the browser and used by the url mapper to skip existing
  // header check
  // TODO: only check for accept header for now and may add more in the future
  const DEFAULT_HEADERS = {
    accept: '*/*',
  };

  /**
   * This class is used to pre-process the security configuration and determine the authentication type
   * and add appropriate headers to the request so it can be properly handled by the downstream plugins.
   */
  class AuthPreprocessorHandlerPlugin extends FetchHandlerPlugin {
    constructor(context, params = {}) {
      super(context, params);

      this.defaultAuthentication = params.defaultAuthentication || {};

      // it can either be an explicit parameter, or come from "initParams".
      const userConfig = (context.fetchHandler.config && context.fetchHandler.config.userConfig);
      this.passthroughs = params.passthroughs
        || (userConfig && userConfig.configuration && userConfig.configuration.passthroughs) || [];

      // determine the default authentication type which can be inherited, defaults to oraclecloud
      // Note: The auth type is set to none by DT if nothing is selected so we'll default it to oraclecloud here.
      this.defaultAuthenticationType = this.defaultAuthentication.type;
      if (!this.defaultAuthenticationType || this.defaultAuthenticationType === Constants.AuthenticationType.NONE) {
        this.defaultAuthenticationType = Constants.AuthenticationType.ORACLE_CLOUD;
      }

      // indicate whether we are anonymous or authenticated
      this.isAnonymous = params.isAnonymous;

      // used to reverse-map URLs to 'vb-init-extension' information
      this.urlMapperClient = this.fetchHandler && this.fetchHandler.urlMapperClient;
    }

    // the following static getters are used by unit tests
    static get forceProxy() {
      return ForceProxy;
    }

    static get tokenRelayTypes() {
      return VB_TOKEN_RELAY_TYPES;
    }

    static get alwaysProxyTypes() {
      return VB_ALWAYS_PROXY_TYPES;
    }

    /**
     * Returns the url key based on the isAnonymous flag.
     *
     * NOTE: The request parameter is used by DT to inject additional behaviors in the service endpoint
     * tester. This will be removed in 19.4.1 when we switch to using the service endpoint test utility
     * we plan to implement.
     *
     * @param isAnonymous true if the request is anonymous, false otherwise
     * @param request used by DT to inject additional behaviors
     * @returns {string}
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    getUrlKey(isAnonymous, request) {
      return isAnonymous ? 'anonymous' : 'authenticated';
    }

    /**
     * Extract the token relay url from the given request.
     *
     * @param request the request to extract the token relay url
     * @returns {string|null}
     */
    static getTokenRelayUrlFromRequest(request) {
      const infoExtensionHeader = request.headers.get(CommonConstants.Headers.VB_INFO_EXTENSION);

      if (infoExtensionHeader) {
        const infoExtension = JSON.parse(infoExtensionHeader);
        if (infoExtension.resolvedTokenRelayUrl) {
          return infoExtension.resolvedTokenRelayUrl;
        }
      }

      return null;
    }

    /**
     * Returns true if CORS is enforced, i.e., by the web browser.
     *
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    enforceCORS() {
      return true;
    }

    /**
     * check if we need to use the proxy for http
     * @param request
     * @param infoExtension
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    useProxyForProtocol(request, infoExtension) {
      // the 'override' allows the request to reach the service worker, when its used;
      // normally 'http' would be blocked for an 'https' request, and wouldn't get this far.
      return request.headers.get(CommonConstants.Headers.PROTOCOL_OVERRIDE_HEADER)
        && (infoExtension.forceProxy === undefined || infoExtension.forceProxy === null);
    }

    /**
     * Return true if the given type is one of the supported token relay types.
     *
     * @param type type to check for token relay support
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    supportsTokenRelay(type) {
      return VB_TOKEN_RELAY_TYPES.indexOf(type) >= 0;
    }

    /**
     * Process the given x-vb info extension. It will directly interpret the new authentication block if
     * it exists. Otherwise, it will fall back to interpreting it using the legacy syntax.
     *
     * @param {Object} infoExtension the x-vb info extension to process. It can be modified during processing.
     * @param request used by DT to inject additional behaviors
     * @returns {*}
     */
    processInfoExtension(infoExtension, request) {
      const hasLegacyUrls = infoExtension[VB_PROXY_URL]
        || infoExtension[VB_PROXY_URLS]
        || infoExtension[VB_TOKEN_RELAY_URLS];

      const needsProxyForProtocol = this.useProxyForProtocol(request, infoExtension); // force http: through proxy

      // new info extension contains an authentication block
      if (!hasLegacyUrls
        && (infoExtension.forceProxy || infoExtension.authentication || needsProxyForProtocol)) {
        const inheritDisabled = this.isInheritDisabledForDT(infoExtension);

        let authenticationType = Constants.AuthenticationType.DIRECT;
        let proxyUrl;
        let tokenRelayUrl;

        const urlKey = this.getUrlKey(this.isAnonymous, request);
        if (urlKey === 'anonymous' && infoExtension.anonymousAccess !== true) {
          // no authentication, proxy or tokenRelay allowed for anonymous user
          return { authenticationType };
        }

        let authentication;
        if (infoExtension.authentication) {
          authentication = infoExtension.authentication[urlKey];

          // once we have the proper authentication block, check if this auth type is one of the ones we should
          // skip, to allow non-VB plugins to process, unmodified
          if (this.isPassthrough(authentication)) {
            return {
              passthroughProperties: Object.assign({}, authentication),
            };
          }

          // if authentication is as_authenticated_user, use authenticated block instead
          if (authentication && authentication.type === VB_AS_AUTHENTICATED_USER) {
            authentication = infoExtension.authentication.authenticated;
          }

          // for 'basic' OR 'http_signature_oci' auth type, forceProxy should be always
          if (authentication && MUST_FORCE_PROXY.indexOf(authentication.type) >= 0) {
            // eslint-disable-next-line no-param-reassign
            infoExtension.forceProxy = ForceProxy.ALWAYS;
          }

          if (authentication && authentication.type === VB_PROPAGATE) {
            authenticationType = this.defaultAuthenticationType;

            // the following inherited authentication types don't require proxy regardless of
            // the value of forceProxy, so we simply return the authentication type
            if ((authenticationType === Constants.AuthenticationType.BASIC
              || (authenticationType === Constants.AuthenticationType.IMPLICIT && !inheritDisabled)
              || authenticationType === Constants.AuthenticationType.OAUTH) && !this.isAnonymous) {
              return { authenticationType };
            }
          }
        }

        // for BUFP-41808; when we are using 'implicit', and the in-page DT preview says to disable it, use proxy

        if (authenticationType === Constants.AuthenticationType.ORACLE_CLOUD
          || infoExtension.forceProxy === ForceProxy.ALWAYS
          || (inheritDisabled && authenticationType === Constants.AuthenticationType.IMPLICIT) // see BUFP-41808 above
          || (infoExtension.forceProxy === ForceProxy.CORS && this.enforceCORS())
          || needsProxyForProtocol) {
          authenticationType = Constants.AuthenticationType.ORACLE_CLOUD;
          // serviceUtils#augmentExtension resolves proxy URL based on the service name
          proxyUrl = infoExtension.resolvedProxyUrl;
        } else if ((infoExtension.forceProxy === ForceProxy.CORS || !infoExtension.forceProxy)
          && (authentication && this.supportsTokenRelay(authentication.type))) {
          authenticationType = Constants.AuthenticationType.TOKEN_RELAY;
          // serviceUtils#augmentExtension resolves tokenRelay URL based on the service name
          tokenRelayUrl = infoExtension.resolvedTokenRelayUrl;
        } else {
          authenticationType = Constants.AuthenticationType.DIRECT;
        }

        return {
          authenticationType,
          proxyUrl,
          tokenRelayUrl,
          trap2Enabled: infoExtension.trapEnabled,
          authentication,
          isLegacy: false,
          passthroughProperties: null,
        };
      }

      // fall back to processing legacy info extension
      return this.processLegacyInfoExtension(infoExtension, request);
    }

    /**
     * Process the given x-vb info extension using the legacy syntax.
     *
     * @param infoExtension the x-vb info extension to process
     * @param request used by DT to inject additional behaviors
     * @returns {{authenticationType: (string|*), proxyUrl: *, tokenRelayUrl: *, authentication: *, isLegacy: boolean}}
     */
    processLegacyInfoExtension(infoExtension, request) {
      // inherit can be disabled by setting inherit in dt-serviceAuthentication via DT extension
      // in getServiceExtensionOverride
      const authentication = infoExtension[CommonConstants.Headers.VB_DT_AUTHENTICATION];
      const inheritDisabled = this.isInheritDisabledForDT(infoExtension);

      // determine if we are inheriting the default authentication
      const inheritAuthentication = infoExtension[CommonConstants.Headers.INHERIT] && !inheritDisabled;

      // determine the authentication type and whether anonymous access is allowed
      // Note: authenticationType is hard-coded to oraclecloud for BOs without inherit and proxy and
      // we simply pick it up here
      let authenticationType = infoExtension.authenticationType;

      if (inheritAuthentication) {
        authenticationType = this.defaultAuthenticationType;
      }

      // process proxy and token relay urls
      const proxyUrls = infoExtension[VB_PROXY_URLS] || {};
      const tokenRelayUrls = infoExtension[VB_TOKEN_RELAY_URLS] || {};
      let proxyUrl;
      let tokenRelayUrl;
      let urlKey;

      if (inheritAuthentication && this.isAnonymous) {
        // if we are anonymous, check to see if an anonymous token relay url or proxy url is provided to override
        // the default authentication type
        urlKey = this.getUrlKey(true, request);
        proxyUrl = proxyUrls[urlKey];
        tokenRelayUrl = tokenRelayUrls[urlKey];

        if (tokenRelayUrl) {
          // use the token relay url to handle anonymous access
          authenticationType = Constants.AuthenticationType.TOKEN_RELAY;
        } else if (proxyUrl) {
          // use the proxy to handle anonymous access
          authenticationType = Constants.AuthenticationType.ORACLE_CLOUD;
        } else {
          // don't know how to handle anonymous access, set auth type to direct and let the request fail
          // if no explicit auth header is provided
          authenticationType = Constants.AuthenticationType.DIRECT;
        }
      } else {
        urlKey = this.getUrlKey(this.isAnonymous, request);
        proxyUrl = proxyUrls[urlKey];
        tokenRelayUrl = tokenRelayUrls[urlKey];
      }

      // support for legacy syntax
      if (!proxyUrl) {
        proxyUrl = infoExtension[VB_PROXY_URL];
      }

      // if we are not inheriting from the default authentication, determine the type based on the
      // tokenRelayUrl, proxyUrl, etc
      if (!authenticationType) {
        if (tokenRelayUrl) {
          authenticationType = Constants.AuthenticationType.TOKEN_RELAY;
        } else if (proxyUrl) {
          authenticationType = Constants.AuthenticationType.ORACLE_CLOUD;
        } else {
          authenticationType = Constants.AuthenticationType.DIRECT;
        }
      }

      return {
        authenticationType,
        proxyUrl,
        tokenRelayUrl,
        authentication,
        isLegacy: true,
      };
    }

    /**
     * 'implicit flow' should be disabled if inherit is set to false in dt-serviceAuthentication
     * the by DT extension in getServiceExtensionOverride
     *
     * inherit can be disabled by setting inherit in dt-serviceAuthentication via DT extension
     * in getServiceExtensionOverride.
     *
     * Also see abstractAuthHandlerPlugin.
     *
     * @param infoExtension
     */
    // eslint-disable-next-line class-methods-use-this
    isInheritDisabledForDT(infoExtension) {
      const dtAuthentication = infoExtension[CommonConstants.Headers.VB_DT_AUTHENTICATION];
      return dtAuthentication && dtAuthentication.inherit === false;
    }

    /**
     * is the 'type' one of the ones configured for passthrough?
     *
     * an application might typically configure this in index.html:
     * <script>
     *  window.vbInitParams = window.vbInitParams || {};
     *  window.vbInitParams[‘services.security.handlers.passthroughs’] = [‘propagate’];
     * </script>
     *
     * OR in app-flow.json
     *
     * "configuration": {
     *   "initParams": {
     *     "services.security.handlers.passthroughs": ["propagate"]
     *   }
     * }
     *
     * @param authentication
     * @returns {*|boolean}
     */
    isPassthrough(authentication) {
      return authentication && this.passthroughs.indexOf(authentication.type) >= 0;
    }

    /**
     * Interpret the authentication configuration
     *
     * @param request the request to which to modify
     * @param client
     * @returns {Promise}
     */
    handleRequestHook(request, client) {
      return Promise.resolve()
        .then(() => {
          // first check if the request came from VB; all data requests have this header when sent from main thread.
          const infoExtensionHeader = request.headers.get(CommonConstants.Headers.VB_INFO_EXTENSION);
          // if the header is not set (should return null, but check for undefined just in case)
          if (infoExtensionHeader === null || infoExtensionHeader === undefined) {
            return this.getExtensionFromMapping(request, client);
          }
          return JSON.parse(infoExtensionHeader);
        })
        .then((infoExtension) => {
          // bail if nothing found
          if (!infoExtension) {
            return request;
          }

          const {
            authenticationType,
            proxyUrl,
            tokenRelayUrl,
            trap2Enabled,
            authentication,
            isLegacy,
            passthroughProperties,
          } = this.processInfoExtension(infoExtension, request);

          // if passthrough, copy auth values to simple headers, and exit; don't interpret
          if (passthroughProperties) {
            return this.setPassthroughHeaders(request, passthroughProperties);
          }

          // get the list of header names before we start adding headers
          const headersBeforeProcessing = [];
          if (!isLegacy && authenticationType === Constants.AuthenticationType.ORACLE_CLOUD && proxyUrl) {
            for (const name of request.headers.keys()) {
              headersBeforeProcessing.push(name);
            }
          }

          // set the authentication type header on the request so it can be processed by the corresponding plugin
          request.headers.set(Constants.AUTHENTICATION_TYPE_HEADER, authenticationType);

          return Promise.resolve()
            .then(() => {
              if (infoExtension.urlToFetch) {
                // create new Request for the real URL to fetch
                // This can happen when the vb-catalog://backends/... URL fetch is requested; in that case
                // urlToFetch will contain resolved https:// URL of the backend.
                return FetchHandlerPlugin.getRequestConfig(request, this.fetchHandler)
                  .then((config) => this.fetchHandler.createRequest(infoExtension.urlToFetch, config));
              }
              return request;
            })
            .then((realRequest) => {
              if (authenticationType === Constants.AuthenticationType.TOKEN_RELAY) {
                realRequest.headers.set(Constants.TOKEN_RELAY_URL_HEADER, tokenRelayUrl);
                if (trap2Enabled) {
                  realRequest.headers.set(Constants.TRAP_2_ENABLED_HEADER, 'true');
                }

                // Provide override for manual configuration of authentication
                if (authentication) {
                  realRequest.headers.set(Constants.TOKEN_RELAY_AUTH_HEADER, JSON.stringify(authentication));
                }
              } else if (authenticationType === Constants.AuthenticationType.ORACLE_CLOUD && proxyUrl) {
                // create a new proxied version of the request
                const proxyPrefix = proxyUrl.endsWith('/')
                  ? proxyUrl.substring(0, proxyUrl.length - 1)
                  : proxyUrl;

                let suffix = realRequest.url.replace(':/', '');
                const protocol = realRequest.headers.get(CommonConstants.Headers.PROTOCOL_OVERRIDE_HEADER);
                if (protocol) {
                  suffix = suffix.replace(VB_HTTPS_PREFIX, protocol);
                }

                // Set information in vb-info-extension header that will cause FA token relay plugin to
                // inject appropriate token for accessing the RIS TRAP service
                // VBS-18763: add 'scope' to vb-info-extension header for RIS TRAP proxy calls
                // VBS-25313: add 'type' too
                if (trap2Enabled && infoExtension.trapScope) {
                  // preserve authentication needed by the TRAP server in case another plugin needs it
                  // eslint-disable-next-line no-param-reassign
                  infoExtension.trapAuthentication = infoExtension.authentication;

                  // new authentication block is for establishing connection with the TRAP server itself
                  // and not the authentication block of the service connection
                  // eslint-disable-next-line no-param-reassign
                  infoExtension.authentication = {};
                  const trapAuth = infoExtension.authentication;
                  trapAuth.authenticated = { // trap service is currently always authenticated
                    scope: infoExtension.trapScope,
                    type: infoExtension.trapAuthType,
                  };
                  realRequest.headers.set(CommonConstants.Headers.VB_INFO_EXTENSION, JSON.stringify(infoExtension));
                }

                // indicate that the proxy request should use access token when available
                realRequest.headers.set(CommonConstants.Headers.USE_OAUTH_ACCESS_TOKEN_WHEN_AVAILABLE, true);

                return FetchHandlerPlugin.getRequestConfig(realRequest, this.fetchHandler).then((config) => {
                  // prefix headers that the proxy should pass along
                  if (!isLegacy) {
                    // only do this for the new proxy
                    AuthPreprocessorHandlerPlugin.addPostProcessingHeader(config, headersBeforeProcessing);
                  }

                  // VBS-33902: When calling VBS builtin trap we want to send over current user credentials
                  if (trap2Enabled && infoExtension.defaultTrap) {
                    // eslint-disable-next-line no-param-reassign
                    config.credentials = 'include';
                  }

                  return this.fetchHandler.createRequest(`${proxyPrefix}/${suffix}`, config);
                });
              }
              return realRequest;
            });
        });
    }

    /**
     * set a header with JSON string with the current "authentication" block properties
     * @param request
     * @param properties
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    setPassthroughHeaders(request, properties) {
      if (properties) {
        request.headers.set(Constants.HEADER_PASSTHROUGH, JSON.stringify(properties));
      }
      return request;
    }

    /**
     * delegate to the UrlMapperClient to look up addition URL config.
     * this also adds the 'vb-info-extension' to the request
     * @param request
     * @param client
     * @returns {Promise<string>} the value of the vb-info-extensions header form the mapping, if any
     */
    getExtensionFromMapping(request, client) {
      return this.urlMapperClient ? this.urlMapperClient.getMapping(request, client)
        .then((mapping) => {
          if (mapping) {
            this.applyMapping(request, mapping);
          }
          return mapping;
        }) : Promise.resolve(null);
    }

    /**
     * add headers from the mapping to the request, but do not overwrite existing headers.
     *
     * the mapping object is
     * {
     *   headers: <Object>,orkwe
     *   serviceName: <string>,
     *   baseUrl: <string>
     * }
     *
     * this will add all the headers, and a special 'vb-info-extension ' header,
     * which is a string-ified version of this structure
     *
     * @param request the headers will be modified if there is a mapping
     * @param mapping if null, simply returns the request
     * @returns {Request}
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    applyMapping(request, mapping) {
      if (mapping) {
        const mappingStr = JSON.stringify(mapping);

        logger.info('UrlMapper found mapping for: ', request.url, mappingStr);

        // only add headers that don't already exist except for default headers added by
        // the browser when running on the service worker
        Object.keys(mapping.headers).forEach((header) => {
          const existing = request.headers.get(header);
          // should return null for headers that aren't set, but check for undefined just in case
          if (existing === null || existing === undefined
            || (DEFAULT_HEADERS[header.toLowerCase()] === existing && SWUtils.isWorkerThread())) {
            request.headers.set(header, mapping.headers[header]);
          }
        });

        // and add the vb-info-header extension
        request.headers.set(CommonConstants.Headers.VB_INFO_EXTENSION, mappingStr);
      }
      // return the (original, possibly modified) Request
      return request;
    }

    /**
     * this is used by the authPostProcessorHandlerPLugin
     *
     * the PRESENCE of the header means we are using the new 1.1 proxy, and need to prefix certain headers.
     * the VALUE of the header is a list of original header names, before we add other plugins-specific headers.
     * (note, other plugins may add other headers later)
     *
     * @param {Object} config In/out parameter
     * @param headersBeforeProcessing
     */
    static addPostProcessingHeader(config, headersBeforeProcessing) {
      // eslint-disable-next-line no-param-reassign
      config.headers[Constants.PROXY_HEADERNAME_HEADER] = JSON.stringify(headersBeforeProcessing || []);
    }
  }

  return AuthPreprocessorHandlerPlugin;
});

