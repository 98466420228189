'use strict';

define('vb/private/vx/localExtensionAdapter',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/vx/baseExtensionAdapter',
], (
  Constants,
  Utils,
  BaseExtensionAdapter,
) => {
  /**
   * The extension adapter used to load extension from local file
   */
  class LocalExtensionAdapter extends BaseExtensionAdapter {
    /**
     * For the local extension adapter, this function consist of loading the .json files mocking
     * the extension manager registry.
     * Return the promise responsible for loading the 3 digests runtime. requirejsInfo and appUiInfo
     * WARNING: The order of each element in the array is important.
     * @returns {Promise<Array<Promise<Array<Object>>>>}
     */
    initiateFetchManifest() {
      return Promise.resolve(this.loadDigests([
        Constants.Digest.RUNTIME,
        Constants.Digest.REQUIREJS_INFO,
        Constants.Digest.APP_UI_INFO,
      ]));
    }

    /**
     * Load the specified types from the remote extension digest
     * @param {String[]} types
     * @returns {Array<Promise<Array<Object>>>}
     */
    loadDigests(types) {
      this.log.info('Loading extensions from local file', this.registryUrl);

      const promise = Utils.loadAndParse(this.registryUrl)
        .then((registry) => {
          const reg = registry.extensions || [];
          const ref = {};
          const promises = [];

          // Reference the ui part and build the promises
          const isUnitTest = Utils.isUnitTestMode(this.vbInitConfig);
          reg.forEach((ext) => {
            ref[ext.id] = ext;
            if (isUnitTest) {
              // in unit tests if available use 'baseUrl-unitTest' for loading extensions
              promises.push(Utils.loadAndParse(`${ext['baseUrl-unitTest'] || ext.baseUrl}/manifest.json`));
            } else {
              promises.push(Utils.loadAndParse(`${ext.baseUrl}/manifest.json`));
            }
          });

          // Load all the vb-extension.json
          return Promise.all(promises).then((extensions) => {
            const manifest = {};
            if (types.includes(Constants.Digest.RUNTIME)) {
              manifest[Constants.Digest.RUNTIME] = [];
            }
            if (types.includes(Constants.Digest.APP_UI_INFO)) {
              manifest[Constants.Digest.APP_UI_INFO] = [];
            }
            if (types.includes(Constants.Digest.REQUIREJS_INFO)) {
              manifest[Constants.Digest.REQUIREJS_INFO] = [];
            }
            if (types.includes(Constants.Digest.PWA_INFO)) {
              manifest[Constants.Digest.PWA_INFO] = [];
            }

            extensions.forEach((ext) => {
              if (manifest[Constants.Digest.RUNTIME]) {
                /* eslint-disable no-param-reassign */
                const vbFiles = ext[Constants.Runtime.VB_FILES];

                if (vbFiles) {
                  ext.files = this.convertVbFiles(vbFiles);
                  delete ext[Constants.Runtime.VB_FILES];
                }

                // Insert the baseUrl part in the extension
                // in unit tests if available use 'baseUrl-unitTest' for loading extensions
                ext.baseUrl = (isUnitTest && ref[ext.id]['baseUrl-unitTest']) || ref[ext.id].baseUrl;

                /* eslint-enable no-param-reassign */
                manifest[Constants.Digest.RUNTIME].push(ext);
              }

              if (manifest[Constants.Digest.APP_UI_INFO] && ext[Constants.Digest.APP_UI_INFO]) {
                manifest[Constants.Digest.APP_UI_INFO].push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext[Constants.Digest.APP_UI_INFO].metadata,
                });
              }

              if (manifest[Constants.Digest.REQUIREJS_INFO] && ext[Constants.Digest.REQUIREJS_INFO]) {
                manifest[Constants.Digest.REQUIREJS_INFO].push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext[Constants.Digest.REQUIREJS_INFO].metadata,
                });
              }

              if (manifest[Constants.Digest.PWA_INFO] && ext[Constants.Digest.PWA_INFO]) {
                manifest[Constants.Digest.PWA_INFO].push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext[Constants.Digest.PWA_INFO].metadata,
                });
              }
            });

            return manifest;
          });
        });

      return types.map((type) => promise.then((manifest) => manifest[type]));
    }
  }

  return LocalExtensionAdapter;
});

