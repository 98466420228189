'use strict';

define('vb/extensions/dynamic/private/helpers/configurableMetadataProviderHelper',[
  'vb/extensions/dynamic/private/helpers/baseHelper',
  'vb/extensions/dynamic/private/helpers/metadataHelperFactory',
  'vb/private/stateManagement/layout',
  'vb/extensions/dynamic/private/models/layoutResource',
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/services/serviceResolverFactory',
  'vb/private/stateManagement/container',
  'vb/private/stateManagement/application',
], (
  BaseHelper,
  MetadataHelperFactory,
  Layout,
  LayoutResource,
  ConfigLoader,
  Constants,
  Log,
  Utils,
  ServiceResolverFactory,
  Container,
  Application,
) => {
  const logger = Log.getLogger('/vb/extensions/dynamic/private/helpers/configurableMetadataProviderHelper', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  const GLOBAL_RESOURCE_DESCRIPTOR = {
    resourceName: 'field-templates-overlay',
    altExtensionResourceName: 'field-templates',
    propertyMap: {
      descriptor: 'data',
      template: 'template',
      viewModel: 'dataModel',
    },
    modelClass: LayoutResource,
  };

  /**
   * The 'metadata provider helper' is an API passed to JET metadata providers, to interface with VB.
   *
   * The ConfigurableMetadataProviderHelper is configured via the 'options.helper.descriptors',
   * to provide resources to the JET metadata providers.
   *
   * This class can be used directly by constructing with options, or by using a subclass to encapsulate the
   * configuration.
   *
   * Resources are returned (to providers) from several functions:
   *
   * from getLayoutResources(): an array of objects with properties for various file contents.
   * This array contains the base (named 'main') and any extensions.
   * Each object may have one or more of the following properties:
   *
   * - name: 'main' for the base, or the extension ID for extensions.
   * - data: layout.json in string format
   * - dataModel view model for the layout
   * - template: layout.html
   * - clientMetadata: data-description-overly.json in string format
   * - clientMetadataModel: view model for data-description-overly
   * - metadataRules: metadata-rules.json in string format
   * - metadataRuleModel: view model for metadata-rules
   * - contextMetadata: context.json in string format
   *
   * Depending on the context, any/all of these files may be optional, or not applicable.
   *
   * The 'options.helper.descriptors' object describes each resource. It has the following properties:
   *
   * - resourceName: the name of the resource, e.g., layout, data-description-overlay, etc
   * - altExtensionResourceName used to indicate the extension resource has the different name than the
   *   base resource. For example, the extension resource name for data-description-overlay is
   *   data-description.
   * - modelClass: the class used to load the resource, e.g., Layout, LayoutResource or any subclass of
   *   of LayoutResource
   * - extension: extension from which resources are loaded, defaults to the current extension for this helper
   * - propertyMap: a map used to assign properties from the resource model to the resulting resource map
   *   returned to JET. For example:
   *   {
   *     descriptor: 'data, // resourceMap.data = resourceModel.descriptor
   *     viewModel: 'dataModel', // resourceMap.dataModel = resourceModel.viewModel
   *     template: 'template', // resourceMap.template = resourceModel.template
   *   }
   * - skipFunctions: if true, skip loading functions module
   * - skipTemplate: if true, skip loading html template
   * - skipExtensions: if true, skip loading -x files
   * - skipBundles: if true, skip loading translation bundles
   * - skipImports: if true, skip loading imports
   *
   * The location of these files is determined by the helper, based on layoutRoot (though it may be overridden
   * using deprecated configuration in some cases, though this should no longer happen).
   *
   */
  class ConfigurableMetadataProviderHelper extends BaseHelper {
    /**
     * @param options {object}
     * @param options.id {string}
     * @param options.helper {object}
     * @param options.helper.descriptors {object}
     * @param options.helper.descriptors.metadata {object}
     * @param options.helper.descriptors.files {Array<object>}
     * @param vbAppContext the context created by VB, and passed as 'options.context' to the components/providers
     * @param container the VB container where the dynamic component with this layout will be used
     * @returns {Promise<ConfigurableMetadataProviderHelper>}
     * a promise that resolves to a ConfigurableMetadataProviderHelper instance
     */
    static get(options, vbAppContext, container) {
      return new ConfigurableMetadataProviderHelper(options, vbAppContext, container).init();
    }

    /**
     * @param options {object}
     * @param options.id {string}
     * @param options.root {string}
     * @param options.layoutType {string}
     * @param options.helper {object}
     * @param options.helper.descriptors {object}
     * @param options.helper.descriptors.metadata
     * @param options.helper.descriptors.files
     * @param vbAppContext
     * @param container
     */
    constructor(options, vbAppContext, container) {
      super();

      this.id = options.id || ''; // should provide an ID

      this.options = options;

      // The root option is only supported for V1 extension apps for loading layouts from an alternate
      // location such as CDN.
      if (this.options.root && Utils.isHostApplication()) {
        logger.warn('Ignoring root option', this.options.root, 'which is not supported for V2 extension applications');

        delete this.options.root;
      }

      // Store the object created by this helper (like models) so we can dispose of them
      this.associatedObjects = [];

      this.context = vbAppContext;
      this.container = container;

      this.descriptors = (options.helper && options.helper.descriptors) || [];

      this.urlMapperDisabled = ConfigurableMetadataProviderHelper.getMapperDisabled();

      this.globalDescriptors = [GLOBAL_RESOURCE_DESCRIPTOR];

      this.extensionId = this.container && this.container.extensionId;
    }

    /**
     * This is an object that contains all information required by services layer to locate a service.
     */
    get vbContext() {
      return this.serviceResolver;
    }

    /**
     * Indicates whether the layout is runnable on the server. The default implementation always returns false.
     *
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    get isRunnableOnServer() {
      return false;
    }

    /**
     * return the boolean used to disable the UrlMapperClient
     * this is a bit kludgey, to preserve the current behavior of a deprecated method.
     * this checks the same configuration that the UrlMapperClient uses to see if its enabled.
     * (deliberately not making this common, since this should be specific to the UrlMapperClient).
     *
     * @returns {boolean|null}
     */
    static getMapperDisabled() {
      // @todo: JET dynamic UI providers should stop using loadExternalServiceMetadata
      const initConfig = globalThis.vbInitConfig || {};
      const swConfig = initConfig.SERVICE_WORKER_CONFIG || {};
      return swConfig && swConfig.urlMapping && swConfig.urlMapping.disabled;
    }

    /**
     * Initialize this helper.
     *
     * @returns {Promise<ConfigurableMetadataProviderHelper>}
     */
    init() {
      return Utils.getRuntimeEnvironment()
        .then((re) => {
          Object.defineProperties(this, {
            // This method is used by dynamic ui to load modules defined outside of the layout, e.g., transform
            // functions. It will adjust the path for the module if defined in a V2 extension before loading it.
            getExternalModuleResource: {
              value: (loc) => {
                logger.info('getExternalModuleResource loc', loc);

                const adjustedLoc = (typeof this.container.adjustImportPath === 'function')
                  ? this.container.adjustImportPath(loc) : loc;

                logger.info('getExternalModuleResource adjustedLoc', adjustedLoc);

                return re.getModuleResource(adjustedLoc)
                  .catch((error) => {
                    logger.error(`Failed to load ${adjustedLoc}`, error);
                    throw error;
                  });
              },
              writable: true, // set to true for mocking purpose in unit test
            },
          });

          // Initialize serviceResolver in the init so we pickup extensionId that is
          // overriden by a subclass (see ServiceMetadataProviderHelper)
          this.serviceResolver = ServiceResolverFactory.getResolver(this.extensionId);

          // determine the extension from which the layout will be loaded
          if (this.extensionId === Constants.ExtensionFolders.BASE) {
            // create an empty extension for loading layout from the base app
            this.extension = Container.createEmptyExtension();
          } else if (this.extensionId) {
            // look up the extension identified by extensionId
            const extension = Application.extensionRegistry.getExtensionById(this.extensionId);

            if (extension) {
              this.extension = extension;

              // need to explicitly call init since the extension may not have been
              // loaded as part of loading the app ui
              return extension.init();
            }
          } else {
            // otherwise, use the extension of the calling container
            this.extension = this.container && this.container.extension;
          }
          return undefined;
        })
        .then(() => this);
    }

    /**
     * Return the root path for global field templates.
     *
     * @returns {*}
     */
    getGlobalRoot() {
      if (this.globalRootPrefix == null) {
        this.globalRootPrefix = this.calcRoot();
      }
      return this.globalRootPrefix;
    }

    /**
     * Allows override of the default '../../dynamicLayouts' layout root.
     *
     * @param path
     * @returns {ConfigurableMetadataProviderHelper}
     */
    setLayoutRoot(path) {
      this.layoutRootPrefix = Utils.addTrailingSlash(path);
      return this;
    }

    /**
     * Initialize and return the layoutRootPrefix.
     *
     * @returns {String|string|*}
     */
    getLayoutRoot() {
      if (this.layoutRootPrefix == null) {
        const root = this.calcRoot();
        this.setLayoutRoot(root);
      }

      return this.layoutRootPrefix;
    }

    /**
     * Calculate root path base on configuration and options.
     *
     * @returns {string|*}
     * @private
     */
    calcRoot() {
      // get once for module, no need to re-get for each helper
      const config = (ConfigLoader.configurationDeclaration
        && ConfigLoader.configurationDeclaration.dynamicLayouts) || {};
      const helper = this.options.helper || {};

      let root = config.path;
      if (root == null) { // root can be ''
        // @ts-ignore
        root = helper.root;
        if (root == null) {
          root = this.options.root;

          if (root == null) {
            if (!this.extension || this.extension.id === Constants.ExtensionFolders.BASE) {
              root = Constants.DefaultPaths.LAYOUTS;
            } else {
              root = `${Constants.DefaultPaths.LAYOUTS}${Constants.ExtensionFolders.SELF}/`;
            }
          }
        }
      }

      return root;
    }

    /**
     * A wrapper for calcLayoutPath, that also calls 'notify' with the path value.
     *
     * @returns {Promise<String>}
     */
    getLayoutPath() {
      if (!this.pathPromise) {
        this.pathPromise = this.calcLayoutPath()
          .then((path) => {
            this.notify('path', path);
            this.notify('baseUrl', (this.extension && this.extension.baseUrl) || '');
            return path;
          });
      }
      return this.pathPromise;
    }

    /**
     * Get the path for the given operationId, remove all the parameters, and use that as the path.
     * examples: /foo/{foo_id}/bar/{bar_id} => foo/bar
     *
     * @returns {Promise<String>}
     */
    calcLayoutPath() {
      return Promise.resolve(this.getLayoutRoot());
    }

    /**
     * The API function used by the JET metadata provider to load global field templates (GFTs).
     *
     * @returns {Promise<[]>}
     */
    getGlobalResources() {
      // load GFTs from the current extension and its extensions
      return this.loadAllLayoutResources(this.getGlobalRoot(), this.globalDescriptors);
    }

    /**
     * Load shared global field templates from base extensions.
     *
     * @returns {Promise<>}
     */
    getSharedGlobalResources() {
      return Promise.resolve().then(() => {
        const { dependencies } = this.extension;
        const allResources = [];

        // load GFTs from base extensions
        if (dependencies) {
          const promises = [];

          Object.keys(dependencies).forEach((extensionId) => {
            const extension = Application.extensionRegistry.getExtensionById(extensionId);

            // create resource descriptors for the base extension
            const descriptors = [
              Object.assign({}, GLOBAL_RESOURCE_DESCRIPTOR,
                {
                  extension,
                  skipExtensions: true, // don't need to load -x files for the shared GFT
                }),
            ];

            // load the shared GFT
            promises.push(this.loadLayoutResources(this.getGlobalRoot(), descriptors)
              .then((resources) => {
                // change the namespace from main to the base extension id
                // eslint-disable-next-line no-param-reassign
                resources.name = extensionId;

                allResources.push(resources);
              }));
          });

          return Promise.all(promises).then(() => allResources);
        }

        return allResources;
      });
    }

    /**
     * The API function used by the JET metadata provider to load layout related resources.
     *
     * @returns {Promise<object>}
     */
    getLayoutResources() {
      return this.getLayoutPath()
        .then((pathPrefix) => this.loadAllLayoutResources(pathPrefix, this.descriptors));
    }

    /**
     * Load layout resources from the base container and it's extensions.
     *
     * @param resourceLoc resource path
     * @param descriptors resource descriptors
     * @returns {Promise<Object>}
     */
    loadAllLayoutResources(resourceLoc, descriptors) {
      return this.loadLayoutResources(resourceLoc, descriptors)
        .then((mainResourceMap) => this.loadLayoutResourceExtensions(descriptors)
          .then((resourceMaps) => {
            const allResources = [mainResourceMap, ...Object.values(resourceMaps)];
            return allResources;
          }));
    }

    /**
     * Load layout resource from the base container.
     *
     * @param resourceLoc resource path
     * @param descriptors resource descriptors
     * @returns {Promise<Object>}
     */
    loadLayoutResources(resourceLoc, descriptors) {
      return Promise.resolve().then(() => {
        if (resourceLoc == null) {
          throw new Error(`No path for dynamic UI resources, ${this.id}`);
        }

        // the base resouces is identified by main
        const mainResourceMap = {};

        const promises = [];

        descriptors.forEach((desc) => {
          const { modelClass } = desc;
          const model = this.createModel(modelClass, resourceLoc, desc);

          if (model) {
            // remember the model so we can use it to extract resources from the extensions
            // eslint-disable-next-line no-param-reassign
            desc.model = model;

            mainResourceMap.name = model.layoutNamespace;

            promises.push(ConfigurableMetadataProviderHelper.extractResourcesFromModel(
              model, desc, mainResourceMap,
            ));
          }
        });

        return Promise.all(promises).then(() => mainResourceMap);
      });
    }

    /**
     * Load layout resources from all extensions.
     *
     * @param descriptors resource descriptors
     * @returns {Promise<Object>}
     */
    // eslint-disable-next-line class-methods-use-this
    loadLayoutResourceExtensions(descriptors) {
      return Promise.resolve().then(() => {
        const resourceMaps = {};
        const promises = [];

        descriptors.forEach((desc) => {
          const { model } = desc;

          model.extensionsArray.forEach((extension) => {
            promises.push(this.loadLayoutResourceExtension(extension, desc, resourceMaps));
          });
        });

        return Promise.all(promises).then(() => resourceMaps);
      });
    }

    /**
     * Load layout resources from the given layout resource extension.
     *
     * @param layoutResourceExtension extension from which to load resources
     * @param descriptor resource descriptor
     * @param resourceMaps result resource map
     * @returns {Promise}
     */
    loadLayoutResourceExtension(layoutResourceExtension, descriptor, resourceMaps) {
      return Promise.resolve().then(() => {
        const promises = [];
        const { layoutNamespace } = layoutResourceExtension;

        // get or create a resource map for the namepace
        let resourceMap = resourceMaps[layoutNamespace];
        if (!resourceMap) {
          resourceMap = {
            name: layoutNamespace,
          };
          // eslint-disable-next-line no-param-reassign
          resourceMaps[layoutNamespace] = resourceMap;
        }

        promises.push(ConfigurableMetadataProviderHelper.extractResourcesFromModel(
          layoutResourceExtension, descriptor, resourceMap,
        ));

        // recursively load dependent extensions
        layoutResourceExtension.extensionsArray.forEach((depExtension) => {
          promises.push(this.loadLayoutResourceExtension(depExtension, descriptor, resourceMaps));
        });

        return Promise.all(promises);
      });
    }

    /**
     * Extract resources from the given model and assign them to resourceMap using descriptor.propertyMap.
     *
     * @param model resource model
     * @param descriptor resource descriptor
     * @param resourceMap resource map containing the extracted resources
     * @returns {Promise}
     */
    static extractResourcesFromModel(model, descriptor, resourceMap) {
      return Promise.resolve().then(() => {
        const { propertyMap } = descriptor;

        // getViewModel triggers loading of all the artifacts
        return model.getViewModel().then(() => {
          Object.entries(propertyMap).forEach(([sourceKey, targetKey]) => {
            // eslint-disable-next-line no-param-reassign
            resourceMap[targetKey] = model[sourceKey];
          });
        });
      });
    }

    /**
     * Create an instance of the given modelClass.
     *
     * @param modelClass class to instantiate
     * @param resourceLoc resource path
     * @param descriptor resource descriptor
     * @returns {Layout|LayoutResource}
     * @private
     */
    createModel(modelClass, resourceLoc, descriptor) {
      try {
        if (!modelClass) {
          return null;
        }

        let model;

        // create Layout
        if (modelClass === Layout) {
          model = new Layout(this.id, this.container, this.extension, resourceLoc);
        } else {
          const Clazz = modelClass;
          model = new Clazz(this.container, descriptor.extension || this.extension, resourceLoc, descriptor);
        }

        // Keep of reference to the model so can dispose of them properly
        this.associatedObjects.push(model);

        return model;
      } catch (err) {
        logger.warn('Unable to create model, continuing', modelClass, err);
        return null;
      }
    }

    /**
     * used by JET metadata provider;
     * resolves with the metadata (openapi3 or data-description.json), and a model, if there is one.
     *
     * if there is no 'data' (metadata), there will be no 'dataModel'
     *
     * @returns {Promise<Object>}
     */
    // eslint-disable-next-line class-methods-use-this
    getMetadata() {
      return Promise.resolve({});
    }

    /**
     * Create a new helper instance for the given options.
     *
     * @param options options for the new helper
     * @returns {Promise}
     */
    getHelper(options) {
      // options, vbContext, container) {
      return MetadataHelperFactory.createHelper(options, this.container, this.id);
    }

    dispose() {
      // Cleanup all associated objects
      this.associatedObjects.forEach((model) => model.dispose());
      this.associatedObjects = [];

      this.container = null;
      this.options = null;
      this.descriptors = null;
      this.context = null;
      this.serviceResolver = null;

      super.dispose();
    }
  }

  return ConfigurableMetadataProviderHelper;
});

