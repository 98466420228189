'use strict';

define('vb/private/vx/registryConfig',[
  'vbc/private/utils',
  'vb/private/vx/endpointExtensionAdapter',
  'vb/private/vx/remoteExtensionAdapter',
], (Utils, EndpointExtensionAdapter, RemoteExtensionAdapter) => {
  /**
   * A config object with the class name for the extension adapter to use
   * as well as the registry URL and the extension manager
   */
  class RegistryConfig {
    constructor(vbInitConfig) {
      this.localAdapter = null;
      this.extensionRegistryModule = null;
      this.digestLoader = null;
      this.fetchManifestPromise = null;

      // Preference is given to the DIGEST_ENDPOINT property set when using SUIS
      this.url = Utils.cleanString(vbInitConfig.DIGEST_ENDPOINT);

      if (this.url) {
        this.digestLoader = new EndpointExtensionAdapter(this.url);
      }

      // If it's not using DIGEST_ENDPOINT, it might be using REGISTRY_URL
      if (!this.url) {
        this.url = Utils.cleanString(vbInitConfig.REGISTRY_URL);

        if (this.url) {
          if (this.url.indexOf('http') === 0) {
            this.digestLoader = new RemoteExtensionAdapter(this.url);
          } else {
            this.localAdapter = 'vb/private/vx/localExtensionAdapter';
          }
        }
      }

      // For DIGEST_ENDPOINT and REGISTRY_URL case, we can initiate the fetch immediately
      if (this.digestLoader) {
        // Only store the promise, don't block on it.
        this.fetchManifestPromise = this.digestLoader.initiateFetchManifest();
      } else if (!this.localAdapter) {
        // This is the case of an application without extension
        this.extensionRegistryModule = 'vb/private/vx/baseExtensionRegistry';
        return;
      }

      /**
       * Return the module name of the extension registry to use with this application
       * Host application use the v2 registry module
       * @type {String}
       */
      this.extensionRegistryModule = 'vb/private/vx/extensionRegistry';
    }

    async init() {
      // Temporary support for local adapter
      if (!this.digestLoader && this.url && this.localAdapter) {
        const Adapter = await Utils.getResource(this.localAdapter);
        this.digestLoader = new Adapter(this.url);
        // Only store the promise, don't block on it.
        this.fetchManifestPromise = this.digestLoader.initiateFetchManifest();
      }
    }

    /**
     * @return {Promise<ExtensionRegistry>}
     */
    async createExtensionRegistry() {
      const ExtensionRegistry = await Utils.getResource(this.extensionRegistryModule);
      const extensionRegistry = new ExtensionRegistry();
      return extensionRegistry.initialize(this);
    }

    hasRegistry() {
      return !!this.digestLoader;
    }
  }

  return RegistryConfig;
});

