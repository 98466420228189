'use strict';

define('vb/private/services/serviceUtils',[
  'urijs/URI',
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/services/serviceConstants',
  'vb/private/services/swaggerUtils',
  'vb/private/services/trapData',
], (
  URI,
  ConfigLoader,
  Constants,
  Log,
  Utils,
  ServiceConstants,
  SwaggerUtils,
  TrapData,
) => {
  const HAS_SCHEME_REGEX = /^[A-Za-z][A-Za-z0-9+.-]*:.+/;

  const getTrapData = (vbInitConfig, options) => TrapData.getTrapData({ vbInitConfig, options });

  const logger = Log.getLogger('/vb/private/services/serviceUtils');

  /**
   * Map of serviceType => EndpointResolverDef set on globalThis.vbInitConfig.SERVICE_ENDPOINT_RESOLVERS.
   * EndpointResolverDef.path - module name of the EndpointResolver
   */
  const ENDPOINT_RESOLVER_DEFS = Symbol('initConfigEndpointResolverDefs');

  const getEndpointResolverDefs = (vbInitConfig) => {
    const serviceEndpointResolvers = vbInitConfig.SERVICE_ENDPOINT_RESOLVERS
      || (vbInitConfig.SERVICE_ENDPOINT_RESOLVERS = {}); // eslint-disable-line no-param-reassign

    if (!serviceEndpointResolvers[ENDPOINT_RESOLVER_DEFS]) {
      const endpointResolverDefs = {};
      Object.keys(serviceEndpointResolvers).forEach((serviceType) => {
        let pluginDef;
        const plugin = serviceEndpointResolvers[serviceType];
        if (typeof plugin === 'string') {
          pluginDef = { path: plugin };
        } else if (plugin && plugin.path) {
          pluginDef = plugin;
        }

        if (pluginDef) {
          endpointResolverDefs[serviceType] = pluginDef;
        } else {
          logger.warn('Endpoint Resolver Plugin for ', serviceType,
            ' is not configured correctly: "', plugin, '".');
        }
      });
      serviceEndpointResolvers[ENDPOINT_RESOLVER_DEFS] = endpointResolverDefs;
    }
    return serviceEndpointResolvers[ENDPOINT_RESOLVER_DEFS];
  };

  /**
   *
   */
  class ServiceUtils {
    /**
     * Gets the scope value for the Trap service.
     * It defaults to "urn:opc:resource:fusion:<FA pod name>:rwdinfra", but the pattern can be
     * overriden via TRAP_SERVICE.SERVICE_SCOPE value in the vbInitConfig.
     *
     * @param {Object} vbInitConfig
     * @returns {string}
     */
    static getTrapServiceScope(vbInitConfig = globalThis.vbInitConfig || {}) {
      return getTrapData(vbInitConfig).getTrapServiceScope();
    }

    /**
     * Gets the authentication type for the Trap service.
     * It defaults to "oauth2_user_assertion", but the value can be
     * overriden via TRAP_SERVICE.AUTH_TYPE value in the vbInitConfig.
     *
     * @param {Object} vbInitConfig
     * @returns {string}
     * @private
     */
    static getTrapServiceAuthType(vbInitConfig = globalThis.vbInitConfig || {}) {
      return getTrapData(vbInitConfig).getTrapServiceAuthType();
    }

    /**
     * Gets the URL for proxy endpoint for the given service name.
     *
     * @param {Object} options
     * @param {string} options.serviceName
     * @param {string} [options.activeProfile = null] Active profile if available
     */
    static getProxyUrl(options) {
      return getTrapData(options.vbInitConfig, options).getProxyUrl(options);
    }

    /**
     * Gets the URL for token relay endpoint for the given service name.
     *
     * @param {Object} options
     * @param {string} options.serviceName
     * @param {string} [options.activeProfile=null] Active profile if available
     */
    static getTokenRelayUrl(options) {
      return getTrapData(options.vbInitConfig, options).getTokenRelayUrl(options);
    }

    /**
     * https://confluence.oraclecorp.com/confluence/pages/viewpage.action?pageId=936840736
     * https://jira.oraclecorp.com/jira/browse/BUFP-26454
     * https://jira.oraclecorp.com/jira/browse/BUFP-27537
     */
    /**
     * Augment the extension with information the proxy url and token relay url for the service.
     *
     * For the name used in the proxy, use the first "services" name from the catalog, if any.
     * Otherwise, use the normal service name (from the app-flow key).
     *
     * @param name name of the service; this must be the name used for the PROXY url.
     * @param {Object} catalogInfo Object created while traversing catalogs during the service resolution
     * @param {Object} catalogInfo.namespace
     * @param {Object} catalogInfo.version
     * @param {Object} [catalogInfo.chain] array from additional info from resolving a vb-catalog:// path, if any
     * @param extensions extension to augment
     * @param {boolean} [skipTrapExtensions] skip TRAP metadata from the extension
     * @private
     */
    static augmentExtension(name, catalogInfo, extensions, skipTrapExtensions) {
      if (extensions) {
        // if the catalog was used, use the first service name for the proxy; otherwise, use the normal service name
        const firstInChain = (catalogInfo.chain && catalogInfo.chain[0]) || {};
        const nameForUrl = (firstInChain.type === 'services' && firstInChain.name) || name;

        const ext = Utils.cloneObject(extensions);

        ext.serviceName = nameForUrl;

        if (!skipTrapExtensions) {
          // Since we already have serviceName we can fully resolve URLs here and not just pass baseUrl(s)
          // we can not use "proxyUrl" for property name as it is used for legacy support
          const urlOptions = {
            serviceName: nameForUrl,
            extensionId: catalogInfo.namespace,
            extensionVersion: catalogInfo.version,
            activeProfile: ConfigLoader.activeProfile,
            defaultTrap: extensions.defaultTrap,
          };

          Object.assign(ext, getTrapData(undefined, urlOptions).getExtensions(urlOptions));
        }

        return ext;
      }
      return extensions;
    }

    /**
     * this was originally in endpoint.js
     *
     * first, check if the URL is 'http', and if so, switch to https, because we use the actual url to make the fetch,
     * and it has to bee https when the app is served from https. We put the original protocol in a special header.
     * When we construct the proxy in the fetch plugins, we look for the special header, and use that protocol instead.
     *
     * next, look to see if the currentPage needs auth, and add another header flag if so.
     *
     * @todo: this should be adding this information in the special 'vb-info-extension' header
     * to keep ALL information passed to the preprocessor in ONE header.
     *
     * note that we need the auth for the current page, but we do not want static dependency on Router in this file
     *
     * @param headers
     * @param url
     * @param isAnonymousAllowed Router.getCurrentPage() && !Router.getCurrentPage().isAuthenticationRequired()
     * @returns {string} undefined or a new URL if a protocol override is needed
     */
    static getHeadersAndUrlForPreprocessing(headers, url, isAnonymousAllowed) {
      const hdrs = headers;
      let newUrl;

      let added = false;

      if (ServiceUtils.isProtocolOverrideRequired(url)) {
        const uri = new URI(url);
        uri.protocol('https');
        hdrs[Constants.Headers.PROTOCOL_OVERRIDE_HEADER] = 'http';
        newUrl = uri.toString();
        added = true;
      }

      if (isAnonymousAllowed) {
        hdrs[Constants.Headers.ALLOW_ANONYMOUS_ACCESS_HEADER] = true;
        added = true;
      }

      // if we already created the 'vb-info-extension' header, update it with the new headers
      const infoExtension = added && headers[Constants.Headers.VB_INFO_EXTENSION];
      if (infoExtension) {
        const extensions = SwaggerUtils.parseServiceText(infoExtension);
        extensions.headers = Object.assign({}, hdrs);
        // eslint-disable-next-line no-param-reassign
        headers[Constants.Headers.VB_INFO_EXTENSION] = JSON.stringify(extensions);
      }

      return newUrl;
    }

    /**
     * used to be in endpoint.js
     *
     * @returns {boolean} True if the protocol of the window is http but the requested url is http
     */
    static isProtocolOverrideRequired(url) {
      return globalThis.location.protocol.indexOf('https') === 0 && url.indexOf('http:') === 0;
    }

    /**
     * note, this is different than DefinitionObject._mergeExtensions;
     * here, everything in extensionB takes precedence over extensionsA
     *
     * @param extensionA
     * @param extensionB
     * @returns {*}
     * @private
     */
    static mergeExtensions(extensionA, extensionB) {
      const extA = extensionA || {};
      const extB = extensionB || {};

      // headers are merged
      const headers = Object.assign({}, extA.headers || {}, extB.headers || {});

      // authentication is overridden
      const authentication = Object.assign({}, extB.authentication || extA.authentication || {});

      // everything else is overriden
      const merged = Object.assign({}, extA, extB, { headers });

      // don't add an empty 'authorization' object, just to keep it clean-ish, and keep the 'clutter' low
      if (Object.keys(authentication).length) {
        merged[ServiceConstants.AUTH_DECL_NAME] = authentication;
      }
      return merged;
    }

    /**
     * "metadata" in this context refers to the use of an "openapi" fragment in a catalog.json "services" object.
     *
     * used when catalog.json uses the "paths" syntax for referencing a service definition;
     * (this is NOT used for normal data fetch; only live swagger/openapi3).
     *
     * @param serverUrl
     * @param metadata created by CatalogHandler.createOpenApiMetadata
     * @returns {string}
     * @private
     */
    static getServiceUrlFromMetadata(serverUrl, metadata) {
      const uri = new URI(serverUrl).segment(metadata.path || '');

      // the 'metadata' is populated by the new openapi3 syntax in catalog, if the URL has a query in "paths/get".
      // note that the serverUrl may also have a query.
      if (metadata.query) {
        if (uri.query()) {
          uri.addQuery(URI.parseQuery(metadata.query));
        } else {
          uri.query(metadata.query);
        }
      }

      const url = uri.normalize().toString();
      return url;
    }

    /**
     * "metadata" in this context refers to the use of an "openapi" fragment in a catalog.json "services" object.
     *
     * used when catalog.json uses the "paths" syntax for referencing a service definition;
     * (this is NOT used for normal data fetch; only live swagger/openapi3).
     *
     * the object returned from here may also me used as an "init" object for a Request/fetch, since it contains the
     * headers for the metadata.
     *
     * @param serverUrl
     * @param metadata created by CatalogHandler.createOpenApiMetadata
     * @param requestInit for Request/fetch API.  typically contains "headers" object (and other unused values).
     * @returns {{headers: *, method: *, url: *, transforms: { path: {string} }}}
     * @private
     */
    static getExtensionsFromMetadata(serverUrl, metadata, requestInit) {
      const url = ServiceUtils.getServiceUrlFromMetadata(serverUrl, metadata);

      const method = metadata.method.toUpperCase();
      const metadataExtensions = metadata.extensions || {};
      const initHeaders = requestInit.headers || {};
      // eslint-disable-next-line max-len
      const serverExtensions = SwaggerUtils.parseServiceText(initHeaders[Constants.Headers.VB_INFO_EXTENSION] || '{}');

      const mergedHeaders = Object.assign({}, requestInit.headers || {}, metadataExtensions.headers || {});
      delete mergedHeaders[Constants.Headers.VB_INFO_EXTENSION];

      // extensions in the "paths" override conflicts with "server" extensions
      // note: we keep url & method here, so this can be used as an init
      const mergedExtensions = Object
        .assign({ url, method }, serverExtensions, metadataExtensions, { headers: mergedHeaders });

      mergedExtensions.headers = mergedHeaders;

      // create an cleaner object for the 'vb-info-extension' header
      const extForHeader = Object.assign({}, mergedExtensions);
      delete extForHeader.url;
      delete extForHeader.method;

      // set a new, combined, vb-info-extension headers
      mergedHeaders[Constants.Headers.VB_INFO_EXTENSION] = JSON.stringify(extForHeader);

      return mergedExtensions;
    }

    /**
     * @typedef {Object} TransformedResolvedCatalogObject
     * @property {*} url
     * @property {string} namespace
     * @property {string} version
     * @property {Object} services
     * @property {Object} services.extensions
     * @property {Object} services.extensions.headers
     * @property {*} services.metadata
     * @property {Object} backends
     * @property {Object|*} backends.extensions
     * @property {*} metadata
     * @property {[]|*} chain
     * @property {*} mergedExtensions
     */
    /**
     * @param resolved
     * @param name
     * @param url
     * @param existingServiceDefHeaders
     * @returns {TransformedResolvedCatalogObject}
     */
    static transformResolvedCatalogObject(resolved, name, url, existingServiceDefHeaders, skipTrapExtensions) {
      // convert the 'resolved' object to something a little easier for the runtime to consume right now
      // reach into the metadata & headers, and separate the top-level by services & backends
      // BEFORE: metadata: {services: {}}, headers: { services: {}, backends: {} }...
      // AFTER: services: { metadata: {}, headers: {} }, backends: { headers: {} }

      // get the 'services' header
      const extensions = resolved.extensions || {};

      // merge the 'backends' extensions _into_ the 'services' extensions
      // the catalogHandler/protocolRegistry keeps the 'services' and 'backends' data separate;
      // its up to the caller to decide how to interpret those in relation to each other.
      // VB considers any referenced 'backends' extension info to be part of the 'services' that references it,
      // so merge the backends into the services, giving the services conflicts priority.
      // This means, a services will inherit references backends' auth block, headers, etc.
      let mergedExtensions = ServiceUtils
        .mergeExtensions(extensions.backends || {}, extensions.services || {});

      const headers = mergedExtensions.headers || {};
      // similiar to endpoint.js, we need to communicate some 'x-vb' information to the service worker plugins
      // BUFP-25539: need to pass proxyUrls to the plugins, if defined in the catalog 'services' object
      // so the fetch of the service definition goes through the proxy

      const serviceNameForProxy = ServiceUtils.getNameForProxy(name, url, resolved.chain);

      // @todo: is the chain right?
      mergedExtensions = ServiceUtils
        .augmentExtension(serviceNameForProxy, resolved, mergedExtensions, skipTrapExtensions);

      headers[Constants.Headers.VB_INFO_EXTENSION] = JSON.stringify(mergedExtensions);

      // any catalog 'headers' are merged with existing (declared) headers (declared ones take precedence).
      const mergedHeaders = Object.assign({}, headers || {}, existingServiceDefHeaders || {});

      const metadata = resolved.metadata || {};

      return {
        url: resolved.url,
        namespace: resolved.namespace, // 'base' or <ext ID>
        version: resolved.version,
        services: {
          extensions: {
            headers: mergedHeaders, // only 'headers' are used for service def loads
          },
          metadata: metadata.services,
        },
        backends: {
          extensions: extensions.backends || {},
          // no backend metadata currently; metadata: metadata.backends,
        },
        metadata: resolved.metadata,
        chain: resolved.chain || [],
        mergedExtensions, // @todo: better name? adding this only for mappper
      };
    }

    /**
     * We need to make sure that we register catalog names from all of the services delegates. If we don't do this,
     * their backends are only "activated" if at least one of their services was used before
     * (if the backend is not "activated", it's not visible to other extensions).
     * Without this server of a Service from this extension may not be resolvable if it references backend
     * from a delegate.
     *
     * @param {Services} services
     */
    static getAndRegisterAllCatalogNames(services) {
      // calls services._serviceDefFactories['catalogServices'].getAndRegisterCatalogNames()
      const callArray = ['_serviceDefFactories', 'catalogServices', 'getAndRegisterCatalogNames'];
      return Promise.resolve()
        .then(() => services.searchDelegates(
          (delegate) => Promise.resolve().then(() => Utils.safelyCall(delegate, ...callArray)).then(() => false),
        ))
        .then(() => Utils.safelyCall(services, ...callArray));
    }

    /**
     * @param {string} value
     * @return {boolean} true if value starts with a valid URI scheme
     */
    static hasScheme(value) {
      return HAS_SCHEME_REGEX.test(value);
    }

    /**
     * Returns an absolute URL by resolving 'url' against 'baseUrl' if necessary, i.e. if url is a relative URL.
     * Returns 'url' in case of errors.
     *
     * @param {string} baseUrl - the base URL
     * @param {string} url - the URL to be turned into an absolute URL
     * @return {string} an absolute URL or 'url' in case of errors
     */
    static toAbsoluteServerUrl(baseUrl, url) {
      // ensures that the resolution only happens if 'baseUrl' is absolute and 'url' is relative
      return ServiceUtils.hasScheme(baseUrl) && !ServiceUtils.hasScheme(url) ? new URI(url, baseUrl).href() : url;
    }

    /**
     * Resolve the path of the service resource (an openapi document or a transforms module)
     *
     * @param {string} resourcePath Path of the resource to load
     * @param {string} contextPath Relative path of the loading context, e.g. 'vx/extA'
     * @param {boolean} isTextResource true if the resource is a text file, i.e. should use 'text!' prefix
     * @returns {string}
     */
    static resolveResourcePath(resourcePath, contextPath, isTextResource) {
      // TODO: HAD TO special-case paths that start with this prefix
      // VB builtin modules start with 'vb/', and application resources start with 'vx/'
      if (resourcePath.startsWith('vb/') || resourcePath.startsWith('vx/')) {
        return resourcePath;
      }

      // here, 'absolute' means 'protocol and host' or it starts with "/".
      // Paths starting with '/' are on the same server but RequireJs does not use baseUrl when loading it,
      // nor it can apply path mapping or packaging.
      // here, 'absolute' means 'protocol and host' or it starts with "/".
      //
      // In case of service definition paths:
      // for example, the flow's path will be prefixed to one, two, and three below. And NOT to four, five.
      // also note that for a flow other than app-flow, "three" would have been rejected earlier.
      // All but "four" and "five" are relative.
      // "services": {
      //    "one":  "x/y/z/service.json",
      //    "two": "./x/y/z/service.json",
      //    "three": "../../x/y/z/service.json",
      //    "four": "/x/y/z/service.json",
      //    "five": "https://x/y/z/service.json",
      //  }
      if (Utils.isAbsolutePath(resourcePath)) {
        return resourcePath;
      }

      // first, figure out what it will add as the baseUrl
      const baseUrl = requirejs.toUrl('.');
      const resourceUrl = requirejs.toUrl(resourcePath);
      // use basic logic for resource path resolution
      // append it to the base, and normalize the URI (remove any "." or "..")
      const ourResourceUrl = (new URI(`${baseUrl}${resourcePath}`)).normalizePath().toString();
      if (resourceUrl !== ourResourceUrl
        || ConfigLoader.isResourceBundled(isTextResource ? `text!${resourcePath}` : resourcePath)) {
        // We can not use resolved requirejs URL for fetching the definition because it may be the path of
        // the bundle containing openapi3.json. Instead pass it as-is and let ServiceLoader
        // use requirejs to load the openapi3.json.
        return resourcePath;
      }

      return `${contextPath}${resourcePath}`;
    }

    static getModuleUri(pathJs) {
      // need to remove the '.js' extension, or requireJS will not do any possible mapping
      let path = pathJs;

      if (pathJs) {
        const useJsExt = Utils.isAbsolutePath(pathJs);
        const hasJsExt = pathJs.endsWith('.js');

        if (useJsExt && !hasJsExt) {
          path = `${pathJs}.js`;
        } else if (!useJsExt && hasJsExt) {
          path = pathJs.substring(0, pathJs.length - 3);
        }
      }

      if (path) {
        // normalize the path, e.g., somePath/./someTransform -> somePath/someTransform,
        // so it doesn't throw off the require mapping that maps the path to a js file
        const uri = new URI(path);
        uri.normalizePath();

        return uri;
      }

      return null;
    }

    static async loadPluginModule(pathJs) {
      const uri = ServiceUtils.getModuleUri(pathJs);
      if (uri) {
        const rtEnv = await Utils.getRuntimeEnvironment();
        return rtEnv.getEndpointPlugin(uri.toString());
      }
      throw new Error(`Can not resolve URI for the endpoint plugin path: "${pathJs}".`);
    }

    /**
     *
     * @param name the 'name' of the service, from the declaration key
     * @param path file path, could be absolute or relative, and could use a custom protocol (vb-catalog).
     * @param chain the trail derived from custom protocol resolution (aka 'vector through the catalog.json')
     * @returns {*|boolean}
     */
    static getNameForProxy(name, path, chain) {
      const firstInChain = chain && chain[0];

      // first, use the name of any referenced "services" object from the catalog (if any)
      // "services": { "wrongname": { "path": "vb-catalog://services/rightname/blah/blah" } }
      // the 'chain keeps track of how we followed custom protocol indirections ("vb-catalog")
      // vb-catalog://services/foo -> vb-catalog://backends/a -> vb-catalog://backends/b, etc.
      let proxyName = (firstInChain && firstInChain.type === 'services' && firstInChain.name);

      // next, look at the file path, if the catalog was not used (no chain) and the path is relative
      // example: "./services/myname/openapi3.json". must have a /services/ followed by another folder name.
      if (!proxyName && (!chain || !chain.length) && !Utils.isAbsolutePath(path)) {
        const parts = path.split('/');
        const idx = parts.indexOf('services');
        if (idx >= 0 && idx === parts.length - 3) {
          proxyName = parts[idx + 1];
        }
      }

      return proxyName || name;
    }

    /**
     * Register name of the JS module that can resolve endpoint references for the given serviceType.
     *
     * @param {string} serviceType Name of the serviceType the endpoint resolver is registered for.
     * @param {string} endpointResolverModule Name of the JS module with Endpoint Resolver Plugin implementation.
     * @param {Object} [vbInitConfig = globalThis.vbInitConfig || {}] vbInitConfig object.
     *              It should only be specified in tests. At RT the method will use value set on the window variable.
     */
    static addEndpointResolverModule(serviceType, endpointResolverModule, vbInitConfig = (globalThis.vbInitConfig || {})) {
      const serviceTypeEndpointResolverDefs = getEndpointResolverDefs(vbInitConfig);
      const existingModule = serviceTypeEndpointResolverDefs[serviceType];
      if (existingModule) {
        logger.warn('endpoint resolver already registered for', serviceType,
          '. Ignoring "', endpointResolverModule, '" plugin.');
      } else {
        serviceTypeEndpointResolverDefs[serviceType] = {
          path: endpointResolverModule,
        };
      }
    }

    /**
     * Get an endpoint resolver plugin definition for the service type if it had been regestered.
     *
     * @param {string} serviceType  Name of the service type the endpoint resolver is registered for.
     * @param {Object} [vbInitConfig = globalThis.vbInitConfig || {}] vbInitConfig object.
     *              It should only be specified in tests. At RT the method will use value set on the window variable.
     * @returns {Object} Endpoint resolver definition object. Currently it only has "path" property.
     */
    static getServiceTypeEndpointResolverDef(serviceType, vbInitConfig = (globalThis.vbInitConfig || {})) {
      return getEndpointResolverDefs(vbInitConfig)[serviceType];
    }

    /**
     * Returns a simple map-like object, that enforces key = ID + namespace.
     * getAll() function returns all values with the given ID + namespaces, regadless of any extra varibales
     *
     * @returns {{
     *  get: (function(string, string): *),
     *  getAll: (function(string, string): *),
     *  set: (function(string, string, *): void),
     *  setWithVariables: (function(string, string, string, *): void),
     *  has: (function(string, string): boolean),
     *  hasWithVariables: (function(string, string, string): boolean),
     *  getWithVariables: (function(string, string, string): boolean),
     *  getKeys: (function(): string[]),
     *  getValues: (function(): *[]),
     *  delete: (function( function(string, string) : boolean ): void)
     * }}
     */
    static createNamespaceMap(defaultNamespace = Constants.ExtensionNamespaces.BASE) {
      return Utils.createNamespaceMap(defaultNamespace);
    }
  }

  return ServiceUtils;
});

