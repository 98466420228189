'use strict';

define('vb/extensions/dynamic/private/types/bridges/serviceMetadataProviderFactoryBridge',[
  'vb/extensions/dynamic/private/types/bridges/helperMetadataProviderFactoryBridge',
  'vb/private/utils',
  'vb/private/services/endpointReferenceFactory',
], (HelperMetadataProviderFactoryBridge, Utils, EndpointReferenceFactory) => {
  /**
   * a 'bridge' for ServiceMetadataProviderDescriptor
   * - specifies the JET metadata provider factory class
   * - converts the VB declaration into options JET provider understands.
   * - configures the MetadataProviderHelper passed to the JET provider.
   */
  class ServiceMetadataProviderFactoryBridge extends HelperMetadataProviderFactoryBridge {
    /**
     *
     * @param options
     * @returns {*}
     */
    translateOptions(options) {
      const newOptions = options;

      // since we are letting EndpointPlugins to split the endpoint ID in custom ways we can not simply do
      // >> const endpointParts = options.endpoint.split(Constants.PATH_SEPARATOR);
      // DynamicUi will use the operationId we pass in, to later search for the endpoint metadata within
      // the OpenApi document constructed by EndpointPlugin on the fly.
      // The two have to be in sync for the Dynamic UI's lookup to work.

      return Promise.resolve()
        .then(() => newOptions.endpoint
          && EndpointReferenceFactory.getResolvedReference(newOptions.endpoint, newOptions.container))
        .then((endpointReference) => {
          if (endpointReference) {
            newOptions.service = endpointReference.qualifiedServiceId || newOptions.service;
            newOptions.operationId = endpointReference.operationId || newOptions.operationId;
            if (!newOptions.service || !newOptions.operationId) {
              throw new Error(`Invalid service configuration: ${newOptions.service}/${newOptions.operationId}`);
            }
          }
          // call last, because this creates the helper, and we want our new options, from above, to be available.
          return super.translateOptions(options);
        });
    }

    /**
     * @returns {string}
     */
    // eslint-disable-next-line class-methods-use-this
    getDelegatePath() {
      return 'oj-dynamic/providers/ServiceMetadataProviderFactory';
    }

    /**
     *
     * @param options
     * @param options.context
     * @param options.container
     * @returns {Promise<T>}
     *
     * @override
     */
    getHelper(options) {
      return Promise.resolve()
        .then(() => {
          if (options.service && options.operationId) {
            return Utils.getResource('vb/extensions/dynamic/private/helpers/serviceMetadataProviderHelper')
              .then((HelperClass) => HelperClass.get(options, options.context, options.container));
          }

          // delegate to the parent class, the 'options' may specify the helper class to use
          return super.getHelper(options, options.context, options.container);
        });
    }
  }

  return ServiceMetadataProviderFactoryBridge;
});

