'use strict';

define('vb/private/services/definition/endpointPathNode',[], () => {
  /**
   * EndpointPathNode models a node in the tree created for modelling all endpoint paths in a service.
   * It provides API for searching for and adding new endpoints based on the paths.
   */
  class EndpointPathNode {
    constructor(segment = '') {
      this.segment = segment;
      this.isTemplate = segment.indexOf('{') !== -1;
      this.endpoint = null;

      // for static path segments we use simple map of path segment to a Node
      // Map<string, EndpointPathNode>
      this.subPaths = {};
      // for templated path segments we keep a list of Nodes
      // [EndpointPathNode]
      this.templatedSubPaths = [];
    }

    /**
     * Checks if this node matches the given path segment. If this node represent a
     * templated segment it matches all segments.
     *
     * @param {string} segment segment of the path
     * @returns {boolean}
     */
    matches(segment) {
      // we can be more fancy matching templates, handling cases when just part of a path
      // segment is templated. Keep it simple for now.
      return this.isTemplate || (segment === this.segment);
    }

    /**
     * Adds new endpoint for the part of the path to the node.
     * The new endpoint subtree path is: "pathSegments[segIndex]/pathSegments[segIndex+1]/...".
     *
     * @param {any} endpoint
     * @param {string[]} pathSegments
     * @param {number} [segIndex=1]
     */
    addEndpoint(endpoint, pathSegments, segIndex = 1) {
      const currentSegment = pathSegments[segIndex];
      const isTemplate = currentSegment.indexOf('{') !== -1;

      let subPathNode;
      if (!isTemplate) {
        subPathNode = this.subPaths[currentSegment];
        if (!subPathNode) {
          subPathNode = new EndpointPathNode(currentSegment);
          this.subPaths[currentSegment] = subPathNode;
        }
      } else {
        subPathNode = this.templatedSubPaths.find((node) => node.matches(currentSegment));
        if (!subPathNode) {
          subPathNode = new EndpointPathNode(currentSegment);
          this.templatedSubPaths.push(subPathNode);
        }
      }

      if (segIndex === pathSegments.length - 1) {
        subPathNode.endpoint = endpoint;
      } else {
        subPathNode.addEndpoint(endpoint, pathSegments, segIndex + 1);
      }
    }

    /**
     * Searches for the endpoint given path segments starting from this node.
     * The endpoint subtree path is: "pathSegments[segIndex]/pathSegments[segIndex+1]/...".
     *
     * @param {string[]} pathSegments
     * @param {number} [segIndex=1]
     * @returns {any|null} found endpoint or null
     */
    findEndpoint(pathSegments, segIndex = 1) {
      const currentSegment = pathSegments[segIndex];
      const isTemplate = currentSegment.indexOf('{') !== -1;

      let subPathNode;
      if (!isTemplate) {
        subPathNode = this.subPaths[currentSegment];
      }
      subPathNode = subPathNode || this.templatedSubPaths.find((node) => node.matches(currentSegment));

      if (subPathNode) {
        if (segIndex === pathSegments.length - 1) {
          return subPathNode.endpoint;
        }
        return subPathNode.findEndpoint(pathSegments, segIndex + 1);
      }
      return null;
    }
  }

  return EndpointPathNode;
});

