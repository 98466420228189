'use strict';

define('vb/private/vx/baseExtensionAdapter',[
  'vb/private/configuration',
  'vb/private/constants',
  'vbc/private/log',
  'vbc/private/monitorOptions',
], (
  Configuration,
  Constants,
  Log,
  MonitorOptions,
) => {
  const logger = Log.getLogger('/vb/stateManagement/vx/extensionAdapter', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  class BaseExtensionAdapter {
    /**
     * @param {String} registryUrl  the url of the extension manager
     * @param {Object} vbInitConfig
     */
    constructor(registryUrl, vbInitConfig = globalThis.vbInitConfig || {}) {
      this.log = logger;
      /** @type {String} */
      this.registryUrl = registryUrl;
      /** @type {Object} */
      this.vbInitConfig = vbInitConfig;
    }

    /**
     * Calculate the preferred version to use when loading extension
     * This function is only called once during the lifetime of an application
     *
     * @return {String} the version
     */
    // eslint-disable-next-line class-methods-use-this
    getExtensionVersion() {
      // Precedence is given to dev version that is specified using _preferExtensionVersion query parameter.
      // If query param is not specified, use the FA sandbox id.
      const queryParams = new URL(globalThis.location).searchParams;

      // The query param name used by DT to pass the extension version to use
      // in the preview (vbdt:preferExtensionVersion)
      let version = queryParams.get(Constants.dtExtVersionQueryParam);

      if (!version) {
        // The query param name used by FA for integration tests (fre:preferExtensionVersion)
        version = queryParams.get(Constants.freExtVersionQueryParam);

        if (!version) {
          version = Configuration.getSandboxId();
          if (version) {
            // Add the $ in front of the FA sandbox id and encode it
            version = `$${encodeURIComponent(version)}`;
          }
        }
      }

      return version;
    }

    /**
     * @param {string} requestUrl
     * @param {string} message
     * @return Promise<Object>
     */
    getFetchManifestPromise(requestUrl, message) {
      const mo = new MonitorOptions(
        MonitorOptions.SPAN_NAMES.LOAD_EXTENSION_MANIFEST, `extension manifest load ${requestUrl}`,
      );

      return this.log.monitor(mo, (registryLoadTimer) => fetch(requestUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Response not OK when fetching extension manifest at: ${requestUrl}`);
          }

          return response.json().then((result) => {
            this.log.greenInfo(message, 'loaded.', registryLoadTimer());
            return result;
          });
        })
        .catch((error) => {
          registryLoadTimer(error);

          throw error;
        }));
    }

    /**
     * Return a URL object initalized with the registry URL string
     *
     * @return {URL}
     */
    getUrl() {
      return new URL(this.registryUrl);
    }

    convertVbFiles(vbFiles) {
      const files = [];
      this.buildPathsForRuntime(files, vbFiles);
      return files;
    }

    /**
     * Builds paths from a vb-bundle format
     *
     * @param {Array[String]}  bundle
     * @param {Array[String]}  bundledResources
     * @param {Object}  files
     */
    buildPathsForRuntime(files, folders, currentPath = '') {
      if (!folders || typeof folders !== 'object') {
        return;
      }

      Object.keys(folders).forEach((name) => {
        // If "files" was a folder name, it's type would not be an array
        if (name === 'files' && Array.isArray(folders.files)) {
          folders.files.forEach((file) => {
            files.push(`${currentPath}${file}`);
          });
        } else {
          this.buildPathsForRuntime(files, folders[name], `${currentPath}${name}/`);
        }
      });
    }
  }

  return BaseExtensionAdapter;
});

