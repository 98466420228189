'use strict';

define('vb/private/stateManagement/pageModuleViewModel',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/stateManagement/baseModuleViewModel',
  'vb/private/log',
], (Constants, Utils, BaseModuleViewModel, Log) => {
  const logger = Log.getLogger('/vb/stateManagement/pageModuleViewModel');

  /**
   * This class describes the view model for a VB page. It is a property of oj-module moduleConfig
   */
  class PageModuleViewModel extends BaseModuleViewModel {
    /**
     * Use the page object in a closure so that it's hidden from the viewModel
     *
     * @param {Page} page
     */
    constructor(page) {
      super(page);
      let rootIS = null; // Initialized when connected() is called

      // oj-module lifecycle callback
      const connected = (domNodes) => {
        logger.info('page', page.fullPath, 'connected to DOM');
        if (domNodes && domNodes.length > 0) {
          const parentNode = domNodes[0].parentNode; // generally a ojModule
          page.setParentOJModuleNode(parentNode);
          // set the ojModule node as the root node to observe intersecting child nodes, that are fragments
          if (!Utils.vbModuleObserver.getIntersectionObserver() && parentNode
            && parentNode.getElementsByTagName('oj-vb-fragment').length > 0) {
            // we have fragment children nodes. So start a new root node
            rootIS = Utils.vbModuleObserver.getIntersectionObserver(parentNode);
          }
        }
      };

      // oj-module lifecycle callback
      const disconnected = (domNodes) => {
        logger.info('page', page.fullPath, 'disconnected from DOM');
        page.setParentOJModuleNode(undefined);
        if (domNodes && domNodes.length > 0) {
          if (rootIS) {
            // when the page is disconnected it's a good time to remove the observer
            Utils.vbModuleObserver.clearIntersectionObserver();
            rootIS = null;
          }
        }

        page.disconnected(domNodes);
      };

      // Add the vbRouterFlow readonly property, this allows to do [[vbRouterFlow]] in the html
      Object.defineProperties(this, {
        [Constants.routerModuleConfig]: {
          value: page.moduleConfig,
          enumerable: true,
          configurable: true,
        },
        connected: {
          value: connected,
          enumerable: true,
          configurable: true,
        },
        disconnected: {
          value: disconnected,
          enumerable: true,
          configurable: true,
        },
      });
    }
  }

  return PageModuleViewModel;
});

