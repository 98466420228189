/* eslint-disable max-len */

'use strict';

define('vb/private/services/operationRefEndpoint',[
  'vb/private/log',
  'vb/private/services/uriTemplate',
], (Log, UriTemplate) => {
  const logger = Log.getLogger('/vb/private/services/operationRefEndpoint');
  /**
   * examples of operationRefs
   *
   * "x-links": { // (or "links")
   *   "SalesProfileTypeLookupLOV": {
   *      "operationRef" : "https://fuscdrmsmc217-fa-ext.us.oracle.com:443/crmRestApi/resources/11.12.0.0/crmBusinessUnits/describe#/paths/~1crmBusinessUnits~1{crmBusinessUnits_Id}~1child~1fndCommonSetIdLookups/get",
   *      "x-lov" : {
   *        "definedOn" : "$this#/RetireReasonCode",
   *        "valueField" : "LookupCode",
   *        "displayFields" : [ "Meaning" ]
   *       },
   *       "parameters" : {
   *         "finder" : "CommonSetIdLOVFinder%3BpLookupType%3DMKL_RETIRE_REASON_CD_SETID%2CpEffectiveDate%3D2020-03-19%2CpEnabledFlag%3DY%2CpReferenceGroupName%3DMKL_RETIRE_REASON_CD_SETID"
   *       }
   *
   * ************
   *
   * "operationRef" : "https://fuscdrmsmc217-fa-ext.us.oracle.com:443/fscmRestApi/resources/11.13.18.05/standardLookupsLOV/describe#/paths/~1standardLookupsLOV/get",
   * "x-lov" : {
   *  "definedOn" : "$this#/RecordSet",
   *    "valueField" : "LookupCode",
   *    "displayFields" : [ "Meaning" ]
   *   },
   *  "parameters" : {
   *     "finder" : "LookupTypeFinder%3BLookupType%3DORA_HZ_RESOURCE_SEARCH_REC_SET"
   *
   * *************
   *
   * "operationRef" : "#/paths/~1resources~1{resources_Id}/get",
   * "parameters" : {
   *   "resources_Id" : "$request.path.resources_Id"
   * }
   */

  /**
   * This class implements the Endpoint interface.
   * BUT Endpoint is a concrete class, and contains a lot of functionality that isn't needed here,
   * so this does not extend Endpoint.
   *
   * @todo: make Endpoint an interface, and extend it for the two concrete classes.
   */
  class OperationRefEndpoint {
    /**
     * The OperationRefEndpoint may or may not have a parent Service, depending on whether the URL could be mapped.
     *
     * @param {EndpointReferences} operationEndpointRef Instance of EndpointReference created using operation ref and the resolved URL
     */
    constructor(operationEndpointRef) {
      this.url = operationEndpointRef.url;

      // parameter replacement support
      this.uriTemplate = new UriTemplate(operationEndpointRef.url);

      // This is only place EndpointReference is created with operation ref options
      // @todo: we should be able to create this from the url & operationRef
      this.endpointReference = operationEndpointRef;

      if (!this.endpointReference.operationMethod) {
        logger.warn('Unable to determine HTTP method from "operationRef", using "GET"', operationEndpointRef.operationRef);
        this.method = 'GET';
      } else {
        this.method = this.endpointReference.operationMethod.toUpperCase();
      }

      this.service = null;
      this.endpoint = null;
    }

    /**
     * Returns a promise of initialized endpoint reference.
     * Initialization includes finding relevant service, and if it exists waiting for it to be loaded.
     * We also try to find endpoint of the referenced operation, and use it to get metadata and transforms.
     * @returns {Promise<OperationRefEndpoint>}
     */
    async init(service) {
      try {
        this.service = service;

        if (service) {
          await service.load();
          this.endpoint = service.findEndpoint(this.endpointReference);
          if (this.endpoint) {
            await this.endpoint.load();
          }
        } else {
          // return one for the raw URL
          logger.info('Unable to map URL, using Endpoint with no Service', this.endpointReference.url);
        }
      } catch (err) {
        // report error loading the service
        logger.warn('Error while loading parts of the endpoint: ', this.url, err);
      }
      return this;
    }

    // {
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api/incidents?technician=hcr",
    //   "method": "GET",
    //   "headers": {
    //     "vb-info-extension": "{\"headers\":{},\"baseUrl\":\"\",\"serviceName\":\"incidents\"}",
    //     "vb-allow-anonymous-access": true
    //   },
    //   "requestContentTypes": [],
    //   "responseContentTypes": []
    // }"

    /**
     * @typedef Config
     * @property {string} url
     * @property {string} [urlSuffix]
     * @property {string|Object} method
     * @property {Object} headers
     * @property {string[]} requestContentTypes
     * @property {string[]} responseContentTypes
     */
    /**
     * gets the url, method, and headers defined in the endpoint.
     *
     * @param {Object} parameters parameter values
     * @param {Object} [parameters.path]
     * @param {Object} [parameters.query]
     * @param {Object} [parameters.serverVariables]
     * @param {Object} [parameters.any]
     * @param {Object} [options] possible properties:
     *  - ignoreMissingParams: {boolean} if true, URL can have unreplaced templates.
     *                         otherwise, rejects when params are missing
     * @returns {Promise<Config>}
     * @private
     */
    getConfig(parameters = {}, options = {}) {
      return Promise.resolve()
        // if we found the endpoint use its config
        .then(() => this.endpoint && this.endpoint.getConfig(parameters, options))
        .then((baseConfig) => {
          const config = baseConfig || {
            headers: {},
            requestContentTypes: [],
            responseContentTypes: [],
          };

          // simple parameter support, for path and query params
          const flattenedParams = Object.assign({},
            parameters.any || {},
            parameters.path || {},
            parameters.query || {});
          config.url = this.uriTemplate.replace(flattenedParams, options.ignoreMissingParams);
          config.method = this.method;

          return config;
        });
    }

    // passed to transform as config.endpointDefinition
    // {
    //   "name": "getIncidents",
    //   "serviceId": "incidents",
    //   "method": "GET",
    //   "description": "",
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api/incidents",
    //   "requestContentTypes": [],
    //   "responseContentTypes": [],
    //   "parameters": {
    //     "query": {
    //       "technician": {
    //         "defaultValue": "hcr",
    //         "name": "technician",
    //         "in": "query",
    //         "x-vb-defaultValue": "hcr"
    //       }
    //     }
    //   },
    //   "headers": {},
    //   "staticQueryParameters": {}
    // }"
    async getMetadata(expended) {
      return this.endpoint ? this.endpoint.getMetadata(expended) : {};
    }

    getRequestTransforms() {
      if (this.endpoint) {
        return this.endpoint.getRequestTransforms();
      }
      return this.service && this.service.getRequestTransforms ? this.service.getRequestTransforms() : {};
    }

    getResponseTransforms() {
      if (this.endpoint) {
        return this.endpoint.getResponseTransforms();
      }
      return this.service && this.service.getResponseTransforms ? this.service.getResponseTransforms() : {};
    }

    getMetadataTransforms() {
      if (this.endpoint) {
        return this.endpoint.getMetadataTransforms();
      }
      return this.service && this.service.getMetadataTransforms ? this.service.getMetadataTransforms() : {};
    }
  }

  return OperationRefEndpoint;
});

