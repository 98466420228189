'use strict';

define('vb/private/configuration',[], () => {
  /**
   * The configuration object is used to store information on the current
   * configuration of the application.
   * This object is initialized once in main.js
   */
  class Configuration {
    static init(configuration) {
      // Store a copy of this function for testing
      Configuration.calculateApplicationUrl = configuration.calculateApplicationUrl;

      /**
       * The application URL. It is the URL showing in the browser running the application.
       * This URL can be different from the base URL used to retrieve the application resource.
       * This is will be used by the router sync navigation state and URL
       * See the configuration object in main.js for how it's calculated.
       */
      Configuration.applicationUrl = configuration.applicationUrl;

      /**
       * A string marker used in the URL when using the router path strategy to recognize
       * where in the URL the VB application path starts. This is used for the generic case
       * where vbInitConfig.APP_NAME is not defined.
       * For the URL https://host/pathname/vp/app/main/main-start
       * the VB path is /app/main/main-start
       * Page 'app' contains flow 'main' which contains page 'main-start'
       * The value is 'vp' and is defined once in main.js
       * @type {String}
       */
      Configuration.urlMarker = configuration.urlMarker || 'vp';

      /**
       * The name of the application as used in the URL. This value is copy from
       * vbInitConfig.APP_NAME. When it is set, it is used instead of urlMarker to
       * recognize where in the URL the application path starts.
       * For the URL https://host/pathname/<appName>/app/main/main-start
       * the VB path is /app/main/main-start
       * Page app contains flow main which contains page main-start
       * @type {String}
       */
      Configuration.appName = configuration.appName;

      /**
       * Calculate the base URL given the values defined in the window.vbInitConfig
       * The body of this function is defined in the configuration object of  in main.js
       * and copied here to avoid duplication.
       * This is expected to be used on PWA to calculated the baseUrl.
       * @return {String}
       */
      Configuration.getBaseUrlFromConfig = configuration.getBaseUrlFromConfig;

      /**
       * True when require-ut is used instead of require.
       * The value is set at init time in main.js by looking at the script tags
       * @type {Boolean}
       */
      Configuration.isRequireUt = configuration.isRequireUt;
    }

    /**
     * Retrieve the sandbox id or the application. Currently uses the BASE_URL_TOKEN property but in
     * future, there will be a dedicated property on vbInitConfig
     * @return {String} an id describing the sandbox of the application
     */
    static getSandboxId() {
      const initConfig = globalThis.vbInitConfig || {};

      // Honor BASE_URL_TOKEN first
      const baseUrlToken = initConfig.BASE_URL_TOKEN || '';
      let sandbox = baseUrlToken.split('sb:')[1];
      if (!sandbox) {
        sandbox = initConfig.SANDBOX;
      }

      return sandbox;
    }
  }

  return Configuration;
});

