/* eslint max-classes-per-file: ["error", 2] */

'use strict';

define('vb/extensions/dynamic/private/types/dynamicLayoutMetadataProviderDescriptor',[
  'vb/private/log',
  'vb/extensions/dynamic/private/types/abstractMetadataProviderDescriptor',
  'vb/extensions/dynamic/private/types/bridges/dynamicLayoutMetadataProviderFactoryBridge',
], (
  Log,
  AbstractMetadataProviderDescriptor,
  DynamicLayoutMetadataProviderFactoryBridge) => {
  const logger = Log.getLogger('/vb/extensions/dynamic/private/types/DynamicLayoutMetadataProviderDescriptor');
  /**
   *
   */
  class DynamicLayoutMetadataProviderDescriptor extends AbstractMetadataProviderDescriptor {
    /**
     * A "metadata" variable for providing the metadata provider to JET dynamic forms/tables.
     * Provides the basic shaping metadata (openapi3 or JSON), as well as the additional 'dynamic' layout resources.
     *
     * Has a 'provider' property that is the JET provider.
     *
     * @override
     * @returns {MetadataProviderFactoryBridge}
     */
    // eslint-disable-next-line class-methods-use-this
    createFactoryBridge() {
      return new DynamicLayoutMetadataProviderFactoryBridge();
    }

    /**
     * This method is called during the activation phase of the descriptor. During this phase,
     * it is safe to access values containing expressions to other variables.
     *
     * @returns {Promise<T>}
     */
    activate() {
      return Promise.resolve()
        .then(() => {
          const value = this.getValue();
          if (value.operationId && value.endpoint) {
            logger.warn(`Variable ${this.id} defined with both "endpoint" and "operationId";`
              + ' "operationId" will be ignored');
          }

          return super.activate();
        });
    }

    /**
     * @override
     * @returns {{type: {path: string, data: string}}}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition() {
      return {
        type: {
          endpoint: 'string',
          path: 'string',
          layoutPath: 'string',
          operationId: 'string',
          metadataPath: 'string',
          layoutConfig: { // deprecated (19.4.3 change log wiki). this was never officially supported.
            path: 'string',
            root: 'string',
          },
          objectContext: 'any',
          externalValue: 'any',
          initialValue: 'any',
        },
      };
    }
  }

  return DynamicLayoutMetadataProviderDescriptor;
});

