'use strict';

define('vb/private/stateManagement/application',[
  'vb/private/stateManagement/applicationClass',
  'vb/private/services/serviceResolverFactory',
], (ApplicationClass, ServiceResolverFactory) => {
  const singleton = new ApplicationClass();
  // eslint-disable-next-line no-param-reassign
  ServiceResolverFactory.application = singleton;
  return singleton;
});

