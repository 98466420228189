'use strict';

define('vb/extensions/dynamic/private/models/layoutResource',[
  'vb/private/stateManagement/container',
  'vb/extensions/dynamic/private/models/context/layoutResourceContext',
  'vb/extensions/dynamic/private/models/layoutResourceExtension',
  'vb/private/stateManagement/layoutMixin',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/utils',
  'vb/private/constants',
  'vb/errors/httpError',
  'vb/helpers/mixin',
  'vb/private/translations/bundleUtils',
], (Container, LayoutResourceContext, LayoutResourceExtension, LayoutMixin, PackageContainerMixin,
  Utils, Constants, HttpError, Mixin, BundleUtils) => {
  /**
   * LayoutResource is used to load layout related resources, e.g., data-description-overly, field-template-overlay,
   * metadata-rules, etc.
   */
  class LayoutResource extends Mixin(Container).with(LayoutMixin, PackageContainerMixin) {
    /**
     * LayoutResource constructor
     *
     * @param container the container that contains this layout resource
     * @param extension the extension from which to load the resource
     * @param {String} path the absolute path to the layout resource, e.g., vb/extA/dynamicLayouts/self/employee
     * @param {Object} resourceDescriptor resource descriptor
     * @param {String} className the class name
     */
    constructor(container, extension, path, resourceDescriptor, className = 'LayoutResource') {
      super(resourceDescriptor.resourceName, container, className);

      // Override value defined in Container
      this._extension = extension;

      this.resourceDescriptor = resourceDescriptor;

      Object.defineProperties(this, {
        path: {
          value: LayoutResource.calculatePath(path, this.extension.baseUrl),
          enumerable: true,
        },
      });

      this.descriptor = undefined;
      this.template = undefined;
    }

    /**
     * The extension class to be used for loading extension related resources
     *
     * @returns {LayoutResourceExtension}
     */
    static get extensionClass() {
      return LayoutResourceExtension;
    }

    /**
     * Return the LayoutResourceContext constructor used to create the '$' expression context.
     *
     * @type {LayoutResourceContext}
     */
    static get ContextType() {
      return LayoutResourceContext;
    }

    /**
     * Calculate the path of the layout resource.
     *
     * @param  {String} path
     * @param  {String} baseUrl
     * @return {String}
     */
    static calculatePath(path, baseUrl) {
      if (path && baseUrl && path.startsWith(baseUrl)) {
        return path.substring(baseUrl.length);
      }

      return path;
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor file
     *
     * @returns {string}
     */
    static get descriptorLoaderName() {
      return 'getTextResource';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getModuleResource';
    }

    /**
     * The name of the runtime environment function to be used to load the html
     *
     * @type {String} the template loader function name
     */
    static get templateLoaderName() {
      return 'getTextResource';
    }

    /**
     * The namespace for the base layout, i.e., main.
     *
     * Dynamic UI has no concept of extensions so all layouts (base layout plus layout extensions) are
     * referenced using namespaces. Note that the base layout can be reference with or without the
     * namespace.
     *
     * @returns {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get layoutNamespace() {
      return Constants.BASE_LAYOUT_NAMESPACE;
    }

    /**
     * This getter returns the extension resource name which can be different from the base resource
     * name. For example, the extension resource name for data-description-overlay is data-description.
     *
     * @returns {String}
     */
    get extensionResourceName() {
      return this.resourceDescriptor.altExtensionResourceName || super.extensionResourceName;
    }

    /**
     * Loader for JSON descriptor file
     *
     * @param resourceLocator resource path
     * @returns {Promise<Object>}
     */
    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        // App UI are using extension with a manifest containing a list of files,
        // so we can check if the file exist before doing a fetch that may fail.
        this.extension.fileExists(`${this.resourceLoc}${this.name}.json`);

        const resource = `${resourceLocator}.json`;
        return super.descriptorLoader(resource)
          .then((jsonContent) => {
            this.descriptor = jsonContent; // unparsed descriptor
            return Utils.parseJsonResource(jsonContent, resource);
          });
      })
        .catch((error) => {
          if (HttpError.isFileNotFound(error)) {
            // return an empty JSON so the container can properly initiate but leave
            // descriptor undefined
            return {};
          }
          throw error;
        });
    }

    /**
     * Loader for functions module
     *
     * @param resourceLocator resource path
     * @returns {Promise}
     */
    functionsLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        if (this.resourceDescriptor.skipFunctions) {
          return undefined;
        }

        this.extension.fileExists(`${this.resourceLoc}${this.name}.js`);
        return super.functionsLoader(resourceLocator);
      })
        .catch((error) => {
          if (HttpError.isFileNotFound(error)) {
            return undefined;
          }
          throw error;
        });
    }

    /**
     * Loader for HTML template file
     *
     * @param resourceLocator resource path
     * @returns {Promise<String>}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    templateLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        if (this.resourceDescriptor.skipTemplate) {
          return undefined;
        }

        // App UI are using extension with a manifest containing a list of files,
        // so we can check if the file exist before doing a fetch that may fail.
        this.extension.fileExists(`${this.resourceLoc}${this.name}.html`);

        return super.templateLoader(`${resourceLocator}.html`)
          .then((template) => {
            this.template = template;
            return template;
          });
      })
        .catch((error) => {
          if (HttpError.isFileNotFound(error)) {
            return undefined;
          }
          throw error;
        });
    }

    /**
     * Return a promise to load the .html
     *
     * @returns {Promise} a promise that resolve with the html text
     */
    loadTemplate() {
      // Keep a reference of the loading promise so that multiple function can wait
      // on the same promise to be resolved.
      this.loadHtmlPromise = this.loadHtmlPromise || this.templateLoader(this.getResourcePath());

      return this.loadHtmlPromise;
    }

    /**
     * Returns a promise that resolves when all resources associated with the layout resource are loaded
     *
     * @returns {Promise}
     */
    load() {
      this.loadPromise = this.loadPromise || this.loadMetadata()
        .then(() => this.loadContainerDependencies());

      return this.loadPromise;
    }

    /**
     * Overridden to allow extension loading to be skipped if resourceDescriptor.skipExtensions is true.
     *
     * @returns {Promise}
     */
    loadExtensions() {
      return Promise.resolve()
        .then(() => (this.resourceDescriptor.skipExtensions ? undefined : super.loadExtensions()));
    }

    /**
     * Overridden to allow loading imports to be skipped if resourceDescriptor.skipImports is true.
     *
     * @returns {Promise}
     */
    loadImports() {
      return Promise.resolve()
        .then(() => (this.resourceDescriptor.skipImports ? undefined : super.loadImports()));
    }

    /**
     * Overridden to allow loading translation bundles to be skipped if resourceDescriptor.skipBundles
     * is true.
     * @param [options] {Object} Special directives for the bundle definitions
     * @param [options.replacementValues] {Object} map of key/value pairs for expressions in path
     * @param [options.allowSelfRelative] {boolean} (V1) let the container JSON use "./" to mean 'relative to the
     * same folder
     * @param [options.isUnrestrictedRelative] {boolean} (V1) you can reach outside of your current folder
     * (only app-flow)
     * @param [options.preferV1] {boolean} If V1 translations are present, ignore V2 translations.
     * @param [options.proxyBundles] {boolean} Make proxies for bundles and don't wait for the bundles to be loaded
     * @returns {Promise}
     */
    loadTranslationBundles(options) {
      return Promise.resolve()
        .then(() => (this.resourceDescriptor.skipBundles ? undefined : super.loadTranslationBundles(options)));
    }

    /**
     * Load all resources and create the view model.
     *
     * @returns {Promise}
     */
    getViewModel() {
      if (!this.viewModelPromise) {
        this.viewModelPromise = Promise.all([
          this.load(), this.loadFunctionModule(), this.loadTemplate(),
        ])
          // load() eventually triggers loadTranslationBundles().  None of the setup needs to block on the load of the
          // bundles, but we need to make sure the bundles have been loaded before we try to construct $translations.
          .then(() => BundleUtils.whenBundlesReady())
          .then(() => {
            this.viewModel = this.getAvailableContexts();
            return this.viewModel;
          });
      }

      return this.viewModelPromise;
    }

    /**
     *
     * @param path
     * @returns {*}
     */
    adjustImportPath(path) {
      // Since LayoutResource doesn't have separate hierarchies for base and v2 extension containers,
      // it will simply check this.package to determine if it's a v2 extension or base container.
      return this.package ? super.adjustImportPath(path) : path;
    }

    // eslint-disable-next-line class-methods-use-this
    getScopeResolverMap() {
      return {};
    }

    dispose() {
      super.dispose();
      // Needed to release the parent container of a layout resource
      this._parent = null;
    }
  }

  return LayoutResource;
});

