'use strict';

define('vb/private/debug/debugStream',['vb/private/utils', 'vb/private/debug/constants', 'vb/private/constants'],
  (Utils, DebugConstants, Constants) => {
    /**
     * Stream used to postMessage data to the VB debugger and receive messages from the debugger.
     */
    class DebugStream {
      constructor(type) {
        this.vbConfig = window.vbInitConfig || {};

        // unique id for this debug stream
        this.id = Utils.generateUniqueId();

        this.type = type;

        if (this.isDebuggerInstalled) {
          this.installDebuggeeMessageListener();
        }
      }

      /**
       * Returns true if the debugger is installed.
       *
       * @returns {boolean|*}
       */
      get isDebuggerInstalled() {
        return this.vbConfig.debuggerInstalled;
      }

      /**
       * Returns true if we are running in actionChain test mode.
       *
       * @returns {boolean}
       */
      get isTestMode() {
        return Utils.isActionChainTestMode(this.vbConfig);
      }

      /**
       * Returns true if either the debugger is installed or we are running in test mode.
       *
       * @returns {boolean|*|boolean}
       */
      get isEnabled() {
        return this.isDebuggerInstalled || this.isTestMode;
      }

      /**
       * Post the given response to the debugger using the given port.
       *
       * @param port the port to post the response
       * @param method the method where the response comes from
       * @param response the response to post
       */
      postDebuggeeResponse(port, method, response) {
        const msg = {
          type: DebugConstants.ResponseType.DEBUGGEE,
          debuggeeId: this.id,
          debuggeeType: this.type,
          method,
        };

        Promise.resolve(response)
          .then((result) => {
            msg.success = true;
            msg.result = result;
            port.postMessage(msg);
          })
          .catch((error) => {
            msg.success = false;
            msg.error = JSON.stringify(error);
            port.postMessage(msg);
          });
      }

      /**
       * Invoke the method specified in methodInfo on the debug stream and post the response
       * back via the given port.
       *
       * @param methodInfo contains info on how to invoke a method
       * @param port the port used to send the response
       */
      invokeDebuggeeMethod(methodInfo, port) {
        const method = methodInfo.method;
        const args = methodInfo.args || [];

        this.postDebuggeeResponse(port, method, this[method](...args));
      }

      /**
       * Listen for messages from the debugger to the debuggee.
       */
      installDebuggeeMessageListener() {
        this.debuggeeMsgListener = (event) => {
          const data = event.data;
          const type = data.type;

          if (type === 'vbDebuggerUninstalled') {
            this.debuggerUninstalled();
          } else if (type === DebugConstants.MessageType.DEBUGGEE) {
            // make sure it's message from the debugger
            const message = data.message;

            // make sure this message is intended for us
            if (message.debuggeeId === this.id) {
              this.invokeDebuggeeMethod(message, event.ports[0]);
            }
          }
        };

        window.addEventListener('message', this.debuggeeMsgListener, false);
      }

      /**
       * Post the given message from the debuggee to the debugger.
       *
       * @param message the message to post
       * @returns {Promise}
       */
      postDebuggerMessage(message) {
        return new Promise((resolve) => {
          const msgChannel = new MessageChannel();

          msgChannel.port1.onmessage = (event) => {
            console.log('Response received from the debugger', event.data);
            const data = event.data;

            if (data.success) {
              resolve(data.result);
            } else {
              // ignore all errors from the debugger
              console.log(data.err);
              resolve();
            }
          };

          msgChannel.port1.onmessageerror = () => {
            console.log('Failed to send message to the debugger.');
            resolve();
          };

          window.postMessage({
            type: DebugConstants.MessageType.DEBUGGER,
            message,
          }, '*', [msgChannel.port2]);
        });
      }

      /**
       * Invoke the given method on the debugger.
       *
       * @param method the method to invoke on the debugger
       * @param args the arguments for the method
       * @returns {Promise}
       */
      invokeDebuggerMethod(method, ...args) {
        return this.postDebuggerMessage({
          debuggeeId: this.id,
          method,
          args,
        });
      }

      /**
       * Register this debug stream as a debuggee with the debugger.
       *
       * @param context the context for the debuggee
       * @returns {Promise}
       */
      registerDebuggee(context) {
        return this.invokeDebuggerMethod('registerDebuggee', this.type, context);
      }

      /**
       * Fire a state changed event to the debugger.
       *
       * @param state new state
       * @param data data associated with the new state
       * @returns {Promise}
       */
      fireStateChanged(state, data) {
        if (this.isDebuggerInstalled) {
          const event = {
            state,
            data,
          };
          return this.invokeDebuggerMethod('fireStateChanged', event);
        }

        return Promise.resolve();
      }

      /**
       * Called when the debugger is uninstalled.
       */
      debuggerUninstalled() {}

      /**
       * Dispose this debug stream.
       */
      dispose() {
        // unregister the message listener for messages from the debugger
        if (this.debuggeeMsgListener) {
          window.removeEventListener('message', this.debuggeeMsgListener);
        }
      }

      /**
       * This method is used to sanitize objects that cannot be serialized via postMessage.
       *
       * @param source object to sanitize
       * @returns {*}
       */
      static sanitize(source) {
        // if source is an extended typed object such as a SDP, clone its internal value state instead
        if (Utils.isExtendedType(source)) {
          return DebugStream.cloneObject(source.value);
        }

        // if source is a function, return its string representation
        if (typeof source === 'function') {
          return source.toString();
        }

        // map the Headers object to a plain object
        if (source instanceof Headers) {
          const headers = {};

          for (const entry of source.entries()) {
            if (entry && entry.length >= 2) {
              // lower case header names so we can assert the headers in a canonical way
              headers[entry[0].toLowerCase()] = entry[1];
            }
          }

          return headers;
        }

        // for non-plain objects, return its string representation
        // TODO: should we call JSON.stringify instead?
        if (source && !Utils.isPrimitive(source) && !Utils.isPrototypeOfObject(source)) {
          return source.toString();
        }

        return source;
      }

      /**
       * Specialized cloneObject that also sanitizes the object so it can be serialized via postMessage.
       *
       * @param source source to clone
       * @param destination
       * @returns {*}
       */
      static cloneObject(source, destination) {
        let target = destination;

        // determine what to do if destination is not specified
        if (typeof destination === 'undefined') {
          if (!Utils.isCloneable(source)) {
            return DebugStream.sanitize(source);
          }

          // otherwise create the right target
          target = Array.isArray(source) ? [] : {};
        }

        for (const name in source) {
          const copy = source[name];
          const src = target[name];
          let clone;

          // Prevent never-ending loop
          if (target === copy) {
            return target;
          }

          // Recurse if we're merging plain objects or arrays

          const copyIsArray = Array.isArray(copy);
          if (Utils.isCloneable(copy)) {
            if (copyIsArray) {
              // always clone into an empty array instead of overwriting the original
              clone = [];
            } else {
              clone = src && Utils.isObject(src) ? src : {};
            }

            // Never move original objects, clone them
            target[name] = DebugStream.cloneObject(copy, clone);

            // Don't bring in undefined values
          } else {
            target[name] = DebugStream.sanitize(copy);
          }
        }

        return target;
      }
    }

    return DebugStream;
  });

