/* eslint-disable max-classes-per-file */

'use strict';

// originally we intended to support RFC 6570, but we switched to Swagger,
// which does not support the full spec, so some of the functionality here isn't used.

define('vb/private/services/uriTemplate',[
  'urijs/URI',
  'vb/private/constants',
  'vb/private/log',
  'vb/private/services/swaggerUtils',
], (
  URI,
  Constants,
  Log,
  SwaggerUtils,
) => {
  // BUFP-19472: pluses cause issues with proxy server (will be fixed, this should be removable eventually)
  // eslint-disable-next-line no-param-reassign
  URI.escapeQuerySpace = false;

  // start conservatively, only look for forward slash
  const REGEXP_RESERVED = /[/]/; // cannot be global! will save state, and give alternating results

  const logger = Log.getLogger('/vb/private/services/uriTemplate');
  const regexTemplates = /{(.*?)}/gi;

  const hasValue = (value) => value !== undefined && value !== null;

  // avoid getting a clone if object is undefined
  const getWriteableCopy = (obj) => (obj ? Object.assign({}, obj) : {});

  // handles the '?' case, including comma-delimited names
  function formReplace(names = [], variables = new Map()) {
    let str = '';

    let isFirst = true;
    names.forEach((nameStr) => {
      let name = nameStr;
      let len = 0;
      const colon = name.indexOf(':');

      if (colon >= 0) {
        name = nameStr.substring(0, colon);
        len = parseInt(nameStr.substring(colon + 1), 10);
      }

      if (hasValue(variables.get(name))) {
        const valueStr = `${variables.get(name)}`; // convert to string
        const replacement = len > 0 ? valueStr.substring(0, len) : valueStr;

        str = `${str}${(isFirst ? '?' : '&')}${name}=${replacement}`;
        isFirst = false;
      }
    });
    return str;
  }

  /**
   * private to this module, represents the contents of a {brace} template
   */
  class Expression {
    constructor(exprStr) {
      const expr = exprStr;
      this.isForm = expr.startsWith('?');
      this.expr = this.isForm ? expr.substring(1) : expr;
    }

    getNames() {
      return this.expr.split(',').map((name) => {
        // strip character count, if any (ex: 'query:4')
        const colon = name.indexOf(':');
        return (colon !== -1) ? name.substring(0, colon) : name;
      });
    }

    getReplacement(variables = new Map()) {
      let str;
      if (this.isForm) {
        logger.warn('warning: {?q} template syntax not supported in service definitions');
        str = formReplace(this.expr.split(','), variables);
      } else {
        // this is a change in behavior, but it shouldn't matter because this is a failure case, and
        // nothing should be expecting, or relying on, the old behavior.
        str = variables.get(this.expr) || `{${this.expr}}`;
      }
      return str;
    }
  }

  /**
   * Map implementation backed by an array of Objects. We only override functions we are using.
   */
  class LinkedMaps extends Map {
    constructor(maps) {
      super();
      this.maps = maps;
    }

    has(key) {
      return this.maps.some((map) => Object.prototype.hasOwnProperty.call(map, key));
    }

    get(key) {
      const mapWithKey = this.maps.find((map) => Object.prototype.hasOwnProperty.call(map, key));
      return mapWithKey && mapWithKey[key];
    }

    delete(key) {
      return this.removeAll(key);
    }

    keys() {
      const allKeys = [];
      this.maps.forEach((map) => allKeys.push(...Object.keys(map)));
      return allKeys.values();
    }

    remove(key) {
      let removed = false;
      this.maps.forEach((map) => {
        if (!removed && map[key]) {
          // eslint-disable-next-line no-param-reassign
          delete map[key];
          removed = true;
        }
      });
      return removed;
    }

    removeAll(key) {
      let removed = false;
      this.maps.forEach((map) => {
        if (Object.prototype.hasOwnProperty.call(map, key)) {
          // eslint-disable-next-line no-param-reassign
          delete map[key];
          removed = true;
        }
      });
      return removed;
    }
  }

  /**
   * utility to expand RFC 6570 URI Templates.
   * A very basic, Level 1 implementation for now, including basic form query support:
   * ex:
   *    http://example.com/search/{name}/customers{?q,lang}
   * If we need expanded support, we should look at using a 3rd party lib.
   *
   *
   * NOTE: service definitions switched to using Swagger-2.0-like syntax,
   * which does NOT support all of RFC 6570. In particular, query parameter replacement
   * is not supported.  So, the ability to handle the templates is here,
   * but the service definitions should not be using those (unless something changes).
   */
  class UriTemplate {
    /**
     *
     * @param uri
     * @param parameterDefs from the service definition (swagger)
     * @param doNotAppendExtras normally, we append extra params as query params.
     */
    constructor(uri, parameterDefs = {}, doNotAppendExtras = false) {
      this.uri = uri || '';
      this.doNotAppendExtras = doNotAppendExtras;
      this.parameterDefs = parameterDefs;
      this.requiredParameters = SwaggerUtils.getRequiredParameters(this.parameterDefs);

      // get default values from the schemas (only respecting query defaults for now).
      this.defaultValueMap = {};

      // [query] => { foo: 'a', bar: 'x' ... }
      this.defaultValueMaps = {};

      SwaggerUtils.URL_PARAM_TYPES.forEach((type) => {
        const urlParameterDefs = this.parameterDefs[type] || {};

        this.defaultValueMaps[type] = {};
        const urlParameterTypeDefaults = this.defaultValueMaps[type];

        Object.values(urlParameterDefs).forEach((paramDef) => {
          const paramDefault = SwaggerUtils.getParameterDefault(paramDef);
          if (paramDef && paramDefault !== undefined) {
            // if there is already a param with the same name, log a warning (should not happen)
            if (this.defaultValueMap[paramDef.name]) {
              logger.warn(`found conflicting parameter names with default values: ${paramDef.name}`);
            }
            urlParameterTypeDefaults[paramDef.name] = paramDefault;
            this.defaultValueMap[paramDef.name] = paramDefault;
          }
        });
      });

      // this.expressionInfo = this._getExpressionInfo(); // todo: should we enable this?
    }

    /**
     * After replacing path segments of the uri template, complete the replacing logic by:
     * 1. adding any missing query parameters
     * 2. endcode the url if asked to do so, and
     * 3. inject back path segments with reserverd characters we did not want to encode
     *
     * @param {string} url current state of the URL replacement
     * @param {string[]} qParamNames list of query parameters that still have not been added
     * @param {Map<string, string>} variablesToMerge map of the query paremeter values
     * @param {Object} placeholderMap
     * @param {boolean} skipTemplateEncoding
     * @returns (string) new URL
     */
    _finishReplacing(url, qParamNames, variablesToMerge, placeholderMap, skipTemplateEncoding) {
      // for BUFP-32180, switching to using URI to add the query params here.
      const uri = new URI(url);
      let newUrl = url;

      if (qParamNames.length) {
        const addQueryParameter = (n, v) => {
          // to match pre-URI behavior, add 'null' or 'undefined'; @todo: revisit this
          if (v === null) {
            uri.addSearch(n, 'null');
          } else if (v === undefined) {
            uri.addSearch(n, 'undefined');
          } else {
            uri.addSearch(n, v);
          }
        };

        qParamNames.forEach((paramName) => {
          const value = variablesToMerge.get(paramName);
          // include null, or non-objects
          if (typeof value !== 'object' || value === null) {
            const def = this._findParameterDefinition(paramName);

            // add it if its 'query', or if we don't see a definition for it
            if ((!def && !this.doNotAppendExtras) || (def && def.in === 'query')) {
              addQueryParameter(paramName, value);
            }
          } else if (Array.isArray(value)) {
            const def = this._findParameterDefinition(paramName);
            if (!def || def.in === 'query') {
              if (def && def.collectionFormat === 'multi') {
                value.forEach((v) => {
                  addQueryParameter(paramName, v);
                });
              } else if (def || !this.doNotAppendExtras) {
                const queryValue = value
                  .map((v) => (typeof v.replace === 'function' ? v.replace(/,/g, '%2C') : v))
                  .join();
                addQueryParameter(paramName, queryValue);
              }
            }
          }
        });

        newUrl = uri.toString();
      }

      // finally, encode the entire URL (this used to be done in Rest helper)
      // @todo: revisit this, see serviceSpec: 'test endpoint parameter substitution 2 (arrays)'
      if (!skipTemplateEncoding) {
        const newNewUrl = UriTemplate.encode(newUrl);
        // check if encoding added a trailing slash;
        // only happens with host-only url (URIjs adds it), so encoding shouldn't matter
        newUrl = (newNewUrl === `${newUrl}${Constants.PATH_SEPARATOR}`)
          ? newNewUrl.substring(0, newNewUrl.length - 1) : newNewUrl;
      }

      // finally, replace the placeholders for reserved-character path params
      Object.keys(placeholderMap).forEach((placeholder) => {
        newUrl = newUrl.replace(placeholder, placeholderMap[placeholder]);
      });
      return newUrl;
    }

    /**
     * do all path/query parameter replacement; this is the main API.
     *
     * const temp = new UriTemplate('http:/myhost/{foo}');
     * const newUrl = temp.replace({ foo: 'a', boo: 'b' }); // 'http:/myhost/a?boo=b'
     *
     * @param variablesArg
     * @param skipTemplateEncoding default is false. should only be true when you know there will missing parameters,
     *     and you do not want to encode the braces
     * @returns {string}
     */
    replace(variablesArg = {}, skipTemplateEncoding = false) {
      // protect against non-object values
      const variables = (typeof variablesArg === 'object' && !Array.isArray(variablesArg))
        ? variablesArg : {};

      const variablesValues = Object.assign({}, this.defaultValueMap, variables);
      const variablesToMerge = new Map(Object.entries(variablesValues));

      // if a path parameter has reserved characters, it must be encoded, so create a 'placeholder',
      // and encode at the end
      let placeholderIndex = 0;
      const placeholderMap = {};
      const mergedVariables = {};

      const newUrl = this.uri.replace(regexTemplates, (braceStr, exprStr) => {
        const expr = new Expression(exprStr);
        let exprReplacement = expr.getReplacement(variablesToMerge);

        // if the param value has reserved characters, handle separately
        if (exprReplacement) {
          const def = this._findParameterDefinition(exprStr);
          // if its a path parameter, use a placeholder, and replace it after we encode everything else
          // arbitrary placeholder, that can be reasonably expected to be unique, and does not need encoding
          const placeholder = `___ph${placeholderIndex += 1}___`;

          // if we have a character in the regex that needs to be encoded...
          if (def && def.in === 'path' && REGEXP_RESERVED.test(exprReplacement)) {
            placeholderMap[placeholder] = encodeURIComponent(exprReplacement);
            exprReplacement = placeholder;
          } else {
            // no param definition; keep the template unencoded if there is no value to replace it
            // using a different check than exprReplacement, because getReplacement() returns the template,
            // when there's no value, and there's no way to tell the difference
            // between 'no value' and 'value looks like template'
            const noReplacement = !variablesToMerge.has(exprStr);

            if (noReplacement) {
              // else, if
              placeholderMap[placeholder] = exprReplacement; // leave it as-is
              exprReplacement = placeholder;
            }
          }
        }

        expr.getNames().forEach((nameInExpr) => {
          // track variables that have been merged (replaced in the url) and remove them from variablesToMerge after
          // the replace. This ensures repeating patterns are addressed
          mergedVariables[nameInExpr] = 'merged';
        });
        return exprReplacement;
      });

      // remove variables already replaced, the leftovers are query params
      Object.keys(mergedVariables).forEach((name) => {
        variablesToMerge.delete(name);
      });

      // now add the ones passed in
      // const qParamNames = Object.keys(this.queryParameters).filter(name => replaced.indexOf(name) < 0);
      // we used to respect the Swagger param definitions, and only included params that were defined.
      // now, we'll use the x-vb-defaultValue if its there, but otherwise, any variables passed in that weren't
      // used already in path templates, and are not defined as non-query, are assumed to be form/query parameters

      const qParamNames = [...variablesToMerge.keys()].sort(); // sorted so the order is predictable for tests

      return this._finishReplacing(newUrl, qParamNames, variablesToMerge, placeholderMap, skipTemplateEncoding);
    }

    /**
     * Resolves template URL using more complex logic.
     * RequireParams properties (path and query) are replaced only into the corresponding parts
     * of the URI template. The requestParams.any values are wild cards, and are used for either path or query
     * if more specific values are not already provided.
     * Only requestParams.query values are consider for appending to the uri when the UriTemplate is
     * configured to append extras.
     *
     * @param {Object} requestParams
     * @param {Object} [requestParams.path]
     * @param {Object} [requestParams.query]
     * @param {Object} [requestParams.any] server variables can be used in any part of the URL
     * @param {boolean} [skipTemplateEncoding=false]
     * @param {Object} [defaultParams] Deafult values provided at request time.
     *                                 Same structure as requestParams paramater
     * @returns {string}
     */
    replaceRequestParams(requestParams, skipTemplateEncoding = false, defaultParams = {}) {
      const anyVariables = getWriteableCopy(requestParams.any);
      const pathVariables = getWriteableCopy(requestParams.path);
      const queryVariables = getWriteableCopy(requestParams.query);

      // pass the values to constrocutor in the order of the presedance
      const pathVariablesToMerge = new LinkedMaps([
        pathVariables,
        anyVariables,
        getWriteableCopy(defaultParams.path),
        getWriteableCopy(this.defaultValueMaps.path)]);
      const queryVariablesToMerge = new LinkedMaps([
        queryVariables,
        anyVariables,
        getWriteableCopy(defaultParams.query),
        getWriteableCopy(this.defaultValueMaps.query)]);

      // if a path parameter has reserved characters, it must be encoded, so create a 'placeholder',
      // and encode at the end
      let placeholderIndex = 0;
      const placeholderMap = {};
      const mergedVariables = {};

      const newUrl = this.uri.replace(regexTemplates, (braceStr, exprStr) => {
        const expr = new Expression(exprStr);
        const isForm = expr.isForm;
        const type = isForm ? 'query' : 'path';
        const variablesToMerge = isForm ? queryVariablesToMerge : pathVariablesToMerge;
        let exprReplacement = expr.getReplacement(variablesToMerge);

        // if the param value has reserved characters, handle separately
        if (exprReplacement) {
          const def = this._findParameterDefinition(exprStr, type, []);
          // if its a path parameter, use a placeholder, and replace it after we encode everything else
          // arbitrary placeholder, that can be reasonably expected to be unique, and does not need encoding
          const placeholder = `___ph${placeholderIndex += 1}___`;

          // if we have a character in the regex that needs to be encoded...
          if (def && def.in === 'path' && REGEXP_RESERVED.test(exprReplacement)) {
            placeholderMap[placeholder] = encodeURIComponent(exprReplacement);
            exprReplacement = placeholder;
          } else {
            // no param definition; keep the template unencoded if there is no value to replace it
            // using a different check than exprReplacement, because getReplacement() returns the template,
            // when there's no value, and there's no way to tell the difference
            // between 'no value' and 'value looks like template'
            const noReplacement = !variablesToMerge.has(exprStr);

            if (noReplacement) {
              // else, if
              placeholderMap[placeholder] = exprReplacement; // leave it as-is
              exprReplacement = placeholder;
            }
          }
        }

        const mergedTypeVariables = mergedVariables[type] || (mergedVariables[type] = {});
        expr.getNames().forEach((nameInExpr) => {
          // track variables that have been merged (replaced in the url) and remove them from variablesToMerge after
          // the replace. This ensures repeating patterns are addressed
          mergedTypeVariables[nameInExpr] = 'merged';
        });
        return exprReplacement;
      });

      // remove variables already replaced, the leftovers are query params
      Object.keys(mergedVariables).forEach((type) => {
        const mergedTypeVariales = mergedVariables[type];
        const variablesToMerge = (type === 'query') ? queryVariablesToMerge : pathVariablesToMerge;
        Object.keys(mergedTypeVariales).forEach((name) => {
          variablesToMerge.delete(name);
        });
      });

      // now add the ones passed in
      // const qParamNames = Object.keys(this.queryParameters).filter(name => replaced.indexOf(name) < 0);
      // we used to respect the Swagger param definitions, and only included params that were defined.
      // now, we'll use the x-vb-defaultValue if its there, but otherwise, any variables passed in that weren't
      // used already in path templates, and are not defined as non-query, are assumed to be form/query parameters

      const qParamNames = [...queryVariablesToMerge.keys()].sort(); // sorted so the order is predictable for tests

      return this._finishReplacing(newUrl, qParamNames, queryVariablesToMerge, placeholderMap, skipTemplateEncoding);
    }

    /**
     * Finds the definition for the parameter.
     * First looks in the parameters of given type (defaults to 'query'), and then looks
     * at any other possible parameter type as specified by otherTypes parameter.
     *
     * the parameterDefs maps the defs by the (swagger) "in" parameter type
     * {
     *    "query": {.... },
     *    "header": {.....},
     *    "cookie": {....},
     *    "path": {....},
     *   any others?
     * }
     *
     * @param {string} paramName
     * @param {string} [type='query']
     * @param {string[]} [otherTypes] Other param types to search for. Defaults to all avialable parameter types.
     * @returns {*}
     * @private
     */
    _findParameterDefinition(paramName, type = 'query', otherTypes = Object.keys(this.parameterDefs)) {
      let def = this.parameterDefs[type] && this.parameterDefs[type][paramName];
      if (!def) {
        otherTypes.some((inType) => {
          def = this.parameterDefs[inType] && this.parameterDefs[inType][paramName];
          return def;
        });
      }
      return def;
    }

    /**
     * not used for runtime, only useful for design-time currently
     * returns an array of information about each variable template in the URI
     * {
     *  isQuery: true/false
     *  name: the name, without any qualifier
     *  isRequired: false if its a form (query) parameter, making it optional
     *  length: - optional, only set if the variable name is qualified with a size. ex. {name:4}
     * }
     * @returns {Array}
     */
    _getExpressionInfo() {
      const templateInfo = [];

      let queryPos = this.uri.indexOf('?');

      let match = regexTemplates.exec(this.uri);

      while (match != null) {
        let isForm = false;

        let str = match[1] || '';

        if (str.startsWith('?')) {
          logger.warn('warning: {?} template syntax not supported in service definitions');
          isForm = true;
          str = str.substring(1);
          if (queryPos < 0) {
            queryPos = match.index;
          }
        }

        const isQuery = isForm || (match.index >= queryPos && queryPos >= 0);

        // handle comma-separated lists
        str.split(',').forEach((strItem) => {
          let name = strItem;
          const colon = name.indexOf(':');
          let len = -1;
          if (colon >= 0) {
            name = strItem.substring(0, colon);
            len = parseInt(strItem.substring(colon + 1), 10);
          }

          const paramInfo = {
            isQuery,
            name,
            isRequired: !isForm,
          };
          // add optional 'length' if one is specified (ex. {name:2}
          if (len > -1) {
            paramInfo.length = len;
          }

          templateInfo.push(paramInfo);
        });

        // keep looking
        match = regexTemplates.exec(this.uri);
      }

      return templateInfo;
    }

    /**
     * returns an array of names of missing required parameters (if any).
     *
     * @param {Object} requestParams
     * @param {Object} [requestParams.path]
     * @param {Object} [requestParams.query]
     * @param {Object} [requestParams.header]
     * @param {Object} [requestParams.serverVariables] server variables can be used in any part of the URL
     * @param {Object} [requestParams.any] non-server variables set as generic parameters
     *
     * @returns {Array<Object>} list of parameter definitions, or an empty Array
     */
    getMissingRequiredParameters(requestParams) {
      const missingDefs = [];
      const serverVars = requestParams.serverVariables || {};
      const anyVars = requestParams.any || {};
      this.requiredParameters.forEach((paramDef) => {
        const name = paramDef.name;
        const type = paramDef.in;

        const typeValue = requestParams[type] && requestParams[type][name];
        const serverVarValue = serverVars[name];
        const anyValue = anyVars[name];

        const noValue = !(hasValue(typeValue) || hasValue(serverVarValue) || hasValue(anyValue));
        const noDefault = !hasValue(this.defaultValueMap[name]);
        if (noValue && noDefault) {
          missingDefs.push(paramDef);
        }
      });
      return missingDefs;
    }

    /**
     * encode url and query parameter values, for added security
     *
     * See rest.js; we used to do this there; moved this closer to where the param substitution is done,
     * to handle path parameter encoding better.
     *
     * @param url
     * @param encodePath if false, the path portion of the url is not encoded. default: true
     * @private
     */
    static encode(url, encodePath = true) {
      const uriObj = URI.parse(url);
      const queryObj = URI.parseQuery(uriObj.query);
      if (queryObj) {
        // decode the existing parameters
        Object.keys(queryObj).forEach((name) => {
          const value = queryObj[name];
          // decode the values - if not encoded, we should get the same value
          if (typeof value === 'string') {
            queryObj[name] = URI.decodeQuery(value);
          } else if (value && Array.isArray(value)) {
            queryObj[name] = value.map((v) => URI.decodeQuery(v));
          }
        });
        // this will re-build it with encoding
        uriObj.query = URI.buildQuery(queryObj, true);

        // bufp-24834 whatwg doesn't encode spaces in the url, so encode the path.
        // note: this requires that path params have NOT been encoded already.
        if (uriObj.path && encodePath) {
          uriObj.path = URI.recodePath(uriObj.path);
        }

        return URI.build(uriObj).toString();
      }

      return url;
    }
  }

  return UriTemplate;
});

