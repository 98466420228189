/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/services/serviceResolverFactory',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/services/serviceResolver',
], (
  Constants,
  Utils,
  ServiceResolver,
) => {
  const factoryID = Symbol('vb.service.resolver.factoryId');

  /**
   *
   */
  class ServiceResolverFactory {
    /**
     *
     * @param {*} extensionId
     * @returns
     */
    static getResolverForExtension(extensionId = Constants.ExtensionNamespaces.BASE) {
      const services = ServiceResolverFactory.application
        && ServiceResolverFactory.application.getExtensionServices(extensionId);
      return new ServiceResolver({ factoryID, extensionId, services });
    }

    static getResolverForContainer(container) {
      const app = container.application || ServiceResolverFactory.application;

      // TODO: simply
      const extensionId = Utils.getCallingExtensionId(container);
      const services = app && app.getExtensionServices(extensionId);

      const containerExtId = container.extensionId;
      let altServices;
      if (containerExtId !== extensionId) {
        altServices = app && app.getExtensionServices(containerExtId);
      }

      return new ServiceResolver({
        factoryID, extensionId, services, altServices,
      });
    }

    /**
     *
     * @param {string|{extensionId: string}|Container|ServiceResolver} contextObject
     * @returns
     */
    static getResolver(serviceContext) {
      if (serviceContext) {
        if (ServiceResolverFactory.checkResolver(serviceContext)) {
          return serviceContext;
        }

        if (typeof serviceContext === 'string') {
          return ServiceResolverFactory.getResolverForExtension(serviceContext);
        }

        return ServiceResolverFactory.getResolverForContainer(serviceContext);
      }
      return ServiceResolverFactory.getResolverForExtension(Constants.ExtensionNamespaces.BASE);
    }

    /**
     * Check if the given object was created by ServiceResolverFactory.getResolver*().
     *
     * @param {ServiceResolver} serviceResolver
     * @returns {boolean}
     */
    static checkResolver(serviceResolver) {
      // check we created it
      return serviceResolver && (serviceResolver.factoryID === factoryID);
    }

    /**
     *
     * @param { ServiceResolver| {extensionId: string}} serviceContext
     * @param {boolean} [strict=false]
     * @throws When strict parameter is true and serviceContext is not created by this class.
     */
    static getNamespace(serviceContext, strict = false) {
      const validServiceResolver = ServiceResolverFactory.checkResolver(serviceContext);
      // Note that during VBS-29855 investigation it was identified that the vbContext AggregateServiceDataProvider
      // receives is writable indicating someone is cloning it somewhere on the stack.
      // We need to be careful handling that and transitioning clients of that kind of behavior.
      if (strict && !validServiceResolver) {
        throw new Error('Invalid service resolver object');
      }

      if (serviceContext) {
        // we already check this so use it if it is true
        if (validServiceResolver) {
          return ServiceResolver.getNamespace(serviceContext);
        }

        if (serviceContext.extensionId) {
          return serviceContext.extensionId;
        }
      }
      return Constants.ExtensionNamespaces.BASE;
    }

    static getServices(serviceContext) {
      const ns = ServiceResolverFactory.getNamespace(serviceContext);
      return ServiceResolverFactory.application
        && ServiceResolverFactory.application.getExtensionServices(ns);
    }
  }
  // will be set by the app singleton
  ServiceResolverFactory.application = undefined;

  return ServiceResolverFactory;
});

