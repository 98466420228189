'use strict';

define('vb/private/constants',['vbc/private/constants'], (CommonConstants) => {
  // copy over constants from CommonConstants
  const Constants = Object.assign({}, CommonConstants);

  /**
   * Supported Message Types allowed by JET.
   * @type {{ERROR: string, WARNING: string, INFO: string, CONFIRMATION: string, NONE: string}}
   * @see oj.Message component
   */
  Constants.MessageType = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    CONFIRMATION: 'confirmation',
    NONE: 'none',
  };

  /**
   * Supported variable name spaces.
   * - state (default): variable holds the state of a related object
   * - data: variable holds the data. example fetched from a backend service
   * - metadata: variable holds metadata. example: used with dynamic components
   * - builtin: builtin variable. Example currentPage and path.
   *
   * @type {{CONSTANTS: string, VARIABLES: string, METADATA: string, BUILTIN: string}}
   */
  Constants.VariableNamespace = {
    VARIABLES: 'variables',
    METADATA: 'metadata',
    BUILTIN: 'builtin',
    CONSTANTS: 'constants',
  };

  Constants.VariableClassification = {
    REGULAR: 'regular',
    INSTANCE_FACTORY: 'instanceFactory',
  };

  Constants.VariableTypePrefixes = {
    INSTANCE_FACTORY: 'vb/InstanceFactory',
  };

  // The query param name used by DT to pass the extension version to use in the preview
  Constants.dtExtVersionQueryParam = 'vbdt:preferExtensionVersion';
  // The query param name used by FA for integration tests
  Constants.freExtVersionQueryParam = 'fre:preferExtensionVersion';
  // A query param used by DT in VB Studio to change the stripe
  Constants.redwoodOverrideQueryParam = 'vbdtRedwoodOverride';

  Constants.UrlParamsToIgnore = [
    Constants.dtExtVersionQueryParam,
    Constants.freExtVersionQueryParam,
    Constants.redwoodOverrideQueryParam,
  ];

  Constants.ReservedVariableNames = Constants.UrlParamsToIgnore;

  /**
   * Suffixes appended to a builtin type variable.
   * @type {{VALUE: string, INTERNAL_STATE: string}}
   */
  Constants.BuiltinVariableName = {
    VALUE: '_value',
    INTERNAL_STATE: '_internalState',
  };

  /**
   * States a variable can be in its lifecycle.
   * - init: variable in the initialization stage. Usually the next state is active
   * - active: variable is live, meaning reading its state will yield a fully resolved value. Other variables it
   *    depends on, also resolve to their full value. Usually this is right before vbEnter
   * - dispose: variable is in the process of being destroyed / disposed
   * Note: This is especially so when other variables it depends on (might) have already been destroyed.
   * @type {{INIT: number, ACTIVE: number, DISPOSE: number}}
   */
  Constants.VariableLifecycleStage = {
    INIT: 1,
    ACTIVE: 2,
    DISPOSE: 4,
  };

  /**
   *
   * @type {{ALL: string, NONE: string}}
   */
  Constants.VariableWritablePropertyOptions = {
    ALL: 'all',
    NONE: 'none',
  };

  /**
   *
   * @type {{INPUT: string, WRITEBACK: string}}
   */
  Constants.VariableProperties = {
    INPUT: 'input',
    WRITEBACK: 'writeback',
  };

  /**
   *
   * @type {{FROM_URL: string, FROM_CALLER: string, NONE: string}}
   */
  Constants.VariablePropertyInput = {
    FROM_CALLER: 'fromCaller',
    FROM_URL: 'fromUrl',
    NONE: 'none',
  };

  /**
   * The allowed values of the peristed property of variables
   *  @type {{HISTORY: string, SESSION: string, DEVICE: string, LOCAL: string}}
   */
  Constants.VariablePropertyPersisted = {
    HISTORY: 'history',
    SESSION: 'session',
    DEVICE: 'device',
    LOCAL: 'local', // same as 'device' but deprecated
  };

  /**
   * Name of the initParam VB DT can use to provide local server overrides when VB is run in the Preview mode
   * @type {Object}
   */
  Constants.InitParams.SERVICES_SERVER_OVERRIDES = 'services.server.overrides';

  /**
   * Name of properties available in context object exposed to the user
   * @type {{ BINDING_CONTEXT: string, PARAMETERS: string, EVENT: string, PREVIOUS: string,
   *          CURRENT: string, INIT_PARAMS: string}}
   */
  Constants.ContextName = {
    PARAMETERS: '$parameters',
    EVENT: '$event',
    PREVIOUS: '$previous', // used in event listeners, for 'transform' and 'transformPayload' behavior
    CURRENT: '$current',
    BINDING_CONTEXT: '$bindingContext',
    INIT_PARAMS: '$initParams',
  };

  /**
   * Name of properties available in context object not exposed to the user
   * @type {{DISPATCH_EVENT: string}}
   */
  Constants.ContextNameInternal = {
    DISPATCH_EVENT: '$dispatchEvent',
  };

  // Used to prefix service endpoints (ex: “base:fooService/getPets”)
  Constants.ExtensionNamespaces = {
    BASE: 'base',
  };

  Constants.CatalogNamespaces = {
    BASE: Constants.ExtensionNamespaces.BASE,
    TENANT: 'tenant', // @todo: near-future use
  };

  Constants.RESOURCES_FOLDER_PATH = 'resources/';

  /**
   * Reserved folders under /resources
   * @type {{FUNCTIONS: string}}
   */
  Constants.RESOURCES_SUBFOLDERS = {
    FUNCTIONS: `${Constants.RESOURCES_FOLDER_PATH}/functions`,
    CSS: `${Constants.RESOURCES_FOLDER_PATH}/css`,
    JS: `${Constants.RESOURCES_FOLDER_PATH}/js`,
  };

  // BASE is for files that extend files in the base app; SELF is for files that are new in the extension.
  Constants.ExtensionFolders = {
    BASE: 'base',
    SELF: 'self',
  };

  /**
   * Properties commonly referred to in expressions.
   * - base: could refer to the global app container that the current extension extends, or an artifact in base the
   *    current extension refers to. Or it could refer to the container / artifact in an extension that is being
   *    extended or referred to.
   * - self: used usually in reference to the current extension - e.g., "self:$functions/foo" to specify a path in
   * imports modules.
   */
  Constants.ExtensionContextProperties = {
    BASE: 'base', // refers to the base (artifact/container) that is being extended by the current extension.
    SELF: 'self',
  };

  // constants used for extension reference types set via the referenceable property
  Constants.ExtensionReferenceable = {
    SELF: 'self',
    EXTENSION: 'extension',
  };

  /**
   * The name of the property used to store the action results in the action scope results variable.
   * @type {String}
   */
  Constants.RESULTS_VARIABLE_KEY = 'vb_results';

  /**
   * Built-in variables
   * @type {String}
   */
  Constants.CURRENT_PAGE_VARIABLE = 'currentPage';
  Constants.CURRENT_FLOW_VARIABLE = 'currentFlow';
  Constants.LOCALE_VARIABLE = 'locale';
  Constants.PATH_VARIABLE = 'path';

  /**
   * built-in constants
   * @type {string}
   */
  Constants.PROFILE_CONSTANT = 'profile'; // $application.profile
  Constants.DEPLOYMENT_CONSTANT = 'deployment'; // $application.deployment

  // these are peers of PATH_VARIABLE/CURRENT_PAGE_VARIABLE because they appear in the availableContexts,
  // but are not Variable objects.
  Constants.RESPONSIVE_CONTEXT = 'responsive';
  Constants.TRANSLATIONS_CONTEXT = 'translations';
  Constants.COMPONENTS_CONTEXT = 'components';
  Constants.INFO_CONTEXT = 'info';
  Constants.APPLICATION_USER_VARIABLE = 'user';
  Constants.GLOBAL_CURRENT_APPUI_VARIABLE = 'currentAppUi';
  Constants.INIT_PARAM_CONTEXT = 'initParams';

  Constants.PARENT_FOLDER = `..${Constants.PATH_SEPARATOR}`;
  Constants.CURRENT_FOLDER = `.${Constants.PATH_SEPARATOR}`;
  Constants.RELATIVE_FOLDER_PREFIX = '.';

  Constants.GLOBAL_PREFIX = 'global';
  Constants.APPLICATION_PREFIX = 'application';
  Constants.FLOW_PREFIX = 'flow';
  Constants.PAGE_PREFIX = 'page';
  Constants.LAYOUT_PREFIX = 'layout';
  Constants.APP_UI_PREFIX = 'application';
  Constants.THIS_PREFIX = 'this';
  Constants.FRAGMENT_PREFIX = 'fragment';

  /**
   * VB event names
   */
  Constants.VB_EVENT_MARKER = 'vb';
  Constants.ENTER_EVENT = 'vbEnter';
  Constants.EXIT_EVENT = 'vbExit';
  Constants.BEFORE_ENTER_EVENT = 'vbBeforeEnter';
  Constants.BEFORE_EXIT_EVENT = 'vbBeforeExit';
  Constants.NOTIFICATION_EVENT = 'vbNotification';
  Constants.AFTER_NAVIGATE_EVENT = 'vbAfterNavigate';
  Constants.INSTALL_PROMPT_EVENT = 'vbBeforeAppInstallPrompt';
  Constants.DATAPROVIDER_NOTIFICATION_EVENT = 'vbDataProviderNotification';
  Constants.RESOURCE_CHANGED_EVENT = 'vbResourceChanged';
  Constants.NEW_CONTENT_AVAILABLE = 'vbNewContentAvailable';

  Constants.VB_EVENTS = [
    Constants.ENTER_EVENT,
    Constants.EXIT_EVENT,
    Constants.BEFORE_ENTER_EVENT,
    Constants.BEFORE_EXIT_EVENT,
    Constants.NOTIFICATION_EVENT,
    Constants.AFTER_NAVIGATE_EVENT,
    Constants.INSTALL_PROMPT_EVENT,
    Constants.DATAPROVIDER_NOTIFICATION_EVENT,
    Constants.RESOURCE_CHANGED_EVENT,
    Constants.NEW_CONTENT_AVAILABLE,
  ];

  /**
   * Possible dirty data states returned by the action that determines
   * whether there are any dirty data variables in the scope.
   * @type {{DIRTY: string, NOT_DIRTY: string}}
   */
  Constants.DirtyDataReturnStatus = {
    DIRTY: 'dirty',
    NOT_DIRTY: 'notDirty',
  };

  Constants.DIRTY_DATA_STATUS = 'dirtyDataStatus';

  /**
   * variable events
   */
  // the event listener set on variable is 'onValueChanged' but the event name, though not
  // explicitly declared anywhere, could follow other vb event names - prefixed by 'vb' but for
  // whatever reason we have diverged from convention!.
  Constants.VALUE_CHANGED = 'onValueChanged';

  /**
   * The value of the container.lifecycleState property
   *
   * @type {Object}
   */
  Constants.ContainerState = {
    CREATED: 'created',
    ENTERED: 'entered',
    EXITED: 'exited',
    REFRESHING: 'refreshing', // set when DT Preview Canvas is refreshing various parts of the container
    DISPOSED: 'disposed',
  };

  /**
   * Specific to fragments, where state refers to CCA state / ojModule state / fragment disposed state (typically
   * when parent calls dispose on its fragments)
   */
  Constants.FragmentState = {
    MODULE_CONNECTED: 'moduleConnected',
    MODULE_DISCONNECTED: 'moduleDisconnected',
  };

  Constants.FragmentComponentState = {
    COMPONENT_CONNECTED: 1,
    COMPONENT_DISCONNECTED: 0,
    COMPONENT_REMOVED: -1, // this happens when the entire fragment subtree is not only disconnected but orphaned
                           // from parent. Example for oj-bind-if cases where the DOM is removed
  };

  /**
   * The capabilities supported on fragment
   * @type {{ALLOWS_SLOTS: string}}
   */
  Constants.FragmentCapability = {
    ALLOWS_SLOTS: 'ALLOWS_SLOTS',
  };

  Constants.RouterStrategy = { QUERY: 'query', PATH: 'path' };

  /**
   * viewModel property to retrieve the oj-module config in index.html
   * @type {String}
   */
  Constants.appModuleConfig = 'vbApplication';
  /**
   * viewModel property to retrieve the oj-module config a VB page .html
   * @type {String}
   */
  Constants.routerModuleConfig = 'vbRouterFlow';
  /**
   * viewModel property used by oj-vb-xxx components to retrieve the context
   * of the container
   * @type {String}
   */
  Constants.componentBridge = 'vbBridge';

  /**
   * Possible values for whether to enable the dirty data tracking on a variable.
   * @type {{NONE: string, TRACK: string}}
   */
  Constants.DirtyDataBehavior = { TRACK: 'track', NONE: 'none' };

  /**
   * viewModel types that setup a  bridge to connect the component to the VB ecosystem
   * @type {{SWITCHER: string, FRAGMENT_SLOT: string, FRAGMENT: string}}
   */
  Constants.ComponentBridgeType = {
    FRAGMENT: 'fragment',
    FRAGMENT_SLOT: 'fragment-slot',
    SWITCHER: 'switcher',
  };

  /**
   * The configuration object for an empty ojModule
   * @type {Object}
   */
  Constants.blankModuleConfig = { view: [], viewModel: null };

  /**
   * A descriptor used for the error page
   */
  Constants.errorPageDescriptor = {
    pageModelVersion: '1.0.0',
    description: 'Error Page',
  };

  /**
   * Service constants
   * @type {{definitionTimeout: number}}
   */
  Constants.Services = {
    definitionTimeout: 30000, // number of milliseconds to wait for a service definition
  };

  /**
   * The default rate limit in milliseconds for limiting how often onValueChanged should be fired
   * @type {number}
   */
  Constants.DEFAULT_RATE_LIMIT = 1;

  /**
   * The maximum depth we would recursively create default values for cyclic types
   * @type {number}
   */
  Constants.MAX_DEFAULT_VALUE_RECURSION_DEPTH = 1;

  /**
   * mime types
   * @type {{MULTIPART: string}}
   */
  Constants.ContentTypes = {
    MULTIPART: 'multipart/form-data',
  };

  Constants.Decorators = {
    PREFIX: '@decorators',
  };

  Constants.DefaultPaths = {
    UI: 'ui/',
    APPLICATIONS: 'applications/',
    FRAGMENTS: 'fragments/',
    SERVICES: 'services/',
    LAYOUTS: 'dynamicLayouts/',
    TRANSLATIONS: 'translations/',
    CATALOG_JSON: 'services/catalog.json',
    MODULES: 'modules/',
  };

  Constants.EXTENSION_PATH_NAME = 'vx';
  Constants.EXTENSION_PATH = `${Constants.EXTENSION_PATH_NAME}${Constants.PATH_SEPARATOR}`;

  Constants.EventTargets = {
    // the container which contains the chain the owns the action
    CURRENT: 'current',
    // the 'lowest' container in the current hierarchy (typically a Page).
    LEAF: 'leaf',
  };

  // declared event behaviors
  Constants.EventBehaviors = {
    NOTIFY: 'notify',
    NOTIFY_WAIT: 'notifyAndWait',
    CHECK_CANCEL: 'checkForCancel',
    TRANSFORM: 'transform',
    TRANSFORM_PAYLOAD: 'transformPayload',
    DYNAMIC_COMP: 'dynamicComponent', // a special behavior; used for dynamic UI template events
  };

  // declared event propagation behaviors
  Constants.EventPropagationBehaviors = {
    // event can only propagate to the current container's listeners
    SELF: 'self',
    // event can propagate to container above current. How this works is left to the discretion of the container.
    // Currently only fragment supports this
    CONTAINER: 'container',
  };

  /**
   * supported types of event auto wiring
   * @type {{SELF_ONLY: string, NONE: string, FULL: string}}
   */
  Constants.EventAutoWireMode = {
    // auto wiring is disabled
    NONE: 'none',
    // auto wiring is enabled only for the parent container
    SELF_ONLY: 'selfOnly',
    // auto wiring is enabled for the parent container and all of its extensions
    FULL: 'full',
  };

  // declared event mode
  Constants.EventMode = {
    LISTENABLE: 'listenable',
    TRIGGERABLE: 'triggerable',
  };

  // returns from event listener processing, to differentiate from 'no return value'
  Constants.NO_EVENT_LISTENER_RESPONSE = '__no_listener__';

  Constants.User = {
    DEFAULT: 'anonymous',
    TESTER: 'tester',
  };

  /**
   * An array of all possible scopes used in expressions
   * @type {Array<String>}
   */
  Constants.ALL_SCOPES = ['$global', '$application', '$flow', '$page', '$base', '$extension', '$chain', '$variables',
    '$layout', '$fragment', '$enums', '$modules'];

  /**
   * The name used for the property storing the application instance into the Container prototype
   * @type {String}
   */
  Constants.APP_INTERNAL_PROPERTY_NAME = '__application';

  /**
   * Symbol used to access internal context of an action chain.
   * @type {symbol}
   */
  Constants.CHAIN_INTERNAL_CONTEXT = Symbol('chain-internal-context');

  /**
   * Symbol used to access the target of a proxy created by the action chain.
   * @type {symbol}
   */
  Constants.CHAIN_PROXY_TARGET = Symbol('chain-proxy-target');

  /**
   * Path prefix for builtin actions.
   * @type {string}
   */
  Constants.BUILTIN_ACTION_PATH_PREFIX = 'vb/action/builtin/';

  /**
   * All the possible navigate operation triggered by navigate action or navigateToPage action
   * @type {Object}
   */
  Constants.NavigateOperation = {
    PAGE: 'navigateToPage',
    PAGE_OLD: 'oldNavigateToPage',
    FLOW: 'navigateToFlow',
    APP_UI: 'navigateToAppUi',
  };

  /**
   * The values for the history property of the navigateAction
   */
  Constants.HistoryMode = {
    PUSH: 'push',
    SKIP: 'skip',
    REPLACE: 'replace',
  };

  Constants.NavigateOrigin = {
    POPSTATE: 'popState',
  };

  Constants.NavigateDirection = {
    FORWARD: 'forward',
    BACKWARD: 'backward',
  };

  /**
   * The value of the navigation.fromExternal property in the page or flow json
   * when navigation from external is enabled
   * @type {String}
   */
  Constants.FROM_EXTERNAL_NAVIGATION_ENABLED = 'enabled';

  /**
   * Common action outcomes used by actions
   * @type {{SUCCESS: string, TRUE: string, FALSE: string, FAILURE: string}}
   */
  Constants.ActionOutcomes = {
    SUCCESS: 'success',
    FAILURE: 'failure',
    TRUE: 'true',
    FALSE: 'false',
  };

  /**
   * Namespace for a base layout
   *
   * @type {string}
   */
  Constants.BASE_LAYOUT_NAMESPACE = 'main';

  Constants.Digest = {
    RUNTIME: 'runtime',
    REQUIREJS_INFO: 'requirejs-info',
    APP_UI_INFO: 'app-ui-info',
    PWA_INFO: 'pwa-info',
  };

  Constants.RequirejsInfo = {
    VB_BUNDLES: 'vb-bundles',
  };

  Constants.Runtime = {
    VB_FILES: 'vb-files',
  };

  return Constants;
});

