'use strict';

define('vbc/private/constants',[], () => {
  const Constants = {};

  /**
   * The default log levels
   */
  Constants.Severity = {
    ERROR: 'error',
    INFO: 'info',
    FINE: 'fine',
    FINER: 'finer',
    WARNING: 'warn',
  };

  // from highest precedence to lowest
  Constants.SeverityOrder = [
    Constants.Severity.ERROR,
    Constants.Severity.WARNING,
    Constants.Severity.INFO,
    Constants.Severity.FINE,
    Constants.Severity.FINER,
  ];

  Constants.SessionStorage = {
    LOG: 'vbSessionLog',
    EDITOR_URL: 'oracle.vbdt.editor',
  };

  Constants.PATH_SEPARATOR = '/';

  Constants.VbProtocols = {
    CATALOG: 'vb-catalog',
  };

  // these headers used in both the fetch handler plugins and service layer
  Constants.Headers = {
    CONTENT_TYPE: 'content-type',

    CONTENT_LENGTH: 'content-length',

    // used internally to communicate extensions to plugins; should be stripped before the actual request.
    VB_INFO_EXTENSION: 'vb-info-extension',

    // header for indicating if anonymous access is allowed for the request
    ALLOW_ANONYMOUS_ACCESS_HEADER: 'vb-allow-anonymous-access',

    // used when service uses HTTP protocol; we must use the proxy, and in order for the service worker to get the
    // request, we have to switch the protocol (http is blocked), and save the original in this header.
    // note; we don't really use the SW any more for requests, but we haven't officially dropped SW support.
    PROTOCOL_OVERRIDE_HEADER: 'vb-protocol',

    // sent by DT during in-page preview, needed to disable certain types of auth that won't work in that context
    VB_DT_AUTHENTICATION: 'dt-serviceAuthentication',

    INHERIT: 'inherit-authentication',

    // attach access token when available to a request with this header
    USE_OAUTH_ACCESS_TOKEN_WHEN_AVAILABLE: 'vb-use-oauth-access-token-when-available',
  };

  // predefined vbInitParam values (or "initParams")
  Constants.InitParams = {
    // an array of authentication 'type' values that the authPreprocessor plugin should not process.
    // useful when using non-standard VB plugins, that may process the same auth type.
    PLUGIN_PASSTHROUGHS: 'services.security.handlers.passthroughs',
  };

  // Status of service worker caching
  Constants.ServiceWorkerCachingStatus = {
    IDLE: 'IDLE',
    CACHING: 'CACHING',
    WAITING: 'WAITING',
  };

  /**
   *
   * @type {{ACTION_CHAIN: string, UNIT_TEST: string}}
   */
  Constants.TestMode = {
    ACTION_CHAIN: 'actionChain',
    UNIT_TEST: 'unitTest',
  };

  return Constants;
});

