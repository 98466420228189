'use strict';

define('vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper',[
  'vb/extensions/dynamic/private/helpers/configurableMetadataProviderHelper',
  'vb/extensions/dynamic/private/models/layoutResource',
  'vb/extensions/dynamic/private/models/dataDescription',
  'vb/extensions/dynamic/private/models/metadataRules',
  'vb/private/stateManagement/layout',
  'vb/private/utils',
], (
  ConfigurableMetadataProviderHelper,
  LayoutResource,
  DataDescription,
  MetadataRules,
  Layout,
  Utils,
) => {
  const RESOURCE_DESCRIPTORS = [{
    resourceName: 'layout',
    propertyMap: {
      descriptor: 'data',
      template: 'template',
      viewModel: 'dataModel',
    },
    modelClass: Layout,
  }, {
    resourceName: 'data-description',
    propertyMap: {
      descriptor: 'clientMetadata',
      viewModel: 'clientMetadataModel',
    },
    modelClass: DataDescription,
    skipTemplate: true,
  }, {
    resourceName: 'metadata-rules',
    propertyMap: {
      descriptor: 'metadataRules',
      viewModel: 'metadataRulesModel',
    },
    modelClass: MetadataRules,
    skipFunctions: true,
    skipTemplate: true,
  }, {
    resourceName: 'context',
    propertyMap: {
      descriptor: 'contextMetadata',
    },
    modelClass: LayoutResource,
    skipFunctions: true,
    skipTemplate: true,
  }];

  /**
   * DataDescriptionMetadataProviderHelper can be used as the "vbHelper" interface that is passed to
   * JET dynamic UI metadata provider for interfacing with VB.
   *
   * This extends ConfigurableMetadataProviderHelper, and implements the "data-description.json" version of
   * the helper; the metadata comes from the local JSON file, rather than service openapi3.
   *
   * @see ServiceMetadataProviderHelper for the closely-related openapi3 version.
   *
   * its 'private', but shared with JET; not for use my application developers
   */

  class DataDescriptionMetadataProviderHelper extends ConfigurableMetadataProviderHelper {
    /**
     *
     * @param options {object}
     * @param options.id {string}
     * @param vbAppContext the context created by VB, and passed as 'options.context' to the components/providers
     * @param container the VB container where the dynamic component with this layout will be used
     * @returns {Promise<ConfigurableMetadataProviderHelper>} this
     */
    static get(options, vbAppContext, container) {
      const helperOptions = DataDescriptionMetadataProviderHelper.getHelperConfiguration();

      const opts = Object.assign({}, options, { helper: helperOptions });

      return new DataDescriptionMetadataProviderHelper(opts, vbAppContext, container).init();
    }

    /**
     * @param options
     * @param vbAppContext
     * @param container
     */
    constructor(options, vbAppContext, container) {
      super(options, vbAppContext, container);
      if (options.path) {
        this.setLayoutRoot(options.path);
      }
    }

    /**
     * the configuration for the base class
     * @returns {{resources: {layout: {baseFile: *, extensionFile: *, prefix: string},
     *   metadata: {baseFile: *, prefix: string}, additional: *}}}
     */
    static getHelperConfiguration() {
      return {
        // @ts-ignore
        descriptors: Utils.cloneObject(RESOURCE_DESCRIPTORS),
      };
    }

    /**
     *
     * @returns {Promise<Object>}
     */
    getMetadata() {
      const descriptors = [{
        resourceName: 'data-description',
        propertyMap: {
          descriptor: 'data',
          viewModel: 'dataModel',
        },
        modelClass: LayoutResource,
        skipTemplate: true,
      }];

      return this.getLayoutPath()
        .then((pathPrefix) => this.loadLayoutResources(pathPrefix, descriptors));
    }
  }

  // expose for unit tests
  DataDescriptionMetadataProviderHelper.RESOURCE_DESCRIPTORS = RESOURCE_DESCRIPTORS;

  return DataDescriptionMetadataProviderHelper;
});

