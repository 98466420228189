/* eslint-disable no-param-reassign, class-methods-use-this */

'use strict';

define('vb/private/vx/baseExtensionRegistry',[
  'vb/private/vx/appUiInfos',
  'vb/private/vx/extension',
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
], (AppUiInfos, Extension, ConfigLoader, Constants, Utils, Log) => {
  const logger = Log.getLogger('/vb/private/vx/baseExtensionRegistry');

  /**
   * Given 2 arrays, replace or append element from source array to
   * target array when the id of the element matches
   *
   * @param  {Array} sourceArray
   * @param  {Array} targetArray
   */
  const replaceOrAppendToArray = (sourceArray, targetArray) => {
    if (Array.isArray(sourceArray) && Array.isArray(targetArray)) {
      sourceArray.forEach((info) => {
        let index = 0;
        // Search for the object in targetArray with info.id
        for (; index < targetArray.length; index += 1) {
          if (targetArray[index].id === info.id) {
            break;
          }
        }

        // Either replace or append it with the source value
        targetArray.splice(index, 1, info);
      });
    }
  };

  /**
   * A class to retrieve the extensions for the current application from the extension manager
   * The extension manager URL is defined in the app-flow.json under the extension property.
   */
  class BaseExtensionRegistry {
    /**
     * This is called from ConfigLoader.js
     */
    constructor() {
      this.fetchManifestPromise = null; // Initialized in subclass initiateLoadManifest()
      this.loadExtensionsPromise = null; // Initialized in getExtensions()

      /**
       * A map of extension object keyed by their id
       * @type {Object}
       */
      this.extensions = {};

      this.log = logger;
    }

    /**
     * The base implementation is not bound to an extension manager version
     * Either null or v2 for the extensionRegistry subclass
     */
    static get extensionManagerVersion() {
      return null;
    }

    /**
     * Initialize the extension registry. This consist of defining promise responsible
     * to load the manifest
     * @return {Object}
     */
    initialize(registryConfig) {
      if (registryConfig.hasRegistry()) {
        this.fetchManifestPromise = registryConfig.fetchManifestPromise;
      } else {
        this.log.info('No extension registry defined.');

        // There is no extension manager defined
        // for this application so the manifest is an empty array.
        this.loadExtensionsPromise = Promise.resolve([]);
      }

      return this;
    }

    /**
     * Load extension manager and design time manifest and merge them
     * Only called by subclass
     * @return {Promise<Array<Promise>>}
     */
    _loadManifest() {
      // Load the manifest from the extension manager and from DT and replace
      // the extensions and requirejsInfo with the ones from the DT manifest.
      return Utils.getRuntimeEnvironment().then((re) => re.getExtensionManifest())
        .then((dtManifest) => {
          if (dtManifest) {
            return this.fetchManifestPromise
              .then(([runtimeDigestPromise, requirejsInfoDigestPromise, appUiInfoDigestPromise]) => {
                // Replace or append manifest extensions using DT manifest
                if (runtimeDigestPromise) {
                  runtimeDigestPromise = runtimeDigestPromise
                    .then((extensions) => {
                      replaceOrAppendToArray(dtManifest.extensions, extensions);
                      return extensions;
                    });
                }

                // Replace or append manifest requirejsInfo using DT extensions requirejsInfo
                if (requirejsInfoDigestPromise) {
                  requirejsInfoDigestPromise = requirejsInfoDigestPromise
                    .then((requirejsInfo) => {
                      replaceOrAppendToArray(dtManifest.requirejsInfo, requirejsInfo);
                      return requirejsInfo;
                    });
                }

                // Replace or append manifest appUiInfo using DT extensions appUiInfo
                if (appUiInfoDigestPromise) {
                  appUiInfoDigestPromise = appUiInfoDigestPromise
                    .then((appUiInfo) => {
                      replaceOrAppendToArray(dtManifest.appUiInfo, appUiInfo);
                      return appUiInfo;
                    });
                }

                return [runtimeDigestPromise, requirejsInfoDigestPromise, appUiInfoDigestPromise];
              });
          }

          return this.fetchManifestPromise;
        });
    }

    getLoadManifestPromise() {
      return this.fetchManifestPromise;
    }

    /**
     * Calculate the base path of the extended resource given the container
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePath(path, container = { extensionId: '' }) {
      return `${container.extensionId}/${path}`;
    }

    /**
     * Calculate the base path for ui type of resource (stuff under ui/)
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line no-unused-vars
    getBasePathForUi(path, container) {
      // implemented by subclass
    }

    /**
     * Calculate the base path for layout type of resource (stuff under dynamicLayouts/)
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line no-unused-vars
    getBasePathForLayout(path, container) {
      // implemented by subclass
    }

    /**
     * Get the base path for the given container. If the container is a layout,
     * getBasePathForLayout will be called, otherwise, getBasePathForUi is called.
     *
     * @param path
     * @param container
     * @returns {string}
     */
    getBasePathForContainer(path, container) {
      return path.startsWith(Constants.DefaultPaths.LAYOUTS) ? this.getBasePathForLayout(path, container)
        : this.getBasePathForUi(path, container);
    }

    /**
     * Loads all the extension for a specific container given its path. It returns a promise
     * that resolves in an array of extensions object, either PageExtension or FlowExtension.
     * @param  {String} path the path of the object for which we are looking for extensions
     * @param  {Container} container the container for which the extension is being loaded
     * @return {Promise} a promise to an array of extension objects
     */
    loadContainerExtensions(path, container) {
      return this.getExtensions()
        .then((extensions) => {
          if (extensions.length === 0) {
            return [];
          }

          const promises = [];
          const basePath = this.getBasePathForContainer(path, container);
          const Clazz = container.constructor.extensionClass;
          // container name may not necessarily be the actual resourceName. So use the resourceName property instead
          const extPath = `${basePath}${container.extensionResourceName}${Clazz.resourceSuffix}`;
          const isOnline = Utils.isOnline();
          const isCachingEnabled = Extension.isCachingEnabled();

          // Traverse the array of extension from first to last. The extension manager is responsible
          // for properly ordering this array of extensions given the dependencies in the extension manager.
          extensions.forEach((extension) => {
            const files = extension.files || [];

            // If the manifest contains an extension for this artifact, creates an extension object for it
            // But only if we're online or the extension has been marked as available offline
            if ((isOnline || (isCachingEnabled && extension.offlineEnabled))
              && files.indexOf(extPath) >= 0) {
              // TODO: reduce params?
              const ext = new (Clazz)(extension, basePath, container);
              const promise = ext.load().then(() => ext);
              promises.push(promise);
            }
          });

          // All files are then loaded in parallel
          return Promise.all(promises);
        });
    }

    /**
     * Retrieve a map of AppUiInfo for all the App UI available in all the extensions
     * The map is populated only by the v2 implementation
     * @return {Promise} a promise that resolve with a map of AppUiInfo
     */
    getAppUiInfos() {
      return Promise.resolve(new AppUiInfos());
    }

    /**
     * Returns a promise that resolves with an array of extensions or an empty array
     * @return {Promise<Array>} a promise that resolve to an array of extension
     */
    getExtensions() {
      this.loadExtensionsPromise = this.loadExtensionsPromise || this.getLoadManifestPromise()
        .then((manifestPromises) => Promise.all(manifestPromises))
        .then(([extArray, requirejsInfo, appUiInfo]) => {
          if (!extArray) {
            return [];
          }

          const extensions = [];

          this.log.info('Found', extArray.length, 'extension(s) in the manifest.');

          // From this point to when endDeferRequireConfig is called, all calls
          // to ConfigLoader.setConfiguration are cached.
          ConfigLoader.startDeferRequireConfig();

          extArray.forEach((definition) => {
            const extensionId = definition.id;
            const extension = this.createExtension(definition,
              appUiInfo && appUiInfo[extensionId],
              requirejsInfo && requirejsInfo.bundlesInfo[extensionId],
              requirejsInfo && requirejsInfo.bundledResources[extensionId],
              requirejsInfo && requirejsInfo.components[extensionId]);

            if (!extension.isValid()) {
              this.log.error('Invalid manifest for extension:', extensionId, 'version:', extension.version);
            } else {
              extensions.push(extension);
              this.extensions[extensionId] = extension;
            }
          });

          const promises = extensions
            // if this extension extends the application, load the bundle or
            // create a require mapping so the resources are available.
            // Extensions with only App UI definition are loaded on demand.
            .filter((extension) => extension.extendsBaseArtifact())
            .map((extension) => extension.init());

          return Promise.all(promises).then(() => extensions)
            .finally(() => {
              ConfigLoader.endDeferRequireConfig();
            });
        });

      return this.loadExtensionsPromise;
    }

    /**
     * Look up the extension identified by id.
     *
     * @param id extension id
     * @returns {Extension}
     */
    getExtensionById(id) {
      return this.extensions[id];
    }

    /**
     * This function checks if an extension depends directly or indirectly on another extension given their ids.
     *
     * @param {string} extensionId
     * @param {string} upstreamExtensionId
     * @returns {boolean} true if extensionId depends on upstreamExtensionId
     * @abstract
     */
    // eslint-disable-next-line no-unused-vars
    isDependent(extensionId, upstreamExtensionId) {
      throw Error('need to override isDependent');
    }

    /**
     * Add requirejs mappings
     * @param {Object} paths
     */
    addRequireMapping(paths) {
      ConfigLoader.setConfiguration({ paths });
    }

    /**
     * Creates an ExtensionServices Object for the application extension, and creates a name/file map
     * from the contents of the extension.
     *
     * @param {String} extensionId
     * @param {Object} options the standard options used to construct a Services object.
     *
     * @returns {Promise}
     */
    loadServicesModel(extensionId, options) {
      // populated with the services we can find on the extension manifest
      const extensionServiceMap = {};

      return this.getExtensions()
        .then((extensions) => {
          const ext = extensions.find((ex) => ex.id === extensionId);
          if (ext) {
            ext.files.forEach((file) => {
              let name;
              let path;

              const match = file.match(this.constructor.serviceRegex);
              // [0] is the whole match, [1] is the first (and only) group
              if (match) {
                path = match[0];
                name = match[1];
              }

              if (name && path) {
                // we need to check if the extension has an explicit serviceFileMap declaration to ensure we are
                // not replacing it
                const declaredPath = options && options.serviceFileMap && options.serviceFileMap[name];
                if (!declaredPath) {
                  extensionServiceMap[name] = path;
                } else if (declaredPath !== path) {
                  // it would be weird to declare a path for a service that doesn't match its name,
                  // if one that did match its name already existed.
                  this.log.warn('Extension', extensionId, 'contains service metadata ', path,
                    '. The declared file will be used instead: ', declaredPath);
                }
              }
            });
          } else {
            // this should never happen
            this.log.warn('Unable to find extension services for extension, continuing: ', ext);
          }
          return this.findCatalog(ext);
        })
        .then((catalogPath) => {
          const optionsClone = Object.assign({ extensionServiceMap }, options);

          if (catalogPath) {
            optionsClone.extensions = optionsClone.extensions || {};
            optionsClone.extensions.catalogPaths = {
              [extensionId]: catalogPath,
            };
          }

          // ExtensionServices need to be loaded later than this module because
          // it forces JET to load before ojL10n is setup in ConfigLoader
          return Utils.getResource('vb/private/services/extensionServices')
            .then((ExtensionServices) => new ExtensionServices(optionsClone));
        });
    }

    /**
     * if there is a catalog.json in self/, returns the vx-mapped path to the file
     * ex:  vx/ext2/self/services/catalog.json
     * @param {Object} extension
     * @returns {string|undefined}
     */
    findCatalog(extension) {
      let found;
      extension.files.some((file) => {
        if (this.constructor.catalogRegex.test(file)) {
          found = `${Constants.EXTENSION_PATH}${extension.id}/${file}`;
        }
        return !!found;
      });
      return found;
    }

    /**
     * Loads all the translation extensions for a specific Bundle given its path.
     * The default implementation which is used by v1 does not support translation extensions so returns
     * an empty Promise.  The v2 implementation in extensionRegistry.js retrieves the translation extensions for
     * the bundle
     * @param  {String} path the path of the Bundle Definition for which we are looking for extensions
     * @param  {Object} bundleDefinition the bundle for which the extensions are being loaded
     * @return {Promise<Array>} a promise to an array of Bundle Extension objects
     */
    // eslint-disable-next-line no-unused-vars
    loadTranslationExtensions(path, bundleDefinition) {
      return Promise.resolve([]);
    }

    /*
     * Retrieve a map of all extensions that define translation bundles.
     * The default implementation which is used by v1 does not understand translations bundles
     * so returns an empty map. The v2 implementation in extensionRegistry.js retrieves
     * a Map of extensions that define a translation bundle.
     * @return {Promise<Map<string,object>>} map of extId to extension for all that define a translation bundle
     */
    getTranslations() {
      return Promise.resolve([]);
    }
  }

  return BaseExtensionRegistry;
});

