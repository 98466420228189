'use strict';

/**
 * No-op module that depend on all plugins. It is only used for making the bundle that includes all
 * fetch plugins.
 */
define(
  'vbsw/private/plugins/allPlugins',[
    'vbsw/private/plugins/anonymousAuthHandlerPlugin',
    'vbsw/private/plugins/authenticatedAuthHandlerPlugin',
    'vbsw/private/plugins/authHeaderHandlerPlugin',
    'vbsw/private/plugins/authPostprocessorHandlerPlugin',
    'vbsw/private/plugins/authPreprocessorHandlerPlugin',
    'vbsw/private/plugins/authPublicHandlerPlugin',
    'vbsw/private/plugins/authSessionHandlerPlugin',
    'vbsw/private/plugins/csrfTokenHandlerPlugin',
    'vbsw/private/plugins/errorResponseHandlerPlugin',
    'vbsw/private/plugins/fallbackInheritedAuthHandlerPlugin',
    'vbsw/private/plugins/generalHeadersHandlerPlugin',
    'vbsw/private/plugins/headerTidyHandlerPlugin',
    'vbsw/private/plugins/implicitFlowHandlerPlugin',
    'vbsw/private/plugins/multiTenantCsrfTokenHandlerPlugin',
    'vbsw/private/plugins/oAuthAccessTokenHandlerPlugin',
    'vbsw/private/plugins/resourceChangedPlugin',
    'vbsw/private/plugins/sessionExpirePlugin',
    'vbsw/private/plugins/sessionTrackingHandlerPlugin',
    'vbsw/private/plugins/tokenRelayHandlerPlugin',
  ], () => ({}),
);

