'use strict';

define('vb/private/services/definition/serviceMapFactory',[
  'vb/binding/expression',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/services/definition/openApiServiceDefFactory',
], (
  Expression,
  Log,
  Utils,
  OpenApiServiceDefFactory,
) => {
  const logger = Log.getLogger('/vb/private/services/definition/ServiceMapFactory');

  const TYPE = 'serviceMap';

  /**
   * Class responsible finding and loading ServiceDefinitions based on the OpenApi
   * resources
   */
  class ServiceMapFactory extends OpenApiServiceDefFactory {
    constructor(services, options) {
      super(services, options);
      const {
        serviceFileMap = {},
      } = options;

      Utils.removeDecorators(serviceFileMap); // TODO: add unit test for this line

      Object.keys(serviceFileMap).forEach((name) => {
        let serviceDeclaration = serviceFileMap[name];
        if (typeof serviceDeclaration === 'string') {
          serviceDeclaration = { path: serviceDeclaration };
        }
        serviceDeclaration.type = ServiceMapFactory.TYPE;
        serviceDeclaration.name = name;
        this._serviceFileMap[name] = serviceDeclaration;
      });

      // create a warning for deprecated expressions
      ServiceMapFactory.checkForDeprecatedPathExpressions(this._serviceDeclarationMap);
    }

    /**
     * log a message about service paths expressions that use anything _other_ than $initParams should
     * be removed (unless someone can present a reasonable use-case).
     *
     * There are cases where we would like to load services before the Application is created, so expressions
     * that reference variables, etc, cannot be evaluated.
     *
     * only $initParams (ConfigLoader) exist before Application, so only expressions limited to those
     * can be consistent between pre-Application and post-Application access.
     * @param serviceMap
     * @private
     */
    static checkForDeprecatedPathExpressions(serviceMap = {}) {
      // check for any expression references using $application, $flow, etc. and log an error.
      // we want to nudge devs toward using ONLY $initParams in expressions, so we can be consistent
      // between what is allowed in service path expressions before and after Application is created.
      const invalidReferences = [];
      Object.keys(serviceMap).forEach((key) => {
        const pathOrObject = serviceMap[key];

        const path = (typeof pathOrObject === 'string') ? pathOrObject : pathOrObject.path;
        if (path && Expression.isExpression(path)) {
          const re = /\$(application|flow|page|variables|metadata)/g;
          const match = re.exec(path);
          if (match) {
            invalidReferences.push(path);
          }
        }
      });
      if (invalidReferences.length) {
        logger.warn('DEPRECATED: Application "services" contains expressions with '
          + 'references other than "$initParams". These should be changed: ', invalidReferences);
      }
    }
  }

  ServiceMapFactory.TYPE = TYPE;

  return ServiceMapFactory;
});

