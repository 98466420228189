'use strict';

define('vb/private/translations/translationContext',[
  'ojs/ojtranslation',
  'vb/private/constants',
  'vb/private/log',
], (
  ojTranslation,
  Constants,
  Log,
) => {
  const logger = Log.getLogger('/vb/private/translations/translationContext');

  /**
   * Import typedefs
   * @import './bundleUtils'
   */

  /**
   *
   */
  class TranslationContext {
    constructor() {
      throw new Error('TranslationContext is a set of static methods, do not call constructor');
    }

    /**
     * This callback is displayed as a global member.
     * @callback getBundleDefinition
     * @param {string} bundleName
     */

    /**
     * Define a 'format' function on stringMap.  Use getBundleDefinition to get the appropriate bundle definition
     * to use.
     * @param {getBundleDefinition} getBundleDefinition
     * @param {Object} stringMap
     */
    static defineFormatFunction(getBundleDefinition, stringMap) {
      // Hold on to the bundle named 'format' (if there is one)
      // Don't get the value, get the descriptor because it may be a getter that resolves to a proxy
      const formatPD = Object.getOwnPropertyDescriptor(stringMap, 'format');

      // (Re)define the format() function
      Object.defineProperty(stringMap, 'format', {
        get: () => (bundleName, key, ...params) => {
          // if no bundleName is passed, assume they are referencing a bundle named 'format'
          if (typeof bundleName === 'undefined') {
            if (!formatPD) {
              logger.error('Translation bundle "format" is not defined');
              return '';
            }
            return formatPD.get();
          }

          // delegate formatting to the actual instance (of BundleDefinition/BundleV2Definition).
          const bundleDef = getBundleDefinition(bundleName);
          if (!bundleDef) {
            logger.error(`Translation bundle "${bundleName}" is not defined`);
            return '';
          }
          return bundleDef.format(key, ...params);
        },
        enumerable: true,
      });
    }

    /**
     * Create an object with just properties we want to expose to the expression context
     * Only the V1 translations bundles are included in this context.
     * Add a format() function to programmatically retrieve translated strings.
     * @param bundlesModel {BundlesModel}
     * @returns {*}
     */
    static createV1Context(bundlesModel) {
      // if no bundles yet, just return the utility method(s), and don't look up the key
      // just in case this is called before the bundles are loaded, which shouldn't happen
      if (!bundlesModel) {
        return {
          format: (_, str, ...params) => ojTranslation.applyParameters(str, ...params),
        };
      }

      const stringMap = bundlesModel.getStringMap();

      // Define 'format' function for V1 Bundle Definition
      const getBundleDefinition = (bundleName) => bundlesModel.getBundleDefinition(bundleName);
      TranslationContext.defineFormatFunction(getBundleDefinition, stringMap);

      return stringMap;
    }

    /**
     * Create an object with just properties we want to expose to the expression context
     * Both V1 and V2 translations bundles are included in this context.
     * Add a format() function to programmatically retrieve translated strings.
     * @param bundlesModel {BundlesModel}
     * @returns {*}
     */
    static createContext(bundlesModel) {
      // if no bundles yet, just return the utility method(s), and don't look up the key
      // just in case this is called before the bundles are loaded, which shouldn't happen.
      if (!bundlesModel) {
        return {
          format: (_, str, ...params) => ojTranslation.applyParameters(str, ...params),
        };
      }

      let stringMap = bundlesModel.getStringMap();
      stringMap = bundlesModel.getV2StringMap(stringMap);

      // Define 'format' function for V1 or V2 Bundle Definition
      const getBundleDefinition = (bundleName) => bundlesModel.getBundleDefinition(bundleName)
        || bundlesModel.getV2BundleDefinition(bundleName);
      TranslationContext.defineFormatFunction(getBundleDefinition, stringMap);

      return stringMap;
    }

    /**
     * Add $extension.<extId>.translations.<bundleName> for all extension translation bundles referenced by the
     * bundlesModel (i.e. translation bundles in other extensions)
     * @param bundlesModel {BundlesModel}
     * @param extensionsContext {Object} extensions context to add the <extId>.translations contexts
     */
    static addExtensionsContexts(bundlesModel, extensionsContext) {
      const extensionsBundles = Object.entries(bundlesModel.getExtensionsV2StringMaps());
      if (extensionsBundles.length) {
        extensionsBundles.forEach(([extId, stringMap]) => {
          // Define 'format' function for Extension V2 Bundle Definition
          const getBundleDefinition = (bundleName) => bundlesModel.getExtensionV2BundleDefinition(extId, bundleName);
          TranslationContext.defineFormatFunction(getBundleDefinition, stringMap);

          if (!extensionsContext[extId]) {
            // If there isn't already an extension entry for extId, create one with the "translations" property
            // set to the extension bundle.
            Object.defineProperty(extensionsContext, extId, {
              enumerable: true,
              configurable: true,
              value: {
                [Constants.TRANSLATIONS_CONTEXT]: stringMap,
              },
            });
          } else {
            // Just add the "translations" property to the existing extId extension
            Object.defineProperty(extensionsContext[extId], Constants.TRANSLATIONS_CONTEXT, {
              enumerable: true,
              configurable: true,
              value: stringMap,
            });
          }
        });
      }
    }
  }

  // all static
  return TranslationContext;
});

