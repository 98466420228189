'use strict';

define('vb/private/services/transformsUtils',[
  'vb/private/log',
  'vb/private/utils',
  'vb/services/transforms/serviceTransformsConstants'],
(Log, Utils, TransformsConstants) => {
  const REQUEST_TRANSFORMS_TYPE_BODY = 'body';
  const REQUEST_TRANSFORMS_TYPE_FETCHBYKEYS = 'fetchByKeys';
  const logger = Log.getLogger('/vb/private/services/transformsUtils');

  class TransformsUtils {
    /**
     * sort callback that sorts 'body' transform last
     * @param a
     * @param b
     * @returns {number}
     */
    static bodyTransformLast(a, b) {
      if (a === REQUEST_TRANSFORMS_TYPE_BODY) {
        return 1;
      }
      if (b === REQUEST_TRANSFORMS_TYPE_BODY) {
        return -1;
      }
      return 0;
    }

    /**
     * filter callback function to exclude fetchByKeys
     * @param a
     * @returns {boolean}
     */
    static excludeFetchByKeys(a) {
      return (a !== REQUEST_TRANSFORMS_TYPE_FETCHBYKEYS);
    }

    /**
     * sort callback that sorts select transform to be first in list of transforms
     * @param a
     * @returns {number}
     */
    static selectTransformFirst(a) {
      return (a === 'select' ? -1 : 1);
    }

    /**
     * returns a new Object literal with the API props cloned. Additionally, if allProps is set then nonAPI props
     * are passed-through as is, without cloning.
     * @param o plain old object or an instance
     * @param {boolean} allProps false if only API props need to be identified
     * @param props list of props to picked
     * @returns { {apiProps: {}, otherProps: * } }
     */
    static pick(o, allProps = false, ...props) {
      const returnVal = { apiProps: {}, otherProps: undefined };
      // @ts-ignore
      returnVal.apiProps = Object.assign({}, ...props.map((prop) => (Object.hasOwn(o, prop) &&
        { [prop]: o[prop] })));

      if (allProps) {
        const nonApiProps = {};
        Object.keys(o).forEach((key) => {
          if (!props.includes(key)) {
            nonApiProps[key] = o[key]
          }
        });
        returnVal.otherProps = nonApiProps;
      }

      return returnVal;
    }

    /**
     * clones a Set. Does not pick properties on Set because today only Set of keys are passed in, where each key
     * is a primitive, Object, or Array
     * @param originalSet
     * @returns {Set<any>}
     */
    static cloneSet(originalSet) {
      return new Set(Array.from(originalSet));
    }

    /**
     * Recursively evaluate value to extract supported properties for fetchParameters and clone. Object literals and
     * arrays are cloned as is; For first class instances only supported properties are extracted and a new Object
     * literal created.
     *
     * @param {string} prop
     * @param {any} value the value to evaluate
     * @param {boolean} [dropNonApiProps=true] when false, the non API props are included.
     * @returns {*}
     */
    static pickAndClone(prop, value, dropNonApiProps = true) {
      if (!value) {
        return value;
      }

      if (Array.isArray(value)) {
        return value.map((item) => TransformsUtils.pickAndClone(prop, item, dropNonApiProps));
      }

      if (value instanceof Set) {
        // usually just the keys are provided as a Set and so all properties are cloned
        return (TransformsUtils.cloneSet(value));
      }

      if (Utils.isObject(value)) {
        return TransformsUtils.pickAndCloneObject(prop, value, dropNonApiProps);
      }

      return value;
    }

    static pickAndCloneObject(prop, value, dropNonApiProps = true) {
      const fetchParamPropsDef = TransformsConstants[prop] || {};
      const { properties, propertyTypes } = fetchParamPropsDef;
      // some top level params are type 'any' (example 'filterCriterion.value'. In such cases return just the cloned
      // value via Utils.cloneObject
      const allProps = {};
      if (properties && properties.length > 0) {
        const {apiProps, otherProps} = TransformsUtils.pick(value, !dropNonApiProps, ...properties);
        Object.keys(apiProps).forEach((key) => {
          const keyProp = propertyTypes && propertyTypes[key];
          allProps[key] = TransformsUtils.pickAndClone(keyProp, apiProps[key], dropNonApiProps);
        });
        const nonApiProps = otherProps && Object.keys(otherProps);
        if (nonApiProps) {
          nonApiProps.forEach((nonApiProp) => {
            // log a warning if there are unsupported props on API
            logger.warn(
              'The property', prop, 'with value', value, 'contains an unsupported key', nonApiProp, 'that is not part' +
              ' of the Service Transforms API. Check your configuration.'
            );
            allProps[nonApiProp] = otherProps[nonApiProp];
          });
        }

        return allProps;
      }
      return Utils.cloneObject(value);
    }

    /**
     * While fetchParameters are what the caller provides via the fetch call, transforms authors can sometimes mutate
     * these. While cloning fetchParameters might be an obvious solution, when the parameter is a reference to a first
     * class instance, cloning fails. This method extracts key properties for each type of fetchParameter and clones
     * the same, to build the final fetchParameters for transforms.
     * @param config the configuration object for the current fetch
     * @returns {{fetchParameters}|*} a cloned pruned fetchParameters
     */
    // eslint-disable-next-line class-methods-use-this
    static extractFetchParametersForTransforms(config) {
      const { capability, fetchParameters } = config;
      const newFetchParams = {};

      if (capability && fetchParameters) {
        const fetchParamsTypeName = TransformsConstants.FetchParamTypes[capability];
        const fetchParamNames = (fetchParamsTypeName && TransformsConstants[fetchParamsTypeName]) || [];
        fetchParamNames.forEach((fpName) => {
          // ignore unsupported fetch params before passing them into transforms
          const fpCloned = TransformsUtils.pickAndClone(fpName, fetchParameters[fpName]/*, true */);
          if (fpCloned !== undefined) {
            newFetchParams[fpName] = fpCloned;
          }
        });
        // all fetch params outside of the known transforms types are passed through as is. Example an SDP that
        // delegates to a RestAction or a RestAction by itself can define its own transforms types (and where
        // author calls rest.setFetchConfiguration). More likely for the former case to happen.
        return Object.assign(config, { fetchParameters: newFetchParams });
      }

      return config;
    }


    /**
     * Extracts the API allowed transforms options and clones them.
     * Note: Authors also unfortunately set random properties that they then look for in the transforms code.
     * Example: On a RestAction, authors can have the following definition for a 'filter' transforms option,
     * (VBS-36646). While this is not allowed it is currently in use and so it's important to pass through these
     * properties to the transforms function, and not drop them. A warning is logged for such nonApi properties --
     * "requestTransformOptions": {
     *   "filter": {
     *     "foo": "passthrough"
     *   }
     * }
     *
     * The above is needed particularly on SDP -> RestAction configuration as SDP variable parameters (hopefully) are
     * vetted by DT and unsupported properties and sub-props are disallowed. But this call is made for both
     * plain-SDP and externalized SDP
     *
     * @param {Object} [transformsOptions={}]
     * @returns {unknown}
     */
    static extractOptionsForTransforms(transformsOptions = {}) {
      const newOptions = {};

      if (Object.keys(transformsOptions).length > 0) {
        const toParamNames = TransformsConstants.TransformsOptionsForFetchCommon || [];
        toParamNames.forEach((toName) => {
          const fpCloned = TransformsUtils.pickAndClone(toName, transformsOptions[toName], false);
          if (fpCloned !== undefined) {
            newOptions[toName] = fpCloned;
          }
        });
      }
      // all transforms options outside of known transforms types are passed through as is.
      return Object.assign({}, transformsOptions, newOptions);
    }

    /**
     * Checks if any of the transforms needs access to the endpoint responses metadata.
     * By default we assume it does not need it.
     * @returns {boolean}
     */
    static transformsUseResponsesMetadata(transformsMetadataFuncs, configuration) {
      if (transformsMetadataFuncs && transformsMetadataFuncs.requirements) {
        const requirements = transformsMetadataFuncs.requirements.call(null, configuration);
        if (requirements && requirements.usesResponsesMetadata === true) {
          return true;
        }
      }
      return false;
    }
  }

  return TransformsUtils;
});

