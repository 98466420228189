'use strict';

// This file was originally located in src/vb/private/. For full history: git log -- src/vb/private/logConfig.js
define('vbc/private/logConfig',['vbc/private/constants'], (Constants) => {
  /**
   * These are the loggers used in VBRT, which is based on a path. Each logger can be configured for
   * that path, and will affect all path elements underneath it. If a logger is requested but not
   * found, the default settings will be used based on the system logger.
   *
   * Each path can specify what levels are logged.
   *
   * To use a log, see utils/logger.js.
   */
  const Loggers = {
    '/': { error: true, info: true, fine: false, finer: false, warn: true },
    '/vb/stateManagement/variable': { finer: true },
    '/vb/stateManagement/instanceFactoryVariable': { finer: true },
    '/vb/stateManagement/container': { finer: true },
    '/vb/action': { fine: true },
    '/vb/types/ServiceDataProvider': { finer: true },
    '/vb/types/ServiceDataProvider.AsyncIterator': { finer: true },
    '/vb/helpers/rest': { fine: true },
  };

  const SpecialLogConstants = {
    CODE: 'code',
  };

  /**
   * Allows a configuration of styles if the default styles are not visually pleasing. Please only
   * make changes to styles in your private copy in loggers-config.js.
   */
  const Styles = {};
  Styles[Constants.Severity.ERROR] = {
    display: 'ERROR',
    style: 'background-color: #EB2A64; color: white',
  };
  Styles[Constants.Severity.INFO] = {
    display: 'INFO',
    style: 'font-weight:normal;background-color: #CED6F0; color: #0C40ED',
  };
  Styles[Constants.Severity.FINE] = {
    display: 'FINE',
    style: 'font-weight:normal;background-color: #C4C6CC; color: #4A4A4A',
  };
  Styles[Constants.Severity.FINER] = {
    display: 'FINER',
    style: 'font-weight:normal;background-color: #E9EAF0; color: #616060',
  };
  Styles[Constants.Severity.WARNING] = {
    display: 'WARN',
    style: 'background-color: #F2F2A7; color: black',
  };
  Styles[SpecialLogConstants.CODE] = {
    display: 'FINER',
    style: 'font-weight:bold;background-color: #E9EAF0; color: #616060',
  };

  // Get the default log level of the application
  const getDefaultLogLevel = () => {
    let defaultLogLevel;
    Constants.SeverityOrder.forEach((l) => {
      const style = Styles[l];
      if (style.disabled === undefined || style.disabled === false) {
        defaultLogLevel = l;
      }
    });
    return defaultLogLevel;
  };

  // Updates Logger Styles to allow console to show logs according to log level set in session storage
  const updateLoggerStyles = (precedence) => {
    // disable all the lower ones, enable all the higher ones
    Constants.SeverityOrder.forEach((l, index) => {
      const style = Styles[l];
      if (style) {
        style.disabled = (index > precedence);
      }
    });
  };

  const FancyStyleByFeature = {
    containerStart: 'orange',
    containerEnd: 'orange-end',
    actionChainStart: 'purple',
    actionChainEnd: 'purple-end',
    restHelperStart: 'yellow',
    restHelperEnd: 'yellow-end',
    serviceDataProviderStart: 'bluegreen',
    serviceDataProviderEnd: 'bluegreen-end',
  };

  const FancyStyles = {
    'fancy-yellow': 'font-weight:normal;background-color: #ffed00; color:#020200;padding:2px;font-size:11px;',
    'fancy-yellow-end': 'font-weight:normal;color:#FFCC00;padding:2px;font-weight:bolder;font-size:11px;',
    'fancy-orange': 'font-weight:normal;background-color: #EC9A29; color: #143642;padding:2px;font-size:11px;',
    'fancy-orange-end': 'font-weight:normal;color:#A56B1C;padding:2px;font-weight:bolder;font-size:11px;',
    'fancy-calm': 'font-weight:normal;background-color: #8BD8C6; color: black;font-size:14px;',
    'fancy-green': 'font-weight:normal;background-color: #3E8914; color: #E8FCCF;padding:2px;font-size:11px;',
    'fancy-coral': 'font-weight:normal;background-color: #ff9999; color: #ffffff;padding:2px;font-size:11px;',
    'fancy-bluegreen': 'font-weight:normal;background-color: #00cccc; color:#E8FCCF;padding:2px;font-size:11px;',
    'fancy-bluegreen-end': 'font-weight:normal;color:#009999;padding:2px;font-weight:bolder;font-size:11px;',
    'fancy-purple': 'font-weight:normal;color: transparent;text-shadow: 0 0 0 #9FAE68;' +
      'background-color:#7768AE;color: #fff;padding:2px;font-size:11px;',
    'fancy-purple-end': 'font-weight:normal;color: transparent;text-shadow: 0 0 0 #9FAE68;' +
      'color:#551A8B;padding:2px;font-weight:bolder;font-size:11px;',
    'fancy-contrast': 'font-weight:normal;background-color: #000000; color:yellow;padding: 2px',
  };

  const Emojis = {
    'fancy-orange': '🐬',
    'fancy-orange-end': '🐬',
    'fancy-green': '🐝',
    'fancy-coral': '🦉',
    'fancy-bluegreen': '🐿',
    'fancy-bluegreen-end': '🐿',
    'fancy-purple': '🐇',
    'fancy-purple-end': '🐇',
    'fancy-start': '✅️',
    'fancy-end': '❌',
    'fancy-calm': '🐐',
    'fancy-yellow': '🐎',
    'fancy-yellow-end': '🐎',
  };

  const CodeFontColors = {
    $string: 'color:#CD0000',
    $number: 'color:#0000FF',
    $boolean: 'color:#CC7832',
    $null: 'color:magenta',
    $key: 'color:#660E7A',
  };

  return {
    Loggers, Styles, FancyStyles, Emojis, CodeFontColors, FancyStyleByFeature, updateLoggerStyles, getDefaultLogLevel,
  };
});

