/* eslint-disable no-underscore-dangle */
'use strict';

define('vb/private/services/swaggerUtils',[
  'urijs/URI',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/services/serviceConstants',
], (
  URI,
  Log,
  Utils,
  ServiceConstants,
) => {
  const SWAGGER_OPERATIONID = 'operationId';
  const swaggerOperationWithBody = ['put', 'post', 'patch'];

  const logger = Log.getLogger('/vb/private/services/swaggerUtils');

  let servicesGlobalVariables;
  let servicesGlobalVariableTokens = [];

  class SwaggerUtils {
    /**
     * find suitable endpoint ID if there is no operation ID
     * @param pathKey
     * @param pathObject
     * @param operationKey
     * @returns {*}
     */

    static getEndpointId(pathKey, pathObject, operationKey, operationObject) {
      let endpointId = operationObject[SWAGGER_OPERATIONID];
      if (!endpointId) {
        const pathNoSlash = pathKey.replace(/\//g, '');
        endpointId = `${operationKey}${pathNoSlash}`;
      }
      return endpointId;
    }

    static hasRequestBody(operation) {
      return swaggerOperationWithBody.indexOf((operation || '').toLowerCase()) !== -1;
    }

    // TODO: remove recursive nature, it is slower

    /**
     * recursively resolve all remote references in the object,
     *  where 'resolve': replace $ref props with the referenced objects
     * @param {OpenApiObjectCommon} openApi
     * @param {(SwaggerNode: node, Object: resolutionContext) => boolean} [filterFn]
     *  returns false if node should be skipped
     * @param {SwaggerNode} [node]
     * @returns {Promise<SwaggerNode>}
     */
    static resolveRemoteReferences(
      openApi,
      filterFn,
      node = openApi.definition,
    ) {
      const resolutionContext = { promises: [] };
      const newNode = SwaggerUtils._resolveRemoteReferences(openApi, filterFn || (() => true), node, resolutionContext);
      return Promise.all(resolutionContext.promises).then(() => newNode);
    }

    static _resolveRemoteReferences(openApi, filterFn, node, resolutionContext) {
      // check if we should abort
      if (!filterFn(node, resolutionContext)) {
        return node;
      }

      const result = SwaggerUtils._resolveRemoteReference(openApi, node, resolutionContext);
      // if resolution left the $ref intact, just return it, and use as the result
      if (result.$ref) {
        return result;
      }

      function resolveArray(arr) {
        return arr.map((arrItem) => {
          if (Array.isArray(arrItem)) {
            return resolveArray(arrItem);
          }
          if (arrItem && typeof arrItem === 'object') {
            return SwaggerUtils._resolveRemoteReferences(openApi, filterFn, arrItem, resolutionContext);
          }
          return arrItem;
        });
      }

      const newNode = result;
      Object.keys(result).forEach((nodeName) => {
        const childNode = result[nodeName];

        if (Array.isArray(childNode)) {
          newNode[nodeName] = resolveArray(childNode);
        } else if (childNode && typeof childNode === 'object') {
          newNode[nodeName] = SwaggerUtils._resolveRemoteReferences(openApi, filterFn,
            childNode, resolutionContext);
        } else {
          newNode[nodeName] = childNode;
        }
      });

      return newNode;
    }

    static _resolveRemoteReference(openApi, node, resolutionContext) {
      if (node && node.$ref) {
        const refPath = node.$ref;
        // if it's not a string, it's either a valid name which happens to be "$ref", or it's some wonky syntax.
        // either way, just return it
        if (typeof refPath !== 'string') {
          return node;
        }

        // process external references
        if (Utils.isAbsoluteUrl(refPath)) {
        // if (!refPath.startsWith('#/')) { // TODO: resolve relative paths too
          const result = {
            $ref: refPath,
          };
          resolutionContext.promises.push(SwaggerUtils._loadRemoteReference(openApi, refPath, result));
          return result;
        }
      }

      return node;
    }

    static _loadRemoteReference(openApi, refPath, refOwner) {
      let fragment;
      return Promise.resolve()
        .then(() => {
          const uri = new URI(refPath);
          fragment = uri.fragment();

          // 1. load the remote URL but use one load request per SwaggerObject
          const url = uri.hash('').toString();
          return fragment && openApi.serviceMetadataLoader(url);
        })
        .then((remoteSwagger) => {
          // 2. separate schema reference (everything after "#/") from the remote URL
          if (remoteSwagger && fragment[0] === '/') {
            let foundNode = remoteSwagger;

            fragment.split('/').slice(1).some((part) => {
              foundNode = foundNode && foundNode[part];
              return !foundNode;
            });

            // 3. if remote Swagger is loaded resolve the schema reference within it
            if (foundNode) {
              // Recursively resolve the schema reference

              // expand any references within the referenced node
              foundNode = SwaggerUtils.resolveReferences(remoteSwagger, null, foundNode);
              // resolve any external references within the referenced node
              return SwaggerUtils.resolveRemoteReferences(remoteSwagger, null, foundNode);
            }
          }
          return undefined;
        })
        .then((resolvedRemoteReference) => {
          if (resolvedRemoteReference) {
            // replace $ref property with the resolved values
            delete refOwner.$ref;
            Object.assign(refOwner, resolvedRemoteReference);
          }
        })
        .catch((err) => {
          logger.info('unable to resolve reference:', refPath, err);
        });
    }

    // TODO: remove recursive nature, it is slower
    /**
     * recursely resolve all references in the object, where 'resolve': replace $ref props with the referenced objects
     * @param {SwaggerObject} swaggerObject
     * @param filterFn optional, fn(node, resolutionContext) return false if node should be skipped
     * @param {SwaggerNode} [node]
     * @param [resolutionContext] used internally, caller should not pass a value
     * @returns {Object}
     */
    static resolveReferences(swaggerObject, filterFn,
      node = swaggerObject, resolutionContext = { names: [], map: {} }) {
      // check if we should abort
      if (filterFn && !filterFn(node, resolutionContext)) {
        return node;
      }

      function resolveArray(arr) {
        return arr.map((arrItem) => {
          if (Array.isArray(arrItem)) {
            return resolveArray(arrItem);
          }
          if (arrItem && typeof arrItem === 'object') {
            return SwaggerUtils.resolveReferences(swaggerObject, filterFn, arrItem, resolutionContext);
          }
          return arrItem;
        });
      }

      const newNode = {};

      const result = Object.assign({}, SwaggerUtils._resolveReference(swaggerObject, node, resolutionContext));
      // if resolution left the $ref intact, just return it, and use as the node
      /**
       * @return {result is SwaggerNode}
       */
      if (result.$ref) {
        return result;
      }

      const dereferencedNode = result.reference;

      if (result.inMap) {
        return dereferencedNode;
      }

      Object.keys(dereferencedNode).forEach((nodeName) => {
        const childNode = dereferencedNode[nodeName];

        resolutionContext.names.push(nodeName);

        if (Array.isArray(childNode)) {
          newNode[nodeName] = resolveArray(childNode);
        } else if (childNode && typeof childNode === 'object') {
          newNode[nodeName] = SwaggerUtils.resolveReferences(swaggerObject, filterFn, childNode, resolutionContext);
        } else {
          newNode[nodeName] = childNode;
        }
        resolutionContext.names.pop();
      });

      return newNode;
    }

    /**
     * Node in the Swagger object
     * @typedef {Object} SwaggerNode
     * @property {Object|String} [$ref]
     */

    /**
     * Object modeling Swagger document
     * @typedef {Object} SwaggerObject
     */

    /**
     * Resolved reference either has "reference" property or "$ref" if it not a string
     * @typedef {Object} ResolvedReference
     * @property {boolean} inMap
     * @property {SwaggerNode} reference the referenced object, or the original if not found
     */

    /** @typedef {SwaggerNode | ResolvedReference} SwaggerNodeOrResolvedReference */

    /**
     * recursively follow references, expanding. does not follow combinations (allOf, etc).
     * @param {SwaggerObject} swaggerObject
     * @param {SwaggerNode} [node]
     * @param {Object} [resolutionContext] should not be passed by the caller, used internally to track recursion
     * @return {ResolvedReference | SwaggerNode} either input node or resolved reference object
     */
    static _resolveReference(swaggerObject, node = {}, resolutionContext) {
      const contextMap = resolutionContext.map;

      const contextPath = resolutionContext.names.join('/');
      if (!contextMap[contextPath]) {
        contextMap[contextPath] = node;
      }

      if (node && node.$ref) {
        // todo assume simple same-file reference. otherwise, ignore
        let refPath = node.$ref;
        // if its not a string, its either a valid name which happens to be "$ref", or its some wonky syntax.
        // either way, just return it
        if (typeof refPath !== 'string') {
          return node;
        }

        // skip external references
        // todo: we will possibly need to resolve these, at some point
        if (!refPath || refPath.startsWith('http')) {
          // bufp-36865 don't log, this gets noisy with new BO openapi3
          return {
            inMap: false,
            reference: node,
          };
        }

        if (refPath.startsWith('#/')) {
          refPath = refPath.substring(2);
        }

        // first, see if we've already expanded it
        // note: this is a very simple recursion check, only to prevent loops.
        // it does not define the loop in an 'optimal' way, it only stops the loop.
        // for example: the two recursive references, loop1 and loop2, where each has a ref to the other,
        // the expansion will not create objects that are recursive; the references will
        // terminate with an unexpanded object at some level.
        // loops really only happen in schemas, and runtime does not use the schema information.
        if (contextMap[refPath]) {
          return {
            inMap: true,
            reference: contextMap[refPath],
          };
        }

        let foundNode = swaggerObject;

        refPath.split('/').some((part) => {
          foundNode = foundNode && foundNode[part];
          return !foundNode;
        });

        if (foundNode) {
          /** @type {ResolvedReference} */
          const result = SwaggerUtils._resolveReference(swaggerObject, foundNode, resolutionContext);
          contextMap[refPath] = result.reference; // new map entry for path, and resolved object
          contextMap[contextPath] = result.reference; // replace the path/object with the resolved one
          return {
            inMap: false,
            reference: result.reference,
          };
        }

        // this doesn't happen; this would mean the local reference doesn't exist
        logger.info('unable to resolve reference:', refPath);
      }

      return {
        inMap: false,
        reference: node,
      };
    }

    /**
     * follow 'allOf' combinations, recursively, and return an array of all schemas
     * this requires that all $ref references have already be resolved.
     * @param schema
     * @returns {Array} array of expanded schemas, including allOf references
     */
    static expandSchema(schema) {
      const schemas = [];

      if (schema) {
        if (schema.allOf && Array.isArray(schema.allOf)) {
          schema.allOf.forEach((allOfSchema) => {
            // recurse, each 'allOf' could in turn include an 'allOf'
            const allOfSchemas = SwaggerUtils.expandSchema(allOfSchema);
            schemas.push(...allOfSchemas);
          });
        } else {
          schemas.push(schema);
        }
      }
      return schemas;
    }

    /**
     * Creates minimal valid OpenApi document with a single path from the given endpoint.
     * @param {Endpoint} endpoint
     * @returns {Promise<Object>}
     */
    static createSwaggerFromEndpoint(endpoint) {
      return Promise.resolve()
        // do not force expanding remote refs at this moment
        .then(() => endpoint.getMetadata(false))
        .then((endpointMetadata) => {
          // endpoint.path = '/data/objects/ora/labsRedwoodRef/storeProducts/v1/storeProducts/{storeProducts_id}'
          // endpointRef = boss#ora/labsRedwoodRef/storeProducts/storeProducts/update
          const openApi = {
            openapi: '3.0.0',
            paths: {
            },
          };
          const pathItems = {
            // endpoint method is always upper case while OpenApi operation method key is lower case
            [endpoint.method.toLowerCase()]: endpointMetadata.openApi,
          };
          openApi.paths[endpoint.path] = pathItems;
          // "/productCategories": {
          //   "get": {
          //     "description": "get a list of product categories",
          //     "operationId": "getProductCategories",
          //     "responses": {
          //       "default": {
          //         "description": "Default response"
          //       }
          //     }
          //   }

          return openApi;
        });
    }

    /**
     * this requires that the parameter defs have been processed by SwaggerUtils.separateParameters
     * @param paramDef
     * @returns {string}
     */
    static getParameterDefault(paramDef = {}) {
      let defaultValue = paramDef[ServiceConstants.VB_EXTENSIONS]
        && paramDef[ServiceConstants.VB_EXTENSIONS][SwaggerUtils.VB_DEFAULT_VALUE];

      if (defaultValue === undefined) {
        defaultValue = paramDef[SwaggerUtils.VB_DEPRECATED_DEFAULT_VALUE];
      }
      if (defaultValue === undefined) {
        defaultValue = paramDef.schema && paramDef.schema.default;
      }

      return defaultValue;
    }

    /**
     * Location of a parameter
     * @typedef {"path"|"query"|"header"|"body"} ParamLocation
     */
    /**
     * Definition of a parameter
     * @typedef {Object} ParamDef
     * @property {ParamLocation} in Location of the parameter path|query|header|body
     * @property {string} name
     * @property {*} [defaultValue]
     */
    /**
     *
     * @typedef {Object} RequestParams
     * @property {Object} [path]
     * @property {Object} [query]
     * @property {Object} [header]
     * @property {Object} [body]
     */

    /**
     * Seperates parameters based on their locations and ensures thet each definition has a defaultValue
     * @param {Array<ParamDef>} [mergedParameters] List of all parameters
     * @returns {RequestParams}
     */
    static separateParameters(mergedParameters = []) {
      const parameters = {};

      mergedParameters.forEach((paramDef) => {
        parameters[paramDef.in] = parameters[paramDef.in] || {};
        // look for (VB-specific) param default value, and copy it to defaultValue
        const newParamDef = Object.assign({
          defaultValue: SwaggerUtils.getParameterDefault(paramDef),
        }, paramDef);
        parameters[paramDef.in][paramDef.name] = newParamDef;
      });

      return parameters;
    }

    /**
     * for the map of Parameter definitions, return the parameter definitions of the required query and path parameters.
     * Only looks at parameters for the URL ('path', 'query') and ignores 'body' and 'header' parameters (swagger 2.0).
     * @param parameterDefs mapped first by type (query, path, etc.), then by name.
     *   {
     *     path: { foo: {...} },
     *     query: {limit: {...} }, ...
     *   }
     * @returns {Array}
     */
    static getRequiredParameters(parameterDefs) {
      const requiredDefs = [];
      // these are the only param types used on the URL
      SwaggerUtils.URL_PARAM_TYPES.forEach((type) => {
        const parameterDefsForType = parameterDefs[type];
        Object.keys(parameterDefsForType || {}).forEach((key) => {
          const parameterDef = parameterDefsForType[key];
          // note, 'path' should have 'required: true', but check for 'path' just  in case
          if (type === 'path' || parameterDef.required === true) {
            requiredDefs.push(Object.assign({ type }, parameterDef));
          }
        });
      });
      return requiredDefs;
    }

    /**
     * Checks if a PathItem property can be name of an operation.
     *
     * @param {string} propertyName
     */
    static isValidOperationName(propertyName) {
      // Not all properties of the PathObject are operations
      return !['summary', 'description', 'servers',
        'parameters', '$ref', ServiceConstants.VB_EXTENSIONS].includes(propertyName);
    }

    static servicesGlobalVariableTokensSupplier() {
      const vars = Utils.servicesGlobalVariableSupplier();
      // recalculate variableTokens only if variables got modified
      if (vars !== servicesGlobalVariables) {
        servicesGlobalVariableTokens = vars.map((globalVar) => {
          const [varName, varValue] = globalVar;
          return [`{@${varName}}`, varValue];
        });
        servicesGlobalVariables = vars;
      }
      return servicesGlobalVariableTokens;
    }

    /**
     * Parses any text meant to be used as a JSON object by services.
     *
     * Replaces the {@link SwaggerUtils#collectGlobalVariableTokens services global variables} if necessary
     *
     * @param {String} text
     * @return {Object} a JSON object or array parsed from the specified text after replacing the global variables.
     */
    static parseServiceText(text) {
      const tokenAndValuePairs = SwaggerUtils.servicesGlobalVariableTokensSupplier();

      if (Array.isArray(tokenAndValuePairs)
        && tokenAndValuePairs.length > 0
        && typeof text === 'string'
        && text.includes('{@')) {
        /**
         * @type {String}
         */
        let replacement = text;
        tokenAndValuePairs.forEach(([variableToken, value]) => {
          replacement = replacement.replaceAll(variableToken, value);
        });
        return JSON.parse(replacement);
      }

      return JSON.parse(text);
    }

    /**
     * Variables object returned by SwaggerUtils.splitVariables()
     *
     * @typedef {Object} Variables
     * @property {Object} variables
     * @property {Object} serverVariables
     */

    /**
     * Splits the variables defined in the specified object.
     *
     * At the moment this method separates the 'server variables' from the "regular" path and query parameters. The
     * server variables are identified by the prefix 'server:'.
     *
     * The returns of this method is an object with properties 'serverVariables' and 'variables'. The 'serverVariables'
     * property is undefined if there is no server variables.
     *
     * Examples:
     * - uriVariables = { foo: true } => { variables: { foo: true }, serverVariables: undefined }
     * - uriVariables = { foo: true, server:foo: true } => { variables: { foo: true }, serverVariables: { foo: true } }
     * - uriVariables = { server:foo: true } => { variables: {}, serverVariables: { foo: true } }
     *
     * @param {object} uriVariables
     * @returns {Variables}
     */
    static splitVariables(uriVariables) {
      let serverVariables;
      const variables = {};
      if (typeof uriVariables === 'object' && !Array.isArray(uriVariables)) {
        Object.keys(uriVariables).forEach((key) => {
          const value = uriVariables[key];
          if (key.startsWith('server:')) {
            if (SwaggerUtils.isValidServerVariableValue(value)) {
              if (!serverVariables) {
                serverVariables = {};
              }
              serverVariables[key.substring('server:'.length)] = uriVariables[key];
            }
          } else {
            variables[key] = value;
          }
        });
      }

      return { serverVariables, variables };
    }

    /**
     * @param {*} value - the value of the variable.
     * @param {object} [server] - the server object. If this argument and 'name' are both specified, the value is
     *                            validated against the server's definition.
     * @param {string} [name] - the name of the variable whose value is being evaluated.
     * @returns {boolean} true if the argument can be used as the value of a server variable.
     */
    static isValidServerVariableValue(value, server, name) {
      if (value !== undefined && value !== null && value !== '') {
        const variable = server && server.variables && server.variables[name];
        if (variable && Array.isArray(variable.enum) && !variable.enum.includes(value)) {
          logger.warn(
            'The enumeration for the server variable', name,
            'does not include the value', value, 'used by the request.',
          );
        }

        return true;
      }

      return false;
    }
  }

  SwaggerUtils.VB_DEPRECATED_DEFAULT_VALUE = `${ServiceConstants.VB_EXTENSIONS}-defaultValue`;

  SwaggerUtils.VB_DEFAULT_VALUE = 'defaultValue'; // this appears in a 'x-vb'

  // the openapi3/swagger parameter types that need replacement/appending in the url
  SwaggerUtils.URL_PARAM_TYPES = ['path', 'query'];

  return SwaggerUtils;
});

