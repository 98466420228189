'use strict';

define('vb/private/stateManagement/fragmentHolderBaseMixin',[
  'vb/private/constants',
  'vb/components/oj-vb-fragment/loader', // load fragment CCA so it's automatically available
], (Constants) => {
  /**
   * Base mixin that has common functions that all fragment holder containers share. Whether the container is an
   * extension or the base.
   */
  const FragmentHolderBaseMixin = (superclass) => class extends superclass {
    constructor(...args) {
      super(...args);

      /**
       * instances of loaded fragments used by this container.
       * @type {Object}
       */
      this.fragments = {};

      /**
       * fragmentBridge that connects this container to the component. Usually set by the fragmentBridge itself
       * @type {null|FragmentBridge}
       */
      this.fragmentBridge = null;

      this.parentOJModuleNode = undefined;

      this.loadHtmlPromise = null; // Initialized in loadTemplate

      const parent = this.getParentOrBaseOfExtension();

      const parentAllowsSlots = (parent && typeof parent.getCapability === 'function')
        ? parent.getCapability(Constants.FragmentCapability.ALLOWS_SLOTS) : true;
      /**
       * by default fragment allows slots to be used as long as its not turned off by its parent (e.g., in layouts)
       * @type {{allowSlots: boolean}}
       */
      this.capabilities = {
        allowSlots: parentAllowsSlots === undefined ? true : parentAllowsSlots,
      };
    }

    getParentOrBaseOfExtension() {
      return this.parent;
    }

    /**
     * Returns a value that is appropriate to the option that is passed in.
     * @param {String} option
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    getCapability(option) {
      if (option === Constants.FragmentCapability.ALLOWS_SLOTS) {
        return this.capabilities.allowSlots;
      }
      return null;
    }

    /**
     * Return a promise to load the .html
     *
     * @returns {Promise} a promise that resolve with the html text
     */
    loadTemplate() {
      // Keep a reference of the loading promise so that multiple function can wait
      // on the same promise to be resolved.
      this.loadHtmlPromise = this.loadHtmlPromise || this.templateLoader(this.getResourcePath());

      return this.loadHtmlPromise;
    }

    /**
     * Retrieves a fragment instance for the fragment id. If a Fragment instance cannot be located this method
     * creates the Fragment instance first and then returns the fragment context. This is only called from the
     * FragmentBridge.
     * @param {string} id of the fragment
     * @param {string} name of the fragment (artifacts)
     * @param {object} params input from caller
     * @param {object} templates defined on fragment
     */
    createFragment(id, name, params, templates) {
      let fragment = this.fragments[id];
      // if name and/or params are provided then we definitely construct a fragment, otherwise return the fragment
      // that was previously created or undefined.
      if (!fragment && name) {
        return this.getFragmentClass().then((FragmentClazz) => {
          fragment = new (FragmentClazz)(id, this, undefined, name, params, templates);
          this.fragments[id] = fragment;
          return fragment;
        });
      }

      return Promise.resolve(fragment);
    }

    getFragment(id) {
      return this.fragments[id];
    }

    /**
     * Returns the class used to instantiate the fragment.
     * @returns {Promise<unknown>}
     */
    getFragmentClass() {
      return Promise.resolve(this.constructor.FragmentClass);
    }

    /**
     * Author may choose to dispose fragment state under these situations -
     * i) when a fragment component is still connected to viewModel but caller indicates that they want fragment state
     * disposed. For the actual state to be disposed we require the component be 'disconnected' first so this method
     * registers the request to dispose state. The fragment CCA has to be entirely removed from the DOM, so it needs
     * to be wrapped in an oj-bind-if or oj-bind-for-each that removes the fragment DOM and recreates a new one.
     * Turning off / on an oj-bind-if condition are synchronous and causes the entire subtree including fragment CCA
     * disconnected()** / connected()  to be called. **Note: Unfortunately today calls to disconnected() does not
     * really tell us whether the fragment has been removed entirely from the subtree or just hidden. A fragment may
     * just be hidden, like if it's inside a dialog that is closed. We require that authors wrap the oj-vb-fragment
     * inside an on-bind-* for deleteState to work properly.
     * ii) If the component was disconnected first, and then a call to dispose its state comes in later. Here the
     * state is deleted immediately (after running vbExit).
     * @param {String} fragmentId
     */
    addFragmentForDispose(fragmentId) {
      // check to see if fragment can be located on container that is itself not disposed
      if (this.lifecycleState === Constants.ContainerState.DISPOSED
        || this.lifecycleState === Constants.ContainerState.EXITED) {
        // there is nothing to do here since the container state is deleted.
        this.log.warn('Unable to delete fragment state because the container with id', this.id,
          'that references the fragment', fragmentId, 'has been disposed.');
        return;
      }

      // locate the fragment on the outer container and ensure it's not disposed or itself in some limbo state.
      // Not all containers are fragmentHolders (example flow)
      const fragment = this.getFragment(fragmentId);
      if (!fragment) {
        this.log.warn('Unable to delete state for fragment with id', fragmentId,
          'because it cannot be located on the container', this.id);
        return;
      }

      // we have a fragment, but it could be in many weird states that doesn't allow us to dispose its state
      // component is still connected to viewModel
      if (fragment.fragmentComponentState === Constants.FragmentComponentState.COMPONENT_CONNECTED
        || fragment.fragmentLifecycleState === Constants.FragmentState.MODULE_CONNECTED) {
        this.log.warn('Unable to delete state for fragment', fragmentId, 'at present because it is still active!',
          'The next time component is removed from DOM and disconnected (from viewModel), the request to delete its',
          ' state will be honored.');
      }

      // if the fragment is not marked for dispose, then mark it for disposal
      if (!fragment.eagerDisposeState) {
        fragment.eagerDisposeState =  '_vb_dispose_';
      }

      // There is one case where the component and module could both be disconnected, because an oj-bind-if parent
      // already removed its children. Then fragment deleteState comes in. If so call disposeFragmentState() right
      // away. All conditions for disposing state at the right time is in disposeFragmentState
      fragment.disposeFragmentState();
    }

    /**
     * deletes a fragment from the container.
     * @param id
     */
    deleteFragment(id) {
      delete this.fragments[id];
    }

    /**
     * Traverse a set of definitions (variables or constants) and build new parameters
     * If the input parameter is already in the map, overwrite the existing definition
     * @param  {String} defName Either 'variables' or 'constants'
     * @param  {Object} allParameters the map of all the parameters
     */
    _buildParameters(defName, allParameters) {
      const parameters = allParameters;
      const variabledefs = this.getAllDefinitionOf(defName);
      Object.keys(variabledefs).forEach((name) => {
        const def = variabledefs[name];
        if (def.input === Constants.VariablePropertyInput.FROM_CALLER
          || def.input === Constants.VariablePropertyInput.FROM_URL) {
          if (parameters[name]) {
            this.log.warn('Input parameter', name, 'is already defined. Using the definition in', defName);
          }
          const inputParameterValue = this.getInputParameterValue(name, def);
          parameters[name] = inputParameterValue;
          this.log.info('Input parameter', name, '[input=', def.input, '] value:', inputParameterValue);
        }
      });
    }

    /**
     * Build a map of all possible input parameters to be used in $parameters for the
     * vbBeforeEnter event
     * @return {Object} a map of parameters
     */
    buildAllParameters() {
      // Add variables input parameters
      const parameters = {};
      this._buildParameters('variables', parameters);
      // Add constants input parameters
      // If an input parameter is already defined as a variable, the constant is used.
      this._buildParameters('constants', parameters);
      return parameters;
    }

    /**
     * Checks whether the current container has any dirty data variables.
     * It will check its current scope, any embedded fragments, layouts
     * and their extensions if present.
     * @return {boolean} true if there is at least one dirty data variable found;
     *                   false otherwise
     */
    checkForDirtyData() {
      // verify if any contained fragments have dirty data
      const hasDirtyData = Object.values(this.fragments).find((fragment) => fragment.checkForDirtyData());
      return hasDirtyData ? true : super.checkForDirtyData();
    }

    /**
     * Re-sets the dirty data state of variables in the current container's scope
     * as well as fragments and layouts and their extensions.
     */
    resetDirtyData() {
      // reset all fragment scopes
      Object.values(this.fragments).forEach((fragment) => {
        fragment.resetDirtyData();
      });

      super.resetDirtyData();
    }

    /**
     * Sets the ParentOJModuleNode for the container
     */
    setParentOJModuleNode(parentNode) {
      this.parentOJModuleNode = parentNode;
    }

    dispose() {
      Object.values(this.fragments).forEach((fragment) => fragment.dispose());
      this.fragments = {};

      super.dispose();
    }
  };

  return FragmentHolderBaseMixin;
});

