'use strict';

define('vb/private/types/factoryMetadataProviderDescriptor',[
  'knockout',
  'vb/private/utils',
  'vb/private/types/metadataDescriptor',
], (ko, Utils, MetadataDescriptor) => {
  class FactoryMetadataProviderDescriptor extends MetadataDescriptor {
    constructor() {
      super();

      // observable for the provider in case it is referenced in an expression
      // TODO: Technically, this shouldn't be necessary if provider is a tracked property of the
      // metadata variable itself. However, it is currently not implemented that way.
      this._providerObservable = ko.observable();
    }

    /**
     * accessor for <variable>.provider property; this is the provider created by the factory
     *
     */
    get provider() {
      return this._providerObservable();
    }

    /**
     * from the (resolved) declaration, create an 'options' structure that the FactoryMetadataProviderDescriptor
     * understands.
     * @param value
     * @returns {Object}
     */
    // eslint-disable-next-line class-methods-use-this
    createOptions(value) {
      return value ? Utils.cloneObject(value) : {};
    }

    /**
     * This method is called during the activation phase of the descriptor. During this phase,
     * it is safe to access values containing expressions to other variables.
     *
     * @returns {Promise<T>}
     */
    activate() {
      return Promise.resolve()
        .then(() => {
          const options = this.createOptions(this.getValue());

          // make the variable name available on the options which will be used as the id
          // as well as part of the scope name for the layout
          options.id = this.id;

          return this.createProvider(options);
        })
        .then(() => super.activate());
    }

    /**
     *
     * @param options from createOptions(), typically derived from the variable declaration.
     * @return {Promise} declaration
     */
    createProvider(options) {
      if (options) {
        return this.getProviderFactory(options)
          .then((factory) => {
            const newOptions = {
              context: this.getVbAppContext(),
              container: this.container,
              metadataDescriptor: this,
            };

            // For backwards compatibility with vb/FactoryMetadataProviderDescriptor, the actual options
            // is under options.options.
            Object.assign(newOptions, options.options ? options.options : options);

            return factory.createMetadataProvider(newOptions);
          })
          .then((provider) => {
            // update the provider observable which may trigger other variables with expression
            // referencing the provider property to update
            this._providerObservable(provider);
            return options; // todo: should this return newOptions? leave it as-is for now
          });
      }

      return Promise.reject(new Error('No definition for MetadataDescriptor.'));
    }

    /**
     * @returns {Object}
     * @deprecated @since 2310 Use #getVbAppContext() instead.
     */
    getVbContext() {
      return this.getVbAppContext();
    }

    /**
     *
     * @returns {{user: Object, responsive: any  }}
     */
    getVbAppContext() {
      const appContext = this.container.application.expressionContext || {};

      // normally this would be an object, but we are defining it as a function to prevent
      // a provider MetadataUtils.cloneObject call, that ends up getting the current value of the getters
      // that wrap the responsive observables (we use getters so we don't need parens in expressions).
      const filteredResponsive = () => ({});

      const responsiveKeys = Object.keys(appContext.responsive).filter((key) => key !== 'screenRange');
      responsiveKeys.forEach((key) => {
        // use a 'get'ter, not a 'value', because we need to preserve the (ko) observable behind
        // the $application.responsive.XXX getters
        Object.defineProperty(filteredResponsive, key, {
          get: () => appContext.responsive[key],
          enumerable: true,
        });
      });

      const context = {};
      // context.application = {}; // move properties to the top level
      Object.defineProperties(context, {
        user: {
          value: appContext.user,
          enumerable: true,
        },
        responsive: {
          value: filteredResponsive,
          enumerable: true,
        },
      });

      return context;
    }

    /**
     * @todo: support defining the "options" be getting a type from the factory, if available (requires Promise).
     * @returns {{type: {factory: string, options: string}}}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition() {
      return {
        type: {
          factory: 'string',
          options: 'object',
        },
      };
    }

    /**
     * subclasses may override this if the 'factory' property is not used to provide the factory implementation.
     * @param options
     * @param options.factory path to (requireJS) JET provider factory, or a VB 'bridge' factory wrapper
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    getProviderFactory(options) {
      return Promise.resolve()
        .then(() => {
          if (!options || !options.factory) {
            throw new Error('MetadataDescriptor requires a "factory" for configuration.');
          }
          return Utils.getResource(options.factory).then((Factory) => new Factory());
        });
    }

    /**
     * Called by the AbstractMetadataProviderDescriptor when the variable is disposed
     * override this method
     */
    dispose() {
      if (this._providerObservable) {
        const provider = this._providerObservable();

        // call dispose on the metadata provider to clean up
        if (provider && typeof provider.dispose === 'function') {
          provider.dispose();
        }

        this._providerObservable = undefined;
      }

      super.dispose();
    }
  }

  return FactoryMetadataProviderDescriptor;
});

