'use strict';

define('vb/action/builtin/getDirtyDataStatusAction',[
  'vb/action/action',
  'vb/private/log',
], (Action, Log) => {
  const logger = Log.getLogger('/vb/action/builtin/getDirtyDataStatusAction');

  /**
   * Determines whether there are any dirty data variables in the scope/container from which
   * this action was invoked. This action will check the current container (i.e.,
   * Application, Flow, Page, Layout, Fragment, etc.), all of its extensions (if present) and
   * any contained containers and their extensions (if the current container has any). As long
   * as at least one dirty data variable has been found, this action will return the dirty state.
   * This action will always return a success outcome with { status: 'dirty' } if there is at least
   * one dirty variable or { status: 'notDirty' } if there aren't any.
   */
  class GetDirtyDataStatusAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    /**
     * Sets the current available context
     * @param context
     */
    setContext(context) {
      this.context = context;
    }

    /**
     * Determines whether there are any dirty data variables in the container from which
     * the action was invoked. It will check the current container, all of its extensions and
     * any embedded containers (and their extensions) if present.
     * @param parameters
     * @return always returns success but with the Outcome { status: 'dirty' } if there is at least one dirty
     * variable present or the Outcome { status: 'notDirty' } if there are no dirty data variables in the given scope.
     */
    // eslint-disable-next-line no-unused-vars
    perform(parameters) {
      const currentContainer = this.context.container;
      const status = currentContainer.getDirtyDataStatus();
      return Action.createSuccessOutcome({ status });
    }
  }

  return GetDirtyDataStatusAction;
});

