'use strict';

define('vb/extensions/dynamic/private/models/metadataRules',[
  'vb/extensions/dynamic/private/models/layoutResource',
  'vb/extensions/dynamic/private/models/metadataRulesExtension',
], (LayoutResource, MetadataRulesExtension) => {
  /**
   * MetadataRules is a subclass of LayoutResource used to load metadata-rules related resources, which have
   * special Translation Bundles semantics (v1 bundle definitions are preferred over v2 bundle definitions).
   */
  class MetadataRules extends LayoutResource {
    /**
     * MetadataRules constructor
     *
     * @param container the container that contains this metadata-rules resource
     * @param extension the extension from which to load the resource
     * @param {String} path the absolute path to the layout resource, e.g., vb/extA/dynamicLayouts/self/employee
     * @param {Object} resourceDescriptor resource descriptor
     * @param {String} className the class name
     */
    constructor(container, extension, path, resourceDescriptor, className = 'MetadataRules') {
      super(container, extension, path, resourceDescriptor, className);
    }

    /**
     * The extension class to be used for loading extension related resources
     *
     * @returns {MetadataRulesExtension}
     */
    static get extensionClass() {
      return MetadataRulesExtension;
    }

    /**
     * Overrides the base impl, so we can add "preferV1: true"
     * (v1 bundle definitions are preferred over v2 bundle definitions)
     *
     * @returns {Promise}
     */
    loadTranslationBundles() {
      // If V1 translations are present, ignore V2 translations.
      return super.loadTranslationBundles({ preferV1: true });
    }
  }

  return MetadataRules;
});

